import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

const SignaturePreviewPopup = (props) => {

  return (
    <Fragment>
      <Modal {...props} tabindex="-1" aria-labelledby="Signpreview" size="md" centered className="modal-sp" >
        <Modal.Body centered className="modal-body-sp">
          <h2>Does this look like the signature on your ID?</h2>
          <h3 className="pop3"><i>We must provide lenders with the matching details in their system to ensure a successful outcome. It should appear as if it were handwritten.</i></h3>
          <img src={props.signaturePreviewImage} alt="SignaturePreview" />
        </Modal.Body>
        <Modal.Footer className="modal-footer-sp">
          <Button className='btn-clear' onClick={props.clearAction}>Retake</Button>
          <Button className='btn-submit next09' onClick={props.submitAction}  disabled={props.isLoading}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default SignaturePreviewPopup;
