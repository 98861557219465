require('dotenv').config();

const AppDomain = process.env.REACT_APP_DOMAIN;
const AppTitle = process.env.REACT_APP_TITLE;
const AppEnv = process.env.REACT_APP_ENV;
const AppYahooGeminiDefaultUrl  = process.env.REACT_APP_YAHOO_GEMINI_DEFAULT_URL;
const AppDomainWithoutProtocol = window.location.hostname; //process.env.REACT_APP_DOMAIN_WITHOUT_PROTOCOL;
const AppLpDefaultUrl = process.env.REACT_APP_LP_DEFAULT_URL;
const AppAdtopiaUPID = process.env.REACT_APP_ADTOPIA_UPID;
const AppReferrerURL = document.referrer;
const AppApDefaultUrl = process.env.REACT_APP_AP_DEFAULT_URL;
const AppLpRedirectDefaultUrl = process.env.REACT_APP_LP_REDIRECT_DEFAULT_URL;
export {
    AppDomain,
    AppTitle,
    AppEnv,
    AppYahooGeminiDefaultUrl,
    AppDomainWithoutProtocol,
    AppLpDefaultUrl,
    AppAdtopiaUPID,
    AppReferrerURL,
    AppLpRedirectDefaultUrl,
    AppApDefaultUrl
}