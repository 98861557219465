export const lenders    =   {
    data:{
        response:{
            legend_banks:[
                { id: 72, lid:721, bank_name: "Latitude Financial Services" },
                { id: 72, lid:722, bank_name:"GO Mastercard"},
                { id: 72, lid:724, bank_name: "Gem Visa" },
                { id: 72, lid:725, bank_name: "Coles Mastercard" },
                //{ id: 72, lid:723, bank_name: "GE Money" },
            ],
            banks:[
                //{id:3, bank_name:"AMP"},
                {id:1, bank_name:"Adelaide Bank"},
                {id:127, bank_name:"Suncorp Bank"},
                {id:22, bank_name:"Bendigo Bank"},
                {id:42, bank_name:"CUA"},
                {id:20, bank_name:"Bankwest"},
                {id:99, bank_name:"People's Choice Credit Union"},
                {id:14, bank_name:"Bank of Melbourne"},
                {id:25, bank_name:"BOQ"},
                {id:124, bank_name:"St.George Bank"},
                {id:32, bank_name:"Citi"},
                {id:47, bank_name:"Esanda"},
                // {id:28, bank_name:"Cash Converters"},
                {id:15, bank_name:"Bank of Sydney"},
                {id:2, bank_name:"American Express"},
                {id:88,bank_name:"National Australia Bank (NAB)"},
                {id:144,bank_name:"Westpac (WBC)"},
                {id:37,bank_name:"Commonwealth Bank of Australia (CBA)"},
                {id:4,bank_name:"Australia and New Zealand Banking Group (ANZ)"},

                // {id:2, bank_name:"American Express"},
                // {id:3, bank_name:"AMP"},
                // {id:9, bank_name:"Australian Military Bank"},
                // {id:11, bank_name:"Auswide Bank"},
                // {id:12, bank_name:"Bank Australia"},
                // {id:13, bank_name:"Bank First"},
                // {id:15, bank_name:"Bank of Sydney"},
                // {id:19, bank_name:"BankVic"},
                // {id:20, bank_name:"Bankwest"},
                // {id:21, bank_name:"BCU"},
                // {id:22, bank_name:"Bendigo Bank"},
                // {id:25, bank_name:"BOQ"},
                // {id:32, bank_name:"Citi"},
                // {id:42, bank_name:"CUA"},
                // {id:44, bank_name:"Delphi Bank"},
                // {id:56, bank_name:"Geelong Bank"},
                // {id:72, bank_name:"Latitude Financial Services"},
                // {id:79, bank_name:"ME Bank"},
                // {id:87, bank_name:"MyState Bank"},
                // {id:97, bank_name:"P&N Bank"},
                // {id:109, bank_name:"RACQ Bank"},
                // {id:110, bank_name:"RACV"},
                // {id:118, bank_name:"Resimac"},
                // {id:119, bank_name:"SCU"},
                // {id:123, bank_name:"Squirrel Superannuation"},
                // {id:126, bank_name:"Summerland Credit Union"},
                // {id:133, bank_name:"The Mac"},
                // {id:134, bank_name:"The Mutual"},
                // {id:142, bank_name:"Wallet Wizard"},
                // {id:143, bank_name:"Well Home Loans"},
                // {id:147, bank_name:"Woolworths Employees CU"},
                // {id:148, bank_name:"Yellow Brick Road"}
            ],
            other_banks:
            [
                //{id:1, bank_name:"Adelaide Bank"},
                // {id:2, bank_name:"American Express"},
                // {id:3, bank_name:"AMP"},
                // {id:4, bank_name:"ANZ"},
                {id:5, bank_name:"Arab Bank Australia"},
                {id:7, bank_name:"Aussie"},
                //{id:8, bank_name:"Australian Financial"},
                {id:9, bank_name:"Australian Military Bank"},
                {id:10, bank_name:"Australian Unity"},
                {id:11, bank_name:"Auswide Bank"},
                {id:12, bank_name:"Bank Australia"},
                {id:13, bank_name:"Bank First"},
                // {id:14, bank_name:"Bank of Melbourne"},
                // {id:15, bank_name:"Bank of Sydney"},
                {id:16, bank_name:"Bank of us"},
                {id:17, bank_name:"BankSA"},
                {id:18, bank_name:"Bankstown City Unity Bank"},
                {id:19, bank_name:"BankVic"},
                // {id:20, bank_name:"Bankwest"},
                {id:21, bank_name:"BCU"},
                // {id:22, bank_name:"Bendigo Bank"},
                {id:23, bank_name:"Beyond Bank Australia"},
                // {id:25, bank_name:"BOQ"},
                {id:26, bank_name:"Cairns Penny"},
                {id:27, bank_name:"CarLoans.com.au"},
                //{id:28, bank_name:"Cash Converters"},
                {id:29, bank_name:"Catalyst Money"},
                {id:30, bank_name:"Catch Personal Loans"},
                {id:31, bank_name:"Circle Alliance Bank"},
                // {id:32, bank_name:"Citi"},
                //{id:33, bank_name:"Clearloans Australia"},
                {id:35, bank_name:"Coastline Credit Union"},
                {id:36, bank_name:"Coles"},
                // {id:37, bank_name:"Commonwealth Bank of Australia"},
                {id:38, bank_name:"Community First Credit Union"},
                {id:39, bank_name:"Credit Concierge"},
                {id:40, bank_name:"Credit One"},
                {id:41, bank_name:"Credit Union SA"},
                // {id:42, bank_name:"CUA"},
                {id:43, bank_name:"Defence Bank"},
                {id:44, bank_name:"Delphi Bank"},
                {id:45, bank_name:"Easy Street Financial Services"},
                {id:46, bank_name:"Endeavour Mutual Bank"},
                // {id:47, bank_name:"Esanda"},
                {id:49, bank_name:"Family First Credit Union"},
                {id:50, bank_name:"First Option Bank Ltd"},
                {id:51, bank_name:"Firstmac"},
                {id:52, bank_name:"Flexigroup"},
                {id:53, bank_name:"Freedom Lend"},
                {id:54, bank_name:"G&C Mutual Bank"},
                {id:55, bank_name:"Gateway Bank"},
                {id:56, bank_name:"Geelong Bank"},
                {id:57, bank_name:"Greater Bank"},
                {id:59, bank_name:"Health Professionals Bank"},
                {id:60, bank_name:"Heritage Bank"},
                {id:61, bank_name:"Holiday Coast Credit Union"},
                {id:62, bank_name:"Homestar Finance"},
                {id:63, bank_name:"Horizon Credit Union"},
                {id:64, bank_name:"HSBC"},
                {id:65, bank_name:"Hume Bank"},
                {id:66, bank_name:"Hunter United"},
                {id:67, bank_name:"Illawarra Credit Union"},
                {id:68, bank_name:"IMB Bank"},
                {id:70, bank_name:"Jetstar"},
                // {id:69, bank_name:"ING"},
                // {id:72, bank_name:"Latitude Financial Services"},
                {id:73, bank_name:"Liberty Financial Services"},
                {id:74, bank_name:"LJ Hooker Home Loans"},
                {id:77, bank_name:"Macquarie Bank"},
                {id:78, bank_name:"Macquarie Credit Union"},
            //    {id:79, bank_name:"ME Bank"},
            //    {id:82, bank_name:"Money3"},
                //{id:83, bank_name:"Mortgage House"},
                // {id:84, bank_name:"MOVE Bank"},
                {id:85, bank_name:"My Credit Union"},
                {id:86, bank_name:"Myer"},
                {id:87, bank_name:"MyState Bank"},
                // {id:88, bank_name:"NAB"},
                {id:89, bank_name:"Newcastle Permanent"},
                {id:90, bank_name:"Nexus Mutual"},
                {id:91, bank_name:"Northern Beaches Credit Union"},
                {id:92, bank_name:"Northern Inland Credit Union"},
                {id:93, bank_name:"Now Finance"},
                {id:94, bank_name:"NRMA Car Loans"},
                {id:97, bank_name:"P&N Bank"},
                // {id:99, bank_name:"People's Choice Credit Union"},
                //{id:101, bank_name:"Pioneer Credit Connect"},
                {id:102, bank_name:"Police Bank"},
                {id:103, bank_name:"Police Credit Union"},
                {id:104, bank_name:"Qantas Money"},
                {id:105, bank_name:"QBANK"},
                {id:106, bank_name:"Qudos Bank"},
                {id:107, bank_name:"Queensland Country Credit Union"},
                {id:108, bank_name:"Queenslanders Credit Union"},
                {id:109, bank_name:"RACQ Bank"},
                {id:110, bank_name:"RACV"},
                //{id:111, bank_name:"RAMS"},
                {id:112, bank_name:"Rate Chaser Home Loans"},
                {id:114, bank_name:"Reduce Home Loans"},
                {id:115, bank_name:"Regional Australia Bank"},
                {id:116, bank_name:"Reliance Bank"},
                {id:117, bank_name:"RESI Mortgage Corp"},
                {id:118, bank_name:"Resimac"},
                {id:119, bank_name:"SCU"},
                {id:120, bank_name:"SERVICE ONE Alliance Bank"},
                {id:122, bank_name:"Southern Cross Credit Union"},
                //{id:123, bank_name:"Squirrel Superannuation"},
                // {id:124, bank_name:"St.George Bank"},
                {id:125, bank_name:"State Custodians"},
                {id:126, bank_name:"Summerland Credit Union"},
                // {id:127, bank_name:"Suncorp Bank"},
                {id:128, bank_name:"Switzer Home Loans"},
                {id:129, bank_name:"Swoosh Finance"},
                {id:131, bank_name:"Teachers Mutual Bank"},
                {id:132, bank_name:"The Capricornian"},
                {id:133, bank_name:"The Mac"},
                {id:134, bank_name:"The Mutual"},
                {id:135, bank_name:"The Rock"},
                {id:137, bank_name:"Transport Mutual Credit Union"},
                {id:138, bank_name:"Ubank"},
                {id:139, bank_name:"UniBank"},
                {id:140, bank_name:"Unity Bank"},
                {id:141, bank_name:"Virgin Money"},
                // {id:142, bank_name:"Wallet Wizard"},
                {id:143, bank_name:"Well Home Loans"},
                // {id:144, bank_name:"Westpac"},
                {id:146, bank_name:"Woolworths"},
                {id:147, bank_name:"Woolworths Employees CU"},
                {id:148, bank_name:"Yellow Brick Road"},
                //{id:150, bank_name:"St Andrew's Australia"},
                {id:151, bank_name:"Pepper Money"},

                //{id:152, bank_name:"GE Money"},
                {id:154, bank_name:"Toyota Finance"},
                {id:155, bank_name:"Gympie Credit Union"},
                {id:163, bank_name:"Lombard"},
                {id:165, bank_name:"American Express - Qantas"},
                {id:166, bank_name:"American Express - Velocity"},
                {id:167, bank_name:"American Express - David Jones"},
            ],
            bank_products: [
                { id: 72, lid:721, product:["Credit Card", "Personal Loan"]},
                { id: 72, lid:722, product:["Credit Card"]},
                { id: 72, lid:723, product:["Credit Card", "Personal Loan"]},
                { id: 72, lid:724, product:["Credit Card"]},
                { id: 72, lid:725, product:["Credit Card"]},
                { id: 1, product:["Credit Card", "Home Loan" ]},
                { id: 127, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 22, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 42, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 20, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 99, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 14, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 25, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 124, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 32, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 47, product:[]},
                { id: 15, product:["Credit Card", "Home Loan"]},
                { id: 2, product:["Credit Card" ]},
                { id: 88, product:[]},
                { id: 144, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 37, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 4, product:[]},

                { id: 5, product:["Personal Loan", "Home Loan" ]},
                { id: 7, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 8, product:["Personal Loan", "Home Loan" ]},
                { id: 9, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 10, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 11, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 12, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 13, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 16, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 17, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 18, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 19, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 21, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 23, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 26, product:["Personal Loan", "Home Loan" ]},
                { id: 27, product:[]},
                { id: 28, product:["Personal Loan"]},
                { id: 29, product:["Personal Loan", "Home Loan" ]},
                { id: 30, product:["Personal Loan"]},
                { id: 31, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 33, product:["Personal Loan"]},
                { id: 35, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 36, product:["Credit Card", "Personal Loan"]},
                { id: 38, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 39, product:[]},
                { id: 40, product:[]},
                { id: 41, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 43, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 44, product:["Home Loan"]},
                { id: 45, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 46, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 49, product:["Personal Loan", "Home Loan"]},
                { id: 50, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 51, product:["Home Loan"]},
                { id: 52, product:["Credit Card"]},
                { id: 53, product:["Home Loan"]},
                { id: 54, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 55, product:["Personal Loan", "Home Loan"]},
                { id: 56, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 57, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 59, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 60, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 61, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 62, product:["Home Loan"]},
                { id: 63, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 64, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 65, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 66, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 67, product:["Personal Loan", "Home Loan"]},
                { id: 68, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 70, product:["Credit Card"]},
                { id: 73, product:["Personal Loan", "Home Loan"]},
                { id: 74, product:["Home Loan"]},
                { id: 77, product:["Credit Card", "Home Loan"]},
                { id: 78, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 83, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 85, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 86, product:["Credit Card"]},
                { id: 87, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 89, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 90, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 91, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 92, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 93, product:["Personal Loan"]},
                { id: 94, product:[]},
                { id: 97, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 101, product:["Personal Loan", "Home Loan"]},
                { id: 102, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 103, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 104, product:["Credit Card"]},
                { id: 105, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 106, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 107, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 108, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 109, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 110, product:[]},
                { id: 111, product:["Home Loan"]},
                { id: 112, product:["Home Loan"]},
                { id: 114, product:["Home Loan"]},
                { id: 115, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 116, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 117, product:["Home Loan"]},
                { id: 118, product:["Home Loan"]},
                { id: 119, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 120, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 122, product:["Personal Loan", "Home Loan"]},
                { id: 125, product:["Home Loan"]},
                { id: 126, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 128, product:["Home Loan"]},
                { id: 129, product:["Personal Loan"]},
                { id: 131, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 132, product:["Personal Loan", "Home Loan"]},
                { id: 133, product:["Personal Loan", "Home Loan"]},
                { id: 134, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 135, product:["Personal Loan", "Home Loan"]},
                { id: 137, product:["Personal Loan", "Home Loan"]},
                { id: 138, product:["Home Loan"]},
                { id: 139, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 140, product:["Credit Card", "Personal Loan", "Home Loan"]},
                // { id: 141, product:["Credit Card"]},
                { id: 141, product:["Credit Card", "Home Loan"]},
                { id: 143, product:["Home Loan"]},
                { id: 146, product:["Credit Card"]},
                { id: 147, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 148, product:["Home Loan"]},
                { id: 150, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 151, product:["Personal Loan", "Home Loan"]},
                { id: 152, product:["Credit Card", "Personal Loan"]},
                { id: 154, product:["Personal Loan"]},
                { id: 155, product:["Personal Loan", "Home Loan"]},
                { id: 163, product:["Credit Card", "Personal Loan", "Home Loan"]},
                { id: 165, product:["Credit Card"]},
                { id: 166, product:["Credit Card"]},
                { id: 167, product:["Credit Card"]},
            ],
            bank_questions: [
                { id: 2, qid:13, question:'Have you taken out this before 2012?', answers:["Yes", "No"]},
                { id: 64, qid:11, question:'Have you taken out this before 2012?', answers:["Yes", "No"]},
                { id: 141, qid:12, question:'Have you taken out this in the last 15 years?', answers:["Yes", "No"]},
            ],    
        }
    }  
}