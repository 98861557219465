import React, { Fragment,useState,useLayoutEffect,useContext } from "react";
import Email from "../../UI/Email";
import Telephone from "../../UI/Telephone";
import InputButton from "../../UI/InputButton";
import CheckBox from "../../UI/CheckBox";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";
import PrivacyPopup from "../Layouts/Common/PrivacyPopup";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import dataLayer from '../DataLayer';

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  loaderClass,
  loaderStatus,
  disabled,
  getValues,
  adjustClass,
  formSubmit,
  Sass,
  showModal,
  skipmodalstatus
}) => {

  const [privacyModalShow, setPrivacyModalShow] = useState(false);
  // const [isAgreedChecked, setIsAgreedChecked] = useState(false);
  const [count, setCount] = useState(0);
  const [calculation, setCalculation] = useState(0);
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);

  // const validateSlide4 = async (e) => {
  //   e.preventDefault();
  //   const values = getValues();
  //   const emailValidChk= await trigger("txtEmail");
  //   const phoneValidChk= await trigger("txtPhone");
   
  //  if(emailValidChk && phoneValidChk) {
  //   if (await phoneValidCheck() === 1) {
  //     formSubmit();
  //   }
  // }
  // }
  const validateSlide4 = async (e) => {
    e.preventDefault();
    const values = getValues();
    const emailValidChk= await trigger("txtEmail");
    const phoneValidChk= await trigger("txtPhone");

    // if(!isAgreedChecked){
    //   document.getElementById("agree_terms").innerHTML = "Please agree to continue";
    // }else{
    //   document.getElementById("agree_terms").innerHTML = "";
    // }
   
   if(emailValidChk && phoneValidChk) {
    if (await phoneValidCheck() === 1 && await emailValidCheck() === 1) {
      dataLayer({
        'question': 'Email, Phone',
        'answer': 'Completed'
      });
      showModal();
    }
  }
  }

  const validateSlide4direct = async (e) => {
    e.preventDefault();
    const values = getValues();
    const emailValidChk= await trigger("txtEmail");
    const phoneValidChk= await trigger("txtPhone");
   
   if(emailValidChk && phoneValidChk) {
    if (await phoneValidCheck() === 1) {
      formSubmit();
    }
  }
  }
  
  
  // useLayoutEffect(() => {
  //   setCalculation(() => count + 1);
  //    if(!isAgreedChecked && calculation != 0){
  //       document.getElementById("agree_terms").innerHTML = "Please agree to continue";
  //     }else{
  //       document.getElementById("agree_terms").innerHTML = "";
  //     }

  // }, [isAgreedChecked]);

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Mobile Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
    else {
      return 0;
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email,visitorParameters.visitor_parameters.uuid)
      
      if( typeof getEmailValidation === 'undefined' || getEmailValidation === null ){
        return 1;
      }

      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: "Please Enter Valid Email",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };


  return (
    <Fragment>
      <div id="slide4" className={className}>
        <h5>90% Completed</h5>
        <div className={'frminpart'}>
          <h4>What's the best way to contact you about your case?</h4>
          <div className={'col-lg-12 col-md-12 col-sm-12 col-12'}>
            <div className={'form-group'}>
              <div className={'input-group'}>
                <Email
                  name="txtEmail"
                  className="form-control"
                  placeholder="Email Address"
                  // onBlur={emailValidCheck}
                  validation={validation({
                    required: "Please Enter Valid Email Address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid Email Address",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtEmail && validationMsg.txtEmail.message
                  }
                ></Email>
                <i className="tick fa_privcy" style={{ display: "none" }}></i>
                <div className={'input-group-append'}>
                  <span className={Sass['input-group-text']}>
                    <img src="/assets/SK_V1/img/privacy.png" alt="" className={'privcy pull-right'} />
                  </span>
                </div>
              </div>
              {validationMsg.txtEmail && (
                <span
                  className="error_msg "
                  id="email_err"
                >
                  {validationMsg.txtEmail.message}
                </span>
              )}
              <div
                id="loader-email"
                className="text-center"
                style={{ display: "none" }}
              >
                <i className="tick-loader_secured">
                  <img src="/assets/SK_V1/img/ajax-loader.gif"></img>
                </i>
              </div>
            </div>
          </div>

          <div className={'col-lg-12 col-md-12 col-sm-12 col-12'} >
            <div className={'form-group'}>
              <div className={`input-group ${Sass['margin-tp-30']}`}>
                <Telephone
                  name="txtPhone"
                  className={`form-control nme_inpu`}
                  placeholder="Mobile Number"
                  // onBlur={phoneValidCheck}
                  validation={validation({ required: "Please Enter Mobile Number" })}
                  validationMsg={
                    validationMsg.txtPhone && validationMsg.txtPhone.message
                  }
                ></Telephone>
                <i className="tick fa_privcy" style={{ display: "none" }}></i>

                <div className="input-group-append">
                <span className={Sass['input-group-text']}><img src="/assets/SK_V1/img/privacy.png" alt="" className="privcy pull-right" /></span>
                </div>
              </div>

              <div id="loader-phone" className={'text-center'} style={{ display: 'none' }}>
                <i className={'tick-loader_secured'}>
                  <img src="/assets/SK_V1/img/ajax-loader.gif" />
                </i>
              </div>
              {validationMsg.txtPhone && (
                <span
                  className="error_msg "
                  id="phone_err"
                >
                  {validationMsg.txtPhone.message}
                </span>
              )}
              <p className={`${Sass['margin-tp-30']} ${Sass['alert-text']}`}><strong>Mobile Phone</strong> is recommended. An automated SMS is
                                    sent when we receive news from your lender, and we’ll need to speak to you in order
                                    to finalise details</p>
            </div>
          </div>
          {/* <div className={'col-lg-12 col-md-12 col-sm-12 col-12'} >
          <input
                            type="checkbox"
                            id="isAgreed"
                            name="isAgreed"
                            className={`${Sass.checkbox_style} form-check-input`}
                            onChange={(e) => setIsAgreedChecked(!isAgreedChecked)}
                            value={isAgreedChecked}
                        />
          <label className={`${Sass.checkbox_label} form-check-label`}>By clicking SUBMIT, I agree that I have not previously claimed or is a class action member
                </label>
                <span className="error_msg " id="agree_terms"></span>
          </div> */}
           &nbsp;
          <div className={'col-lg-12 col-12 text-center'}>
            <div className={'main_1'}>
              <InputButton
                name="backslide4"
                className={`${Sass.btn} btn ${Sass.backbt}`}
                id="back03"
                value="< Back"
                btnType="button"
                onClick={backClick}
              />
              &nbsp;
            
              {skipmodalstatus ? <InputButton
              name="formSubmitBtn"
              className={`${Sass.btn} btn next04 ${Sass.getstart_but} applynow02  slide9_subtn`}
              id=""
              value="Submit"
              btnType="button"
              style={{ float: "none" }}
              disabled={disabled}
              // onClick={showModal}
              onClick={validateSlide4direct}
            />:<InputButton
            name="formSubmitBtn"
            className={`${Sass.btn} btn next04 ${Sass.getstart_but} applynow02  slide9_subtn`}
            id=""
            value="Submit"
            btnType="button"
            style={{ float: "none" }}
            disabled={disabled}
            // onClick={showModal}
            onClick={validateSlide4}
            />}


              {/* <InputButton
                name="formSubmitBtn"
                className={`${Sass.btn} btn ${Sass.getstart_but} applynow02  slide9_subtn`}
                id=""
                value="Submit"
                btnType="submit"
                style={{ float: "none" }}
                disabled={disabled}
                onClick={validateSlide4}
              /> */}
            </div>
          </div>
          <div className={`col-lg-12 col-md-12 col-xs-12 ${Sass.frm_lst}`}>
            <p>Your privacy is important to us. By submitting your details you are agreeing with our
              {/* <a style={{ cursor: 'pointer', color: '#036dff' }} onClick={() => setPrivacyModalShow(true)}> Privacy Policy </a> */}
              <a style={{ cursor: 'pointer', color: '#036dff' }} href="/assets/Privacy_Policy_v1.1-Reclaimer.pdf" role="button" tabIndex="0" target="_blank" > Privacy Policy </a>
              and to receive a call, email and /or SMS
         with details of making a claim for CCI</p>
          </div>
        </div>
      </div>

     <PrivacyPopup show={privacyModalShow} onHide={() => setPrivacyModalShow(false)}  Sass={Sass} />
    </Fragment>
  );
};
export default ContactDetails;
