//import {useState} from 'react'; 
import {Api} from '../api/Api';

export const usePostCodeAddress = () => {
    //const [isLoading,setIsLoading] = useState(false);
    // const getPostcodeValidation = async (postcode) => {
    //     const response = await Api.get("api/v1/postcode-val?postcode=" + postcode, { 
    //     });
    //     return response;
    // }
    const getPostcodeAddress = async (Addrkeyword) => {
        const response = await Api.get("api/v1/get-addr-list-api-aus?address=" + Addrkeyword, { 
        });    
        

        /*** test case ***/
//         let a = Addrkeyword;
// const response = [{"value":"","label":"Please Select Address"},{"value":"81b3a84e-da75-11eb-9686-68c0871815b5","label":"Unit 1012, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"},{"value":"81b360c8-da75-11eb-9686-68c0871815b5","label":"Unit 1008, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"},{"value":"005926b6-da75-11eb-bf99-68c0efbd8816","label":"Unit 1001A, 1 Carter Street, LIDCOMBE NSW 2141"},{"value":"81b34f48-da75-11eb-9686-68c0871815b5","label":"Unit 1007, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"},{"value":"81b33b34-da75-11eb-9686-68c0871815b5","label":"Unit 1006, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"},{"value":"81b316b8-da75-11eb-9686-68c0871815b5","label":"Unit 1004, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"},{"value":"81b328f6-da75-11eb-9686-68c0871815b5","label":"Unit 1005, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"},{"value":"81b2d8ce-da75-11eb-9686-68c0871815b5","label":"Unit 1001, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"},{"value":"e3ea5bc2-da73-11eb-bf99-68c0efbd8816","label":"Unit 1001, Level 10, 1 Elizabeth Plaza, NORTH SYDNEY NSW 2060"},{"value":"81b2f0f2-da75-11eb-9686-68c0871815b5","label":"Unit 1002, Floor 10, 1 Clara Street, SOUTH YARRA VIC 3141"}];

    
        return response;
    }
    const getSplitPostcodeAddress = async (AddressID) => {
        const response = await Api.get("api/v1/get-addr-details-api-aus?address_id=" 
        +AddressID, { 
        });


     /**** test case ****/

    //  const response = {"address_line_1":"Unit 1012, Floor 10","address_line_2":"1 Clara Street","postcode":"3141","suburb":"SOUTH YARRA","state":"VIC"}


        return response;
    }
    return { getPostcodeAddress,getSplitPostcodeAddress } 
}

