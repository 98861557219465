import React, {useEffect} from 'react';
import TyStyle from '../../../assets/Thankyou/css/Thankyou.module.scss';
//import AnimateStyle from '../../../assets/Thankyou/css/Animated.module.scss';
import ThankyouHeader from "../../Includes/Layouts/ThankyouHeader";
import ThankyouFooter from '../../Includes/Layouts/ThankyouFooter';
const Thankyou = () =>{
    localStorage.clear();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
          
    return (
        <>
            <ThankyouHeader TyStyle={TyStyle}/>
            <section className={TyStyle['section-padd']}>
                <div class="container">
                    <div class="row">
                    <div class="col-lg-12 text-center">
                        <h1>Thank You</h1>
                        <p>Thanks for signing up to our service; we have started to contact your lenders and will soon have updates for you.</p>
                    </div>
                    </div>
                </div>
            </section>
            <ThankyouFooter TyStyle={TyStyle} />
         </>
    )
}

export default Thankyou;
