import React, { Fragment } from "react";
import SKV3Accordion from "../../../UI/BootstrapAccordion";

const StaticSection = ({ content, Sass }) => {
  return (
    <Fragment>
      <section className={Sass.experts}>
        <div className={` ${Sass.container} container text-center`}>
          <h2>WHAT PRODUCTS CAN I CHECK ON?</h2>
          <div className={"row"}>
            <div className={"col-md-3 col-sm-12 col-12 text-center"}>
              <div className={"feature-icon text-center"}>
                {" "}
                <img src="/assets/SK_V2/img/im1.png " alt="" />{" "}
              </div>
              <h4>Personal Loans</h4>
              <p>Secured, Unsecured</p>
            </div>
            <div className={"col-md-3 col-sm-12 col-12 text-center"}>
              <div className={"feature-icon text-center"}>
                {" "}
                <img src="/assets/SK_V2/img/im2.png " alt="" />{" "}
              </div>
              <h4>CREDIT CARDS</h4>
              <p>Standard, 0% Balance Transfer, Rewards, No Annual Fee</p>
            </div>
            <div className={"col-md-3 col-sm-12 col-12 text-center"}>
              <div className={"feature-icon text-center"}>
                {" "}
                <img src="/assets/SK_V2/img/im3.png " alt="" />{" "}
              </div>
              <h4>CAR LOANS</h4>
              <p>Personal, Contract Purchase, Hire Purchase</p>
            </div>
            <div className={"col-md-3 col-sm-12 col-12 text-center"}>
              <div className={"feature-icon text-center"}>
                {" "}
                <img src="/assets/SK_V2/img/im4.png " alt="" />{" "}
              </div>
              <h4>HOME LOANS</h4>
            </div>
          </div>
        </div>
      </section>

      <section className={`${Sass["brought-ppi"]} text-center`}>
        <div className={` ${Sass.container} container`}>
          <h2>BROUGHT TO YOU BY THE CCI EXPERTS</h2>
          {/*<p>CCI policies were sold alongside loans and credit cards.</p>*/}
          <p>
            Reclaimer has partnered with one of the UK’s leading claims
            companies who have so far reclaimed over £600 million (A$1.1
            billion) for their UK customers for PPI (which is the UK equivalent
            of CCI)
          </p>
          <div className={"row"}>
            <div className={"col-lg-6 col-sm-6 col-12 col-list"}>
              <ul>
                <li>
                  <span>
                    With our combined experience, we’ve now launched in
                    Australia.{" "}
                  </span>{" "}
                  <br />
                  We were not surprised when we discovered that billions of
                  dollars worth of useless CCI policies were sold over the last
                  10-15 years by banks and lenders to their own customers who
                  were applying for loans, credit cards, home loans and car
                  loans.
                </li>
                <li>
                  <span>Find out if you’re owed anything.</span>
                  <br />
                  Most customers are not aware they even had CCI. Have you
                  checked?
                </li>
                <li>
                  <span>Start a claim, and we’ll handle everything else.</span>
                  <br />
                  Please bear in mind that you can manage the investigation and
                  complaint process yourself, but if you do it through us, then
                  we’re very happy to apply our ten years of experience on your
                  behalf.
                </li>
              </ul>
            </div>
            <div className={"col-lg-6 col-sm-6 col-12"}>
              <img
                src="/assets/SK_V2/img/rgt_im.png"
                className={"img-fluid"}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className={Sass.faqs}>
        <div className={` ${Sass.container} container`}>
          <div className={"row"}>
            <div className={"col-lg-12 col-md-10 col-sm-12 col-12 text-center"}>
              <h3>FAQs</h3>

              <SKV3Accordion content={content} Sass={Sass} />
            </div>
            <div className={"col-lg-12 col-12 text-center"}>
              <p className={`${Sass["btn"]} btn`}>
                <a href="#">CLAIM NOW</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default StaticSection;
