import axios from 'axios';
require('dotenv').config();
require('dotenv').config();
const adtopia_api_url = process.env.REACT_APP_ADTOPIA_TRACKING_API;
const adtopia_api_token = process.env.REACT_APP_ADTOPIA_TOKEN;
export const AdtopiaApi =  axios.create({
    baseURL: adtopia_api_url,
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization":"Bearer "+adtopia_api_token,
    }
});
