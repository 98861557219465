import React, { Fragment } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
const BootstrapAccordion = ({ content, Sass }) => {

  const contentString = content.map((item, index) => {
    return (
      <Card className={`${Sass['faqs-card']} card`}>
        <Card.Header className={Sass['card-header']}>
          <Accordion.Toggle as={Button} variant="link" eventKey={`key_${ index}`} className={`${Sass['card-btn']} btn btn-link accordion-toggle`} >{item.question}</Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={`key_${ index}`}>
          <Card.Body dangerouslySetInnerHTML={{ __html: item.content }}></Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });

  return (
    <Fragment>
      <Accordion defaultActiveKey="key_0" className={Sass.accordion}>
        {contentString}
      </Accordion>
    </Fragment>
  );
};

export default BootstrapAccordion;