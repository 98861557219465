import {Api} from '../api/Api2';

export const useEmailValidation = () => {

    const emailValidation = async (email,visitor_uuid) => {
        const getValidation = await Api.get(
            "api/v1/get-email-validation?email=" + email + "&uuid=" + visitor_uuid, 
            {}
          )
        .catch(error => {
            console.log(error);
        });
        return getValidation;
    }
    return { emailValidation }
}