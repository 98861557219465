import React from "react";

const SlideQuestion4 = ({
  Sass,
  slideChange,
  className,
  setReminder
}) => {

  return (
    <>
      <section id="question4" className={`${Sass['content-part']} ${Sass['animated']} ${Sass['animatedFadeInUp']} ${Sass['fadeInUp']} ${className}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6  col-lg-6 col-md-12 col-12 text-center ">
              <div className={Sass['video_div']}>
                <video autoPlay muted playsInline loop tabIndex="0">
                  <source src="assets/UserVerificationV1/video/video-intro3.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={`row align-items-center ${Sass['text-div']}`}>
                <img src="assets/UserVerificationV1/img/logo.png" className={Sass['logo']} alt="" />
                <div className="col-12 col-lg-12 col-xl-12">
                  <h4>To avoid you missing out on this once-in-a-lifetime opportunity we have set an automated reminder for you and will SMS you tomorrow.</h4>
                  <h4 className={Sass['cust-size']}>Highest Offer Recieved by our Clients: $10,348</h4>
                  <h4 className={Sass['cust-size']}>Average Offer Received by our Clients: $1720</h4>
                </div>
                <div className=" col-md-12 col-12 align-middle d-block d-lg-none">
                  <a className={` ${Sass['btn-home']} btn4`} onClick={setReminder}>Set Reminder</a> 
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12 text-center white_bg my-auto d-none d-lg-block">
              <a className={`${Sass['btn-home']} btn4`} onClick={setReminder}>Set Reminder</a> 
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SlideQuestion4;
