import React, { useEffect, useState, useRef, useContext, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Lendors from "../Includes/Splits/Lendors";
import BasicDetails from "../Includes/Splits/BasicDetails";
import ContactDetails from "../Includes/Splits/ContactDetails";
import PreviousNamesModal from "../Includes/Layouts/PreviousNamesModal";
import PreviousNames from "../Includes/Layouts/PreviousNames";
import PostCode from "../Includes/Splits/PostCode";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide"
}
const FormReducer = (state, action) => {
  switch (action.type) {
    case 'showSlide': {
      if (action.payload.clickedSlide.slide == 'slide1') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show"
        }
      } else if (action.payload.clickedSlide.slide == 'slide2') {
        return {
          ...state,
          showSlide2: "hide",
          showSlide3: "show"
        }
      } else if (action.payload.clickedSlide.slide == 'slide3') {
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show"
        }
      } else if (action.payload.clickedSlide.slide == 'slide4') {
        return {
          ...state,
          showSlide4: "hide",
          showSlide5: "show"
        }
      }else {
        return {
          ...state
        }
      }
    }
    case 'previousSlides': {
      if (action.payload.previousSlide.slide == 'backslide2') {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide"
        }
      } else if (action.payload.previousSlide.slide == 'backslide3') {
        return {
          ...state,
          showSlide2: "show",
          showSlide3: "hide"
        }
      } else if (action.payload.previousSlide.slide == 'backslide4') {
        return {
          ...state,
          showSlide3: "show",
          showSlide4: "hide"
        }
      }
      else if (action.payload.previousSlide.slide == 'backslide5') {
        return {
          ...state,
          showSlide4: "show",
          showSlide5: "hide"
        }
      }
      else {
        return {
          ...state
        }
      }
    }
  }
}
const FormSK_V2 = ({ Sass }) => { 
  
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [skipmodalstatus, setSkipmodalstatus] = useState(false);
  const [previousAddr, setPreviousAddr] = useState([]);
  const [previoussurname, setPrevioussurname] = useState([]);
  const { queryString } = useContext(QueryStringContext);
  const splitForm = useRef(null);
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    reset 
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  
  const [showPreNamesModal, setshowPreNamesModal] = useState(false);
  const handlePreNamesModalShow=()=>{
    setshowPreNamesModal(true);
  };
  const handlePreNamesModalClose=()=>{
    setshowPreNamesModal(false);
  };


  const [bankSelectedValue, setBankSelectedValue] = useState([]);
  const [LendedTypeArray, setLendedTypeArray] = useState([]);
  const [otherBankSelectedList, setOtherBankSelectedList] = useState([]);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [loaderClass, setLoaderClass] = useState("load");
  const [loaderStatus, setLoaderStatus] = useState("hide");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const foDetails = {};
  const [bankProducts, setBankProducts] = useState([]);
  // **********form submit*************************************************
    
  useEffect(()=>{
    console.log("show modal status ");
    console.log(skipmodalstatus);
    if(skipmodalstatus == true) {
      formSubmit();
    }
  }, [skipmodalstatus]);
  
  const formSubmit = async () => {
    setLoaderClass("load");
    setLoaderStatus("show");
    setBtnDisabled(true);
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    // if (formData.address1 !== "") {
    //   const txtUdprn = form["txtUdprn"].value;
    //   const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
    //   const txtPz_mailsort = form["txtPz_mailsort"].value;
    //   const txtStreet = form["txtStreet"].value;
    //   formData.txtUdprn = txtUdprn;
    //   formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
    //   formData.txtPz_mailsort = txtPz_mailsort;
    //   formData.txtStreet = txtStreet;
    // }
    let bankIds = bankSelectedValue.map((a) => a.id);
    let otherBankIds = otherBankSelectedList.map((a) => a.id);
    //formData.lenderType = LendedTypeArray;
    formData.lenderType = bankProducts;
    formData.bankList = bankIds;
    formData.otherBankList = otherBankIds;
    formData.page_name = 'cci_claims/SK_V1';
    formData.foDetails = foDetails;
    formData.previous_address_list = previousAddr;
    formData.previous_surname_list = previoussurname;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // setFormParameters(formData);
      // setter
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('queryString',queryString );
      
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'cci_claims/SK_V1',
        queryString,
        visitorParameters.data
      )
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            history.push("/fbpixel");// fb fixel firing
          } else {
            history.push(
              "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        } else {
          history.push(
            "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      }
    }
  };
  // ******************************Slide Chnage***************************************
  const slideChange = (e) => {
    window.scrollTo(0, 0);
    setClickedSlide({ slide: e.target.name });
  };
  const previousSlideChange = (e) => {
    window.scrollTo(0, 0);
    setPreviousSlide({ slide: e.target.name });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: 'showSlide', payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);

  const modalclosefn = ()=>{
    console.log("modal close function");
    window.scrollTo(0, 0);
    setClickedSlide({ slide: 'slide4' });
 }
  // *********************Address validation*******************************************
  return (
    <form
      ref={splitForm}
      name="split_form"
      id="user_form"
      method="POST"
    >
      <Lendors
        className={`slide06 ${Sass[state.showSlide1]}`}
        setBankSelectedValue={setBankSelectedValue}
        setOtherBankSelectedList={setOtherBankSelectedList}
        setLendedTypeArray={setLendedTypeArray}
        slideChange={slideChange}
        Sass={Sass}
        bankProducts={bankProducts}
        setBankProducts={setBankProducts}
      ></Lendors>
      <BasicDetails
        className={`slide07 ${Sass[state.showSlide2]}`}
        backClick={previousSlideChange}
        validation={register}
        validationMsg={errors}
        trigger={trigger}
        slideChange={slideChange}
        Sass={Sass}
      ></BasicDetails>
      <PostCode
        className={`slide07 ${Sass[state.showSlide3]}`}
        validation={register}
        validationMsg={errors}
        trigger={trigger}
        foDetails={foDetails}
        clearErrors={clearErrors}
        setError={setError}
        textContent="Your Current Address"
        onBackClick={previousSlideChange}
        slideChange={slideChange}
        Sass={Sass}
      />
      <ContactDetails
        validation={register}
        validationMsg={errors}
        setError={setError}
        trigger={trigger}
        getValues={getValues}
        className={`slide09 ${Sass[state.showSlide4]}`}
        backClick={previousSlideChange}
        loaderClass={loaderClass}
        loaderStatus={loaderStatus}
        disabled={btnDisabled}
        Sass={Sass}
        slideChange={slideChange}
        showModal={handlePreNamesModalShow}
        skipmodalstatus={skipmodalstatus}
        formSubmit={formSubmit}
      ></ContactDetails>
      <PreviousNamesModal
        showstatus={showPreNamesModal} 
        hidepopup ={handlePreNamesModalClose}
        validation={register}
        validationMsg={errors}
        trigger={trigger}
        clearErrors={clearErrors}
        setError={setError}
        modalcloseupfn = {modalclosefn}
        skipmodalfn   = {setSkipmodalstatus}
      ></PreviousNamesModal> 

      <PreviousNames
        validation={register}
        validationMsg={errors}
        setError={setError}
        trigger={trigger}
        getValues={getValues}
        clearErrors={clearErrors}
        className={`slide10 ${Sass[state.showSlide5]}`}
        backClick={previousSlideChange}
        loaderClass={loaderClass}
        disabled={btnDisabled}
        formSubmit={formSubmit}
        reset={reset}
        preaddressfetch = {setPreviousAddr}
        presurnamefetch = {setPrevioussurname}
        Sass={Sass}
      ></PreviousNames>
    </form>
  );
};

export default FormSK_V2;