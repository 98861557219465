import React from "react";

const Loader = ({LdStyle, showLoad}) => {
  return (
    <>
      <section id="myDiv" className={LdStyle[showLoad]}>
        <div className={LdStyle['questionspart']}>
          <div className="container">
            <div className="row">
              <div  className={`col-12 col-sm-12 l_bg`}>
                <form>                   
                  <div className={`${LdStyle['space']} text-center`}>
                    <img
                      src="/assets/Followup/img/review.gif" alt=""
                      />
                    <h3>Analyzing your application....</h3>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>    
    </>
  );
};

export default Loader;
