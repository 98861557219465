import React from "react";

const Telephone = ({ placeholder, name, className, validation, onBlur }) => {
  return (
    <input
      type="tel"
      name={name}
      className={className}
      placeholder={placeholder}
      ref={validation}
      onBlur={onBlur}
      onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
    />
  );
};
export default Telephone;
