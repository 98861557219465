import { useEffect, useState, useContext, Fragment } from "react";
import axios from "axios";
import FrankieSmartUI from "../Pages/Splits/FrankieSmartUi";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import { FrankieOneAPI } from "../../Hooks/FrankieOneAPI";
import { useHistory } from "react-router-dom";
import VerificationStyle from "../../assets/Verification/css/Verification.module.scss";
import SignStyle from "../../assets/Signature/css/Signature.module.scss";

const SmartUI = ({ }) => {

  const { saveStatus } = FrankieOneAPI();

  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const history = useHistory();

  const FRANKIE_URL = process.env.REACT_APP_FRANKIE_URL;

  const FRANKIE_CONFIG = {
    frankieBackendUrl: FRANKIE_URL,
    documentTypes: ["DRIVERS_LICENCE", "PASSPORT"],
    idScanVerification: {
      injectedCss: '.onfido-sdk-ui-Theme-step { min-height: 90% }'
    },
    ageRange: [18, 125],
    organisationName: "Reclaimer's",
    googleAPIKey: false,
    phrases: {
      document_select: {
        title: "Choose your ID",
        hint_message: "Choose which ID you'd like to provide."
      },
    },
    welcomeScreen: {
      type: true,
      htmlContent: [
        "<div><h3><strong>Verify Your Identity</strong></h3><p>As the claims process will involve interaction with the banks and lenders you have told us about, for your security, we need to verify your identity.<br><br>It's important you enter your details as accurately as possible.<br><br>The information you provide will be checked using the Australian Government Document Verification Service (DVS).</p></div>",
      ],
      ctaText: "Start Identity Verification",
    },
    phrases: {
      success_screen: {
        title: "Verification Completed",
        subtitle: "Your identity has been verified.",
      },
    },
    requestAddress: { acceptedCountries: ["AUS", "NZL"] },
    consentText:
      "I agree with the terms described in the Consent section of the Reclaimer's webpage",
  };
  const CREDENTIALS = '' + process.env.REACT_APP_FRANKIE_CUSTOMER_ID + ':' + process.env.REACT_APP_FRANKIE_API_KEY;
  const ENCODED_CREDENTIALS = Buffer.from(CREDENTIALS).toString('base64');// use your base64 encoded credentials!

  const FRANKIE_HEADERS = { authorization: `machine ${ENCODED_CREDENTIALS}` };

  const [start, setStart] = useState(false);
  const [open, setOpen] = useState(false);

  const FRANKIE_EVENTS = ['INIT', 'SCREEN:WELCOME', 'SCREEN:DOCUMENT_TYPE_SELECT', 'SCREEN:DRIVERS_LICENCE_INPUT', 'SCREEN:NATIONAL_ID', 'SCREEN:MEDICARE_INPUT', 'SCREEN:PASSPORT_INPUT', 'SCREEN:NAME_INPUT', 'SCREEN:DOB_INPUT', 'SCREEN:ADDRESS_INPUT', 'SCREEN:DETAILS_SUMMARY', 'SCREEN:FAILURE', 'SCREEN:SUCCESS', 'SCREEN:PENDING_VERFICATION', 'SCREEN:ERROR', 'DESTROY', 'FF_SAVE_RESULT', 'FF_CHECK_RESULT', 'FF_SAVE_ERROR', 'FF_CHECK_ERROR', 'FF_EXTERNAL_IDV_CHECK_COMPLETED'];

  useEffect(() => {

    const initialiseWidget = async () => {
      const data =
        await axios.post(`${FRANKIE_URL}/auth/v1/machine-session`, {}, {
          headers: FRANKIE_HEADERS,
        }
        )
      const {
        headers: { token: ffToken },
      } = data;

      let fo_entity_id = localStorage.getItem('fo_entity_id');
      let form_data = localStorage.getItem('formData');

      window.frankieFinancial.initialiseOnboardingWidget({

        entityId: fo_entity_id, // This will be your applicants reference code and corresponds to the customer_reference value in the FrankieOne Core API 

        config: FRANKIE_CONFIG,
        ffToken,
        width: `${window.innerWidth * 0.9}px`,
        height: `${window.innerHeight / 1.2}px`,
      });

      const eventEffect = function (eventName, event) {
        saveStatus(fo_entity_id, event.detail, eventName,'live');

        if ('SCREEN:SUCCESS' == eventName) {
          setTimeout(() => {
            history.push("/thankyou");
          }, "3000");
        }

        if ('FF_CHECK_RESULT' == eventName) {
          if ((JSON.parse(form_data).txtEmail).includes('@922.com')) {
            history.push("/thankyou");
          }
        }
      }

      FRANKIE_EVENTS.forEach(function (eventName, index) {
        window.addEventListener(eventName, function (e) {
          eventEffect(eventName, e);
        });
      });

    };

    if (window.frankieFinancial && !start) {
      console.log('inside loop');
      initialiseWidget();

      // temp code
      setOpen(true);
      setStart(true);
    }
  }, [start]);

  return (
    <Fragment>
      <section className={SignStyle['sign-body']}>
        <div className={`  container`}>
          <div className="col-lg-8 offset-lg-2 col-md-12 col-12 ">
            <div className={`row ${SignStyle["signature-part"]}`}>
              <div className="col-12 text-center">
                <img
                  src="assets/Signature/img/ft-logo.png"
                  className={` ${SignStyle["sign-logo"]} `}
                  alt=""
                />
                <h1>Almost Done...</h1>
                <p>
                  Your claim process is almost ready to begin.<br />
                  Some banks and lenders will require proof of your identity to support a claim.<br />
                  To avoid any unnecessary delays, click below to verify your identity now.
                </p>
                <section className={VerificationStyle['verification-body']}>
                  <button className={VerificationStyle['verification-button']}
                    onClick={() => {
                      setOpen(true);
                      setStart(true);
                    }}
                  >
                    {start ? `Continue Verification!` : `Verify your identity!`}
                  </button>
                  <FrankieSmartUI show={open} onClose={() => setOpen(false)} />
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default SmartUI;
