import { useEffect, useState } from "react";


const Surname_Pre = ({
    validation,
    validationMsg,
    onBackClick,
    clearErrors,
    setError,
    slideChange,
    adjustClass,
    trigger,
    presurnamefetch,
    Sass
  }) => {

    const [selectSurname, setSelectSurname] = useState('');
    const [finalsurname, setFinalsurname] = useState([]);
    const [surnamecounter, setSurnamecounter] = useState(0);
    const [hidesurnamestyle, setHidesurnamestyle] = useState('show');
    const [surname_placeholder, setSurname_placeholder] = useState('Previous Surname')
    const Surnamestore = ()=>{
        if(selectSurname !=''){
            setFinalsurname([...finalsurname, selectSurname]);
            setSurnamecounter(surnamecounter + 1);
            setSelectSurname('');
        }
    }

    useEffect(()=>{
        presurnamefetch(finalsurname);
    },[finalsurname]);

    useEffect(()=>{
        if(surnamecounter >= 1){
            setSurname_placeholder('Please Enter Another Previous Name(s)(if any)')
          }else{
            setSurname_placeholder('Previous Name(s)')
          }
        if(surnamecounter== 5){
            setHidesurnamestyle('hide');
        }else{
            setHidesurnamestyle('show');
          // hidepoststyle
        }
      },[surnamecounter]);


    const handleChange =(e)=>{
        const re = /^[a-zA-Z\s]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
           setSelectSurname(e.target.value);
        }
    }
    const removeSurname =(e,id)=>{
        e.preventDefault();
        // finalsurname.pop(id);
         console.log(id);
         setFinalsurname(finalsurname =>(finalsurname.filter((item, itemIndex) => itemIndex != id)));
         setSurnamecounter(surnamecounter - 1);
    }
    const selectSurnameArr = finalsurname.map((surname, index) => {
        return (
            <div className={`addressbox ${Sass.prev_addr_bx}`} id={index} >
            <h6>Previous surnames:</h6>
            <p>{surname}</p>
            <a href="#" className={`hideadrbox ${Sass.hide_srh_bx_a}`}>
                <p className={`text-end ${Sass.remove_adr_btn}`} onClick={(e) => removeSurname(e,index)} ><span><img src="/assets/SK_V1/img/remove.svg" alt=""/></span> Remove</p>
            </a>
        </div>
        );
      });

    return(
        <>
         <fieldset className={`p-2 ${Sass.surname_outer}`}>
            <legend className="float-none w-auto px-3">Enter your previous name(s) below</legend>
            {/* <!-- <p><b>Enter your previous surname below.</b></p> --> */}
            <p className={`${Sass.surname_p_blank}`}><i>(Leave blank if not applicable.)</i></p>
              {selectSurnameArr}
            <div id='namesec1' className={hidesurnamestyle}>
                <div className="form-group">
                    <input  type="text" className="form-control hero-input" placeholder={surname_placeholder} value={selectSurname} onChange={handleChange} />
                </div>
                <div className="col-lg-12 col-12 form-group text-center clearfix" id="savename1">
                    <input name="next" className={`btn save-btn savename savecustom-btn ${Sass.savecustom_btn_surname}`} value="Save" type="button" onClick={Surnamestore}/>
                </div>
            </div>
            {/* <div className="addressbox" id="add_name2" style={{display: 'none'}}>
                <h6>Previous surnames:</h6>
                <p>Surname</p>
                <a href="#" className="hidenamebox2">
                    <p className="text-end"><span><img src="dist/img/remove.svg" alt=""/></span> Remove</p>
                </a>
            </div>
            <div id="namesec2" style={{display: 'none'}}>
                <div className="form-group">
                    <input  type="text" className="form-control hero-input" placeholder="Previous Surname" value=""/>
                </div>
                <div className="col-lg-12 col-12 form-group text-center clearfix" id="savename2">
                    <input name="next" className="btn save-btn savename2 " value="Save" type="button"/>
                </div>
            </div> */}
        </fieldset>
        </>
    );

  }

  export default Surname_Pre;