import React,{useEffect} from 'react';
import AdtopiaLoadRoot from '../../Utility/AdtopiaLoadRoot';


const HomePage = () => {

    const renderSplitName = (splitName) => {
       // console.log(splitName);
    }


    return (
        <>
            <AdtopiaLoadRoot pageType="home" renderSplitName={renderSplitName} splitName="SK_V1"/>
        </>
    )
}

export default HomePage;