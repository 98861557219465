import LoaPdf from "../assets/Signature/pdf/LOA.pdf";

export const Salutation    =   [
    {
        label:"Select Title",
        value:""
    },
    {
        label:"Mr.",
        value:"Mr"
    },
    {
        label:"Mrs.",
        value:"Mrs"
    },
    {
        label:"Ms.",
        value:"Ms"
    },
    {
        label:"Dr.",
        value:"Dr"
    },
    {
        label:"Prof.",
        value:"Prof"
    },
];

export const DobDay= [{label:"Day",value:''}];
const startDay = 1;
const endDay = 31;
for (var i=startDay;i<=endDay;i++) {
    var objDobDay = {};
    objDobDay["label"] = i;
    objDobDay["value"] = i;
    DobDay.push(objDobDay);
}

export const DobMonth= [
    { label:"Month", value:"" },
    { label:"January", value:"January" },
    { label:"February", value:"February" },
    { label:"March", value:"March" },
    { label:"April", value:"April" },
    { label:"May", value:"May" },
    { label:"June", value:"June" },
    { label:"July", value:"July" },
    { label:"August", value:"August" },
    { label:"September", value:"September" },
    { label:"October", value:"October" },
    { label:"November", value:"November" },
    { label:"December", value:"December" }
];

export const DobYear= [{label:"Year",value:''}];
const startYear = 2002;
const endYear = 1910;
for (var k=startYear;k>=endYear;k--) {
    var objDob = {};
    objDob["label"] = k;
    objDob["value"] = k;
    DobYear.push(objDob);
}

export const signCheckText = `By signing and by pressing “Submit” below, you agree to Check for plevin's <a style="cursor: pointer; color:#00aab1 !important;" target="_blank" href="https://fuselegal.com/wp-content/uploads/2021/03/Fuselegal-Terms-of-Business.pdf">Terms and Conditions</a> and that we may use your signature on the <a style="cursor: pointer; color:#00aab1 !important;" target="_blank" href=${LoaPdf}> Letter of Authority</a> that we send to your bank.  A copy of the <a style="cursor: pointer; color:#00aab1 !important;" target="_blank" href="https://fuselegal.com/wp-content/uploads/2021/03/Fuselegal-Terms-of-Business.pdf">Terms and Conditions</a> and <a style="cursor: pointer; color:#00aab1 !important;" target="_blank" href=${LoaPdf}>Letter of Authority</a> will be sent to you via email.`;