import React, { Fragment } from "react";
import FormSK_V2 from "../../../Forms/FormSK_V2";

const FormSection = ({ content, Sass }) => {

  return (
    <Fragment>
      <div className={'offset-xl-1 col-xl-5 col-lg-5 col-md-12 col-12'}>
        <div className={`col-lg-12 ${Sass['form-part']}`}>
          <FormSK_V2 Sass={Sass} />
        </div>
      </div>
    </Fragment>
  );
};

export default FormSection;
