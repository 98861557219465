import {Api} from '../api/Api';

export const useYahooGeminiRedirection = () => {
    const getYahooGeminiRedirectionUrl = async (device,userAgent,ipAddress,queryString) => {
         const response = await Api.post("api/v1/yahoo-gemini", {
            device,
            userAgent,
            ipAddress,
            queryString
        });
        return response;
    }
    return { getYahooGeminiRedirectionUrl}
}



