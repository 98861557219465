import React from "react";
import PreviousPostCode from "../../../Utility/PreviousPostCode";
import Postcode_Pre from "../../../Utility/Postcode_Pre";
import Surname_Pre from "../../../Utility/Surname_pre";
const PreviousNames = ({
  className,
  validation,
  validationMsg,
  clearErrors,
  setError,
  trigger,
  reset,
  backClick,
  formSubmit,
  preaddressfetch,
  presurnamefetch,
  getValues,
  Sass
}) => {
  
  return (
    <>
      <div className={`${className} special-class-custom`}>
        <div className="frminpart">
        <h4>What are your previous details?</h4>
             <div className="col-lg-12 col-md-12 col-sm-12 col-12 previousslide mob-0">
                <div className="form-group">
                    {/* postcode */}
                       <Postcode_Pre
                        validation={validation}
                        validationMsg={validationMsg}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        setError={setError}
                        reset ={reset}
                        textContent="Your Current Address"
                        preaddressfetch={preaddressfetch}
                        getValues={getValues}
                        Sass={Sass}
                        //    onBackClick={previousSlideChange}
                        //    slideChange={slideChange}
                        />
                        {/* previous surname */}
                         <Surname_Pre
                          presurnamefetch ={presurnamefetch}
                          Sass={Sass}
                         />
                    </div>
                </div>
            
                <div className="col-lg-12 col-12  text-center">
                    <div style={{width:'100%'}} className="main_1">
              <input name="backslide5" className={`btn backbt slide10_back ${Sass.main_back_btn}`} onClick={backClick} id="" value="< Back" type="button"/>
                      <input style={{float:'none'}} name="next" className={`btn center-block next07 getstart_but ${Sass.main_submitsub_btn}`} onClick={formSubmit} value="Submit" type="button"/>
                    </div>
                </div>
          <div className={`col-lg-12 col-md-12 col-xs-12 frm_lst ${Sass.personal_info_quarn}`}>
                  <h6>PERSONAL INFORMATION GUARANTEE</h6>
                  <p className={`${Sass.personal_info_text}`}>We do not cold call, spam or pass on your data for marketing</p>
                </div>





              {/* <div className="col-lg-12 col-md-12 col-12 frm_lst">
              <div className="main_1">
                  <input name="submit1b" className="btn backbt slide10_back" value="< Back" type="button"/>
                  <input name="next" className="btn center-block getstart_but" value="Submit" type="button"/>
                  </div>
                  <p>Your privacy is important to us. By submitting your details you are agreeing with our <a  target="_blank" href="https://dev.secretbankcommissions.co.uk/assets/pdf_docs/privacy-policy.pdf">Privacy Policy</a> and to receive a call, email and /or SMS with details of making a claim for mis-sold PPI</p>
              </div> */}
          </div>
        </div> 
    </>
  );
};
export default PreviousNames;
