import React from 'react';
import { DobYear, DobMonth, DobDay } from "../../../Constants/Constants";
import SelectBox from '../../UI/SelectBox';


const DateOfBirth = ({ textDob, validation, validationMsg, Sass }) => {

    return (
        <fieldset className={Sass['scheduler-border']}>
            <legend className={Sass['scheduler-border']}>{textDob}</legend>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                        <SelectBox
                            className="form-control"
                            OptionValue={DobDay}
                            name="lstDobDay"
                            myRef={validation({
                                required: "Please Select Date"
                            })}
                            validationMsg={validationMsg.lstDobDay && validationMsg.lstDobDay.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                        <SelectBox
                            className="form-control"
                            OptionValue={DobMonth}
                            name="lstDobMonth"
                            myRef={validation({
                                required: "Please Select Month"
                            })}
                            validationMsg={validationMsg.lstDobMonth && validationMsg.lstDobMonth.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                        <SelectBox
                            className="form-control"
                            OptionValue={DobYear}
                            name="lstDobYear"
                            myRef={validation({
                                required: "Please Select Year"
                            })}
                            validationMsg={validationMsg.lstDobYear && validationMsg.lstDobYear.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                    </div>
                </div>
                <span id="dob_final_err" className="error_msg"></span>
            </div>
        </fieldset>
    )
}

export { DateOfBirth };