import React, { useState, useReducer, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import TyStyle from '../../../assets/Unsubscribe/css/Unsubscribe.module.scss';
//import AnimateStyle from '../../../assets/Thankyou/css/Animated.module.scss';
import TextField from '../../UI/TextField';
import Button from "../../UI/Button";
import Email from "../../UI/Email";
import Telephone from "../../UI/Telephone";
import QueryStringContext from "../../../Contexts/QueryString";
import { useUnsubscribeLoad } from "../../../Hooks/useUnsubscribeLoad";
//import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import { Api } from "../../../api/Api";


const initialState = {
    fields: {},
    errors: {},
    txtEmailKeyword:'',
    txtPhoneKeyword:'',
    isTxtEmailed: false,
    isTxtPhoned: false,
};

const signatureReducer = (state, action) => {
    switch (action.type) {
        case "validationSucess1": {
            return {
                ...state,
              lookUp: action.payload.lookup,
              txtEmailKeyword: action.payload.txtEmail_keyword,
              addressPosition1: action.payload.address_position1
            };
        }
        case "validationSucess2": {
            return {
              ...state,
              lookUp: action.payload.lookup,
              txtPhoneKeyword: action.payload.txtPhone_keyword,
              addressPosition2: action.payload.address_position2
            };
        }
        case "emailEnterd": {
            return {
              ...state,
              isTxtEmailed: action.payload.value,
            };
        }
        case "phoneEnterd": {
            return {
              ...state,
              isTxtPhoned: action.payload.value,
            };
        }
    }
}

const Unsubscribe = () =>{
    
    const [state, dispatch] = useReducer(signatureReducer, initialState);
    const { queryString } = useContext(QueryStringContext);
    const { loadUnsubscribe, isuseUnsubscribeLoad } = useUnsubscribeLoad();
    //const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();

    const [txtEmailLoader, setTxtEmailLoader] = useState(false);
    const [txtPhoneLoader, setTxtPhoneLoader] = useState(false);
    
    const [enterdEmail, setEnterdEmail] = useState(false);
    const [enterdPhone, setEnterdPhone] = useState(false);
    const [allFollowupData, setFollowupData] = useState({});
    const [showUserName, setUserName] = useState("");
    const [userUuid, setUserUuid] = useState("");
    const [showLoad, setshowLoad] = useState("show");

    useEffect(() => {
        (async () => {
          const response = await Api.get("/api/v1/unsubscribe/get-pending-details?query_string=" + queryString, {});
          const followupDetails = response.data.response.followup_data;
          setUserName(response.data.response.user_name);
          setUserUuid(response.data.response.user_uuid);
          setFollowupData({
            flpData: response.data.response,
          });
            console.log(response.data.status);

            if (response.data.status === "Success") {
                //Load API
                if (response.data.response.pending_details.email_unsubscribe === 1 && 
                response.data.response.pending_details.sms_unsubscribe === 1) {
                    setshowLoad("hide");
                }
            }
        })();
    },[]);

    const checkValidation = async (prvAdrPos,e) => {
        e.preventDefault();
        
        const addressKeyword = e.target.value;
        //console.log("addressKeyword:-"+addressKeyword);
        if (addressKeyword) {
            if(prvAdrPos=='email') {
                setTxtEmailLoader(true)
            }
            else if(prvAdrPos=='mobile')
            {
                setTxtPhoneLoader(true)
            }

            if(prvAdrPos=='email') {            
                dispatch({
                    type: "validationSucess1",
                    payload: {
                        lookup: false,
                        address_position1:prvAdrPos,
                        txtEmail_keyword: e.target.value,
                    },
                });
                if(e.target.value!=="") {
                    setEnterdEmail(false);
                }
            }  else if(prvAdrPos=='mobile') {
                dispatch({
                    type: "validationSucess2",
                    payload: {
                        lookup: false,
                        address_position2:prvAdrPos,
                        txtPhone_keyword: e.target.value,
                        
                    },
                });
                if(e.target.value!=="") {
                    setEnterdPhone(false);
                }
            }
        }
    };

    const handleClick = async () => {
        const txtEmail = state.txtEmailKeyword;
        const txtPhone = state.txtPhoneKeyword;
        
        if(txtEmail!=="") {
            if (await emailValidCheck() === 0) {
                setEnterdEmail(true);
                dispatch({ type: "emailEnterd", payload: { value: true } });
                return false;
            } else {
                dispatch({ type: "emailEnterd", payload: { value: false } });
            }
        }

        if(txtPhone!=="") {
            if (await phoneValidCheck() === 0) {
                setEnterdPhone(true);
                dispatch({ type: "phoneEnterd", payload: { value: true } });
                return false;
            } else {
                dispatch({ type: "phoneEnterd", payload: { value: false } });
            }
        }
        
        let formdata = {
            "txtEmail" : txtEmail,
            "txtPhone" : txtPhone
          }
        const flpResponse = loadUnsubscribe(
            "unsubscribe",
            formdata,
            allFollowupData.flpData.followup_data,
            queryString
        );
        if(txtEmail!=="" || txtPhone!=="") {
            setshowLoad("hide");
        }
    }

    const emailValidCheck = async () => {
        let txtEmail = state.txtEmailKeyword;
        let pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        if (!pattern.test(txtEmail)) {
            return 0;
        }
        return 1;
    }

    const phoneValidCheck = async () => {
        let txtPhone = state.txtPhoneKeyword;
        let pattern = new RegExp(/^[0-9\d]+$/);
        if (!pattern.test(txtPhone)) {
            return 0;
        } else {
            return 1;
        }
    }

    return (
        <>
            <section className={TyStyle['unsubscribe-wrapper']}>
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8 col-md-12 col-12 text-center">
                            <div className={TyStyle['midbox']}>
                                <img src="assets/Unsubscribe/img/icon-1.png" alt=""/>
                                <div className={TyStyle[showLoad]}>
                                    <h1>Opt-Out Preferences</h1>
                                    <h6>You can choose to opt out of any of the following types of communications we send</h6>
                                    <form method="post"  name="userRegistrationForm" >
                                    <div className={`offset-lg-1 col-lg-10 col-md-12 col-12 ${TyStyle["buttonsec"]}`}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                                                <p className={TyStyle['p1']}>Unsubscribe from Email</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                                <Email
                                                    name="txtEmail"
                                                    className="form-control"
                                                    placeholder="Email Address"
                                                    value={state.txtEmailKeyword}
                                                    onBlur={(e) => checkValidation('email', e)}
                                                    onChange={() => {
                                                        dispatch({
                                                          type: "validationSucess1",
                                                          payload: {
                                                            lookup: true,
                                                            get_address3: [],
                                                          },
                                                        });
                                                      }}
                                                    ></Email>
                                            </div>
                                            {state.isTxtEmailed && (
                                                <div className="col-12 text-center">
                                                    <span className="error_msg">
                                                        Please Enter Valid Email Address
                                                    </span>
                                                </div>
                                            )}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                                                <p className={TyStyle['p1']}>Unsubscribe from SMS</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                                                <Telephone
                                                    name="txtPhone"
                                                    className={`form-control nme_inpu`}
                                                    placeholder="Mobile"
                                                    value={state.txtPhoneKeyword}
                                                    onBlur={(e) => checkValidation('mobile', e)}
                                                    onChange={() => {
                                                        dispatch({
                                                          type: "validationSucess2",
                                                          payload: {
                                                            lookup: true,
                                                            get_address3: [],
                                                          },
                                                        });
                                                      }}
                                                    ></Telephone>
                                            </div>
                                            {state.isTxtPhoned && (
                                                <div className="col-12 text-center">
                                                    <span className="error_msg">
                                                    Please Enter Valid Phone Number
                                                    </span>
                                                </div>
                                            )}
                                            <div className="col-lg-12 col-12">
                                                <p>
                                                    
                                                    <Button
                                                        type="button"
                                                        className={`btn ${TyStyle["btn"]}`}
                                                        id="save"
                                                        onClick={handleClick}
                                                        buttonText="Unsubscribe"
                                                    />
                                                    {/*   
                                                    <Button
                                                        type="submit"
                                                        className={`btn ${TyStyle["btn"]}`}
                                                        id="save"
                                                        buttonText="Unsubscribe"
                                                    />
                                                    
                                                    <input type="submit" />*/} 
                                                </p>
                                            </div>
                                        </div>
                                        <p>Please note that you cannot opt-out of transactional messages if your claim has been already submitted.</p>
                                    </div>
                                </form>
                                </div>
                                <div style={showLoad === "hide" ? { display: "block" } : { display: "none "}}>
                                    <h3>Unsubscribed successfully</h3>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Unsubscribe;
