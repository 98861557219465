
import {Api} from "../api/Api";

export const useFollowupLoad = () => {

    const loadFollowup = async (message_type,followup_data,page_name,query_string,type) => {
        const FollowupLoad = await Api.post("/api/v1/data-ingestion-pipeline", {
            message_type,
            followup_data,
            page_name,
            query_string,
            type
            
        });
      
        return FollowupLoad;
    }
    return { 
     
        loadFollowup
    }
}