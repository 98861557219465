import React, { useContext, useEffect } from 'react';
import {AppDomain} from "../Constants/EnvConstants";
import queryString from "./QueryString";
import { UUID } from "./UUID";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { useUserAgent } from "../Hooks/useUserAgent";

const GetVisitorsParams = () => {
  const history = useHistory();
  const referrer = document.referrer;
  const query = new URLSearchParams(window.location.search);
  const { getUserAgent } = useUserAgent();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const field = 'uuid';
  var uuid = "";
  var queryString = "";
  if (query_string_arr != null) {
    queryString = query_string_arr[1];
  }
  useEffect(async () => {
    if ( visitorParameters.data === undefined || visitorParameters.data.length === 0 || visitorParameters.data === null) {
        const query_uuid = query.get("uuid");
        const local_storage_uuid = localStorage.getItem('uuid');
        var uuid = (query_uuid)? query_uuid : local_storage_uuid;
        const response = await getUserAgent(
          window.navigator.userAgent,
          "LP",
          queryString,
          AppDomain,
          window.location.href,
          referrer
        );
        visitorParameters.data = response.data.response.data;
        visitorParameters.visitor_parameters = { uuid: uuid };
    }
  }, [])
  return (
    <></>
  )
}

export default GetVisitorsParams;