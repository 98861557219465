
import {useState} from 'react'; 
import {Api} from "../api/Api";
//import {Api,ApiFile} from "../api/Api";

export const useUserDocumentFlp = () => {
    const [isLoading,setIsLoading] = useState(false);

    const saveUserDocument = async (message_type, user_document_data, followup_data) => {
        setIsLoading(true);
        const userDocumentSubmit = await Api.post("api/v1/followup/user_documents", {
            message_type,
            user_document_data,
            followup_data
        });
        
        setIsLoading(false);
        return userDocumentSubmit;
    }

    // const saveUserDocument = async (data) => {
    //     setIsLoading(true);
    //     const userDocumentSubmit = await ApiFile.post("api/v1/user_documents",data);
        
    //     setIsLoading(false);
    //     return userDocumentSubmit;
    // }

    return { 
        isLoading,
        saveUserDocument
    }
}

export const useSkipUserDocumentFlp = () => {
    const [isSkipLoading,setIsSkipLoading] = useState(false);
    
    const skipUserDocument = async (skiped_user_document_data,visitor_parameters,query_string,message_type='skip_user_docs') => {
        setIsSkipLoading(true);
        const userDocumentSkip = await Api.post("api/v1/skip_user_documents", {
            message_type,
            skiped_user_document_data,
            visitor_parameters:visitor_parameters.visitor_parameters,
            data:visitor_parameters.data,
            query_string:query_string
        });
        
        setIsSkipLoading(false);
        return userDocumentSkip;
    }
    
    return { 
        isSkipLoading, 
        skipUserDocument
    }
}