import React, { useRef, useState, useEffect, useReducer } from "react";
import SignatureCanvas from "react-signature-canvas";
import { BrowserView, MobileView, IOSView , isBrowser, isMobile, isIPad13 } from 'react-device-detect';
import Button from "../../UI/Button";
import { signFlpCheckText } from "../../../Constants/Constants";
import CheckBox from "../../UI/CheckBox";
import { useFollowupSignature } from "../../../Hooks/useFollowupSignature";

const initialState = {
    isChecked: false,
    isAgreed: false,
    isCanvasEmpty: false,
    isValid: false,
}
const signatureReducer = (state, action) => {
    switch (action.type) {
        case 'signPadChange': {
            return {
                ...state,
                isCanvasEmpty: action.payload.value,
            }
        }
        case 'privacyAgreed': {
            return {
                ...state,
                isAgreed: action.payload.value,
            }
        }
        case 'validSignature': {
            return {
                ...state,
                isValid: action.payload.value,
            }
        }
        default:
            return state;
    }
}
const SignatureFlp = ({ FVStyle, carClassName, onModalclose, allFollowupData, setPendingStatus, showUserName, setLoader, hideLoader, setPopupLoader, hidePopupLoader}) => {
    const [state, dispatch] = useReducer(signatureReducer, initialState);
    const { saveFollowupSignature, isLoading } = useFollowupSignature();
    const [isChecked, setIsChecked] = useState(false);
    const signPadRef = useRef();
    const [isSignValid, setIsSignValid] = useState(false);

    useEffect(() => {
        if (carClassName === "modal show") {
            document.body.classList.add("modal-open");
            let signature = document.getElementById("signature");
            
            window.onclick = function (event) {
                if (event.target == signature) {
                    onModalclose()
                }
            };
        }
    }, [carClassName]);

    const handleClick = async () => {
        try {
            const signPad = signPadRef.current;
            if (signPad.isEmpty()) {
                return dispatch({ type: 'signPadChange', payload: { value: true } });
            } else {
                setIsSignValid(false);
                const rpdata = signPad.toData();
                let signLength = 0;
                for (var i = 0; i < rpdata.length; i++) {
                    signLength += Object.keys(rpdata[i]).length;
                }
                
                if (signLength > 13) {
                    if (!isChecked) {
                        return dispatch({ type: 'privacyAgreed', payload: { value: true } });
                    } else {
                        setPopupLoader(true);
                         
                        let width = signPad.getCanvas().width;
                        let height = signPad.getCanvas().height;
                        let canvasParameters = {'width':width, 'height':height};

                        const response = await saveFollowupSignature(
                            "followup_signature",
                            signPad.getCanvas().toDataURL("image/png"),
                            allFollowupData.flpData.followup_data,
                            canvasParameters,
                        )
                        
                        // localStorage.clear();
                        if (response.data.status === "Success") {
                            onModalclose()
                            setPendingStatus({ status_btn: 'signature', status_flag: 'showTick' });
                            hidePopupLoader();
                        } else {
                            onModalclose()
                            setPendingStatus({ status_btn: 'signature', status_flag: 'showButton' });
                            hidePopupLoader();
                        }
                    }
                } else {
                    setIsSignValid(true);  
                }
            }
        } catch (e) {
            console.warn(e);
        }
    }

    useEffect(() => {
        if (isChecked) {
            dispatch({ type: "privacyAgreed", payload: { value: false } });
        }
    }, [isChecked]);
    
    const clearSign = () => {
        if (signPadRef.current) signPadRef.current.clear();
        setIsSignValid(false);
    }
    //let signclassBrowser = "anim_bt d-lg-block"+ FVStyle['signature-pad'];
    let signclassBrowser = FVStyle['signature-pad'] + " anim_bt "+ FVStyle['d-lg-block'] ;
    let checkboxlabel = 'By signing and pressing “Submit” below, you confirm you have read, understood, and agree to Reclaimer Australia Pty Ltd’s <a href="/assets/Terms_and_Conditions_v1.2-Reclaimer.pdf" target="_blank">Terms and Conditions</a> and <a href="/assets/Reclaimer_LOE_v1.1.pdf" target="_blank">Letter of Engagement</a>. Reclaimer will apply your electronic signature to those documents and the <a style={{ cursor: "pointer" color: "#00aab1 !important" }} href="/assets/Reclaimer_LOA_v1.1.pdf" target="_blank">Letter of Authority</a> to confirm your acceptance. Reclaimer will send you a copy of the <a href="/assets/Terms_and_Conditions_v1.2-Reclaimer.pdf" target="_blank">Terms and Conditions</a> and your signed documents via email.';
  return (
    <>
        
        <div
            className={`${carClassName} ${FVStyle['followup-sign']}`}
            id="signature"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="signaturepart"
            aria-hidden="true"
            style={
                carClassName === "modal show"
                    ? {display: "block" }
                    : { display: "none" }
            }
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className={`modal-content ${FVStyle['signmodal']}`}>
                    <div className={` ${FVStyle['modal-body']} `}>
                        <div className={`${FVStyle['signature-part']}`}>
                            <div className={`${FVStyle['signform']}`}>
                                <div className={`${FVStyle['textheader']}`}>
                                    <h2>Hi, {showUserName}</h2>
                                    <p> We need your electronic signature below to proceed. Please note that your electronic signature will
                                    be included on all forms we submit to your lender</p>
                                </div>

                                <div className={`${FVStyle['ensure_li']}`}>
                                    <div className={`col-lg-12 col-12 nopadmob`}>
                                        <ul className={`${FVStyle['signature-ul']} mt-2`}>
                                            <li> Sign using your finger, mouse or stylus</li>
                                            <li> Keep it fully contained within the box</li>
                                            <li> Ensure it is a true likeness of your signature</li>
                                        </ul>
                                    </div>
                                     
                                    <div className={`${FVStyle['signature_canvaseWrap']}`}>
                                        <div className="bglogo"></div>
                                        <div className="">
                                            {(isIPad13)
                                                ? 
                                                    <IOSView>
                                                        <SignatureCanvas
                                                            canvasProps={{
                                                                width: 420,
                                                                height: 238,
                                                                className: `${FVStyle["signature-pad"]} anim_bt d-lg-block`,
                                                            }}
                                                            ref={signPadRef}
                                                            onEnd={() => {
                                                                if (!signPadRef.current.isEmpty()) {
                                                                    dispatch({ type: 'signPadChange', payload: { value: false } });
                                                                    dispatch({ type: 'validSignature', payload: { value: false } });
                                                                }
                                                            }}
                                                        />
                                                    </IOSView>
                                                :
                                                (isBrowser)
                                                    ?
                                                    <BrowserView>
                                                        <SignatureCanvas
                                                        canvasProps={{
                                                            width: 690,
                                                            height: 240,
                                                            className: `${FVStyle["signature-pad"]} anim_bt d-lg-block`,
                                                        }}
                                                        ref={signPadRef}
                                                        onEnd={() => {
                                                            if (!signPadRef.current.isEmpty()) {
                                                            dispatch({ type: 'signPadChange', payload: { value: false } });
                                                            dispatch({ type: 'validSignature', payload: { value: false } });
                                                            }
                                                        }}
                                                        />
                                                    </BrowserView>
                                                    :
                                                    <MobileView>
                                                        <SignatureCanvas
                                                            canvasProps={{
                                                            width: 300,
                                                            height: 200,
                                                            className: `${FVStyle["signature-pad"]} anim_bt d-lg-block`,
                                                            }}
                                                            ref={signPadRef}
                                                            onEnd={() => {
                                                                if (!signPadRef.current.isEmpty()) {
                                                                    dispatch({ type: 'signPadChange', payload: { value: false } });
                                                                    dispatch({ type: 'validSignature', payload: { value: false } });
                                                                }
                                                            }}
                                                        />
                                                    </MobileView>
                                            }
                                        </div>
                                        <div className="c">
                                           <span className="text-danger" id="signatures_required" style={ {display:"none"} }> Signatures are required!</span>
                                        </div>
                                        {state.isCanvasEmpty && (
                                            <div className="c">
                                                 <span className={`${FVStyle['error_msg']} ${FVStyle['signature_error_msg']}`} id="signatures_required"> 
                                                    Signatures are required!
                                                </span>
                                            </div>
                                        )}
                                        {isSignValid && (
                                            <div className="c">
                                                <span className={`${FVStyle['error_msg']}`} id="signatures_required"> 
                                                Draw valid signature!
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                
                                <div className={`text-left ${FVStyle['bord']}`}>
                                    <div className="custom-control custom-checkboxd">
                                        <CheckBox
                                            dataId="acceptterms"
                                            anchorText="Terms of Business"
                                            anchorStyle=""
                                            href=""
                                            onChange={(e) => setIsChecked(!isChecked)}
                                            className="custom-control-input"
                                            labelClassName="custom-control-labela"
                                            value={isChecked}
                                            CheckBoxText={checkboxlabel}
                                        />
                                        {/* 
                                        <label 
                                        className="custom-control-label" >
                                        By signing and by pressing “Submit” below, you agree to Reclaimer's &nbsp;
                                        <a href="/assets/Terms_and_Conditions_v1.0-Reclaimer.pdf" role="button" tabIndex="0" target="_blank" >Terms and Conditions</a> &nbsp;and that we may use your signature to submit the claim.    
                                        </label>
                                        */}
                                    </div>
                                    {state.isAgreed && (
                                        <span className="error_msg">
                                            Please agree to Reclaimer’s Terms and Conditions
                                        </span>
                                    )}
                                </div>
                            </div>
                        
                            <div className={`${FVStyle['signfooter']}`} style={{ display: "block" }}>
                                <Button
                                    type="button"
                                    className={`${FVStyle['btn-clear']}`}
                                    id="clear"
                                    onClick={clearSign}
                                    buttonText="Clear">
                                </Button>
                                <Button
                                    type="button"
                                    className={`${FVStyle['btn-submit']}`}
                                    id="clear"
                                    onClick={handleClick}
                                    buttonText="Submit">
                                </Button>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};
export default SignatureFlp;
