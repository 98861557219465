import { useEffect } from "react";
import "./FrankieModal.css";
// import $ from "jquery";
const FrankieSmartUI = ({ className,show, onClose }) => {
  useEffect(() => {
    // $('#ff-onboarding-widget').addClass('testhello')
  });
  
  const showHideClassName = show ? "modal display-block" : "modal display-none";
   return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button type="button" onClick={onClose} className={`${className} btn_close`}>
          Close
        </button>
        <ff-onboarding-widget />
      </section>
    </div>
  );
};

export default FrankieSmartUI;
