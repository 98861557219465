import React from "react";

const SlideQuestion2 = ({
  Sass,
  slideChange,
  className,
  flpStatus
}) => {

  const formParameters = JSON.parse(localStorage.getItem("formData"));
  let userInfo = localStorage.getItem("user_info");
  if(!userInfo && formParameters ) {
    userInfo = formParameters.txtFName+' '+formParameters.txtLName;
  }
  
  const nextSlide = async (e) => {
    slideChange(e);
  };
  console.log("flpStatus:"+flpStatus)
  return (
    <>
      <section id="question2" className={`${Sass['content-part']} ${Sass['animated']} ${Sass['animatedFadeInUp']} ${Sass['fadeInUp']} ${className}`}>
        <div className="container-fluid">
          <div className="row">
            
            <div className="col-xl-6 col-lg-6 col-md-12 col-12 text-center ">
              <div className={Sass['video_div']}>
                <video autoPlay muted playsInline loop tabIndex="0">
                  <source src="assets/UserVerificationV1/video/video-intro.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={`row align-items-center ${Sass['text-div']}`}>
                <img src="assets/UserVerificationV1/img/logo.png" className={Sass['logo']} alt="" />
                {flpStatus && flpStatus=='FLP' && (
                    <h3>Before a financial institution can process our claim for a refund on Junk Insurance, they are required by law to establish the legitamacy of a customer's identity so they can identify risk factors.</h3>
                )}
                {flpStatus && flpStatus=='Live' && (
                  <h3>{userInfo}, before a financial institution can process our claim on Junk Insurance, they are required by law to establish the legitimacy of your identity to identify risk factors.</h3>  
                                      
                )}  
                {flpStatus && flpStatus=='FLP' && (
                  <div className=" col-md-12 col-12 align-middle d-block d-lg-none"> 
                    <a className={`${Sass['btn-home']} btn2`} name="btn2" onClick={nextSlide}>LEARN MORE</a> 
                  </div>
                )} 
                {flpStatus && flpStatus=='Live' && (
                  <div className=" col-md-12 col-12 align-middle d-block d-lg-none"> 
                      <a className={`${Sass['btn-home']} btn2`} name="btn2" onClick={nextSlide}>COMPLETE REMOTE REFUND {'>>'}</a> 
                  </div>
                )}
              </div>
            </div>
            <div className={`col-xl-6 col-lg-6 col-md-12 col-12 text-center ${Sass['white_bg']} ${Sass['my-auto']} d-none d-lg-block`}>
              {flpStatus && flpStatus=='FLP' && (
                <span className="align-middle">
                  <a className={`${Sass['btn-home']} btn2`} name="btn2" onClick={nextSlide}>LEARN MORE</a>
                </span>
              )}
              {flpStatus && flpStatus=='Live' && (
                <span className="align-middle">
                  <a className={`${Sass['btn-home']} btn2`} name="btn2" onClick={nextSlide}>COMPLETE REMOTE REFUND {'>>'}</a>
                </span>
              )}  
            </div>              
          </div>
        </div>
      </section>
    </>
  );
};
export default SlideQuestion2;
