import React, { useEffect } from 'react';
import { useUserAgent } from "../../Hooks/useUserAgent";
import { useLocation } from "react-router-dom";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useYahooGeminiRedirection } from "../../Hooks/useYahooGeminiRedirection";
import { useHistory } from "react-router-dom";

const YahooGemini = () => {
  const { getUserAgent } = useUserAgent();
  const { getYahooGeminiRedirectionUrl } = useYahooGeminiRedirection();
  const queryString = useLocation().search;
  const uuids = '';
  console.log(queryString);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await getUserAgent(
        window.navigator.userAgent,
        "AP",
        queryString,
        EnvConstants.AppDomain,
        window.location.href,
        uuids
      );
      const visitorData = response.data.response.visitor_parameters;
      const DataParams = response.data.response.data;
      const uuid = visitorData.uuid;
      const device = visitorData.device_type;
      const userAgent = DataParams.user_agent;
      const ipAddress = visitorData.ip_address;

      const ygResponse = await getYahooGeminiRedirectionUrl(
        device,
        userAgent,
        ipAddress,
        queryString
      );
      const YahooGeminiRedirectionUrl = ygResponse.data.redirect_url;
      if (ygResponse.data.status == 'Success') {
        window.location.href = YahooGeminiRedirectionUrl;
      }
      else {
        const redirectURL = EnvConstants.AppYahooGeminiDefaultUrl;
        window.location.href = redirectURL + '?uuid=' + uuid;
      }
    })();
  }, []);

  return (
    <>

    </>
  )
}

export default YahooGemini;
