export const SK_V1Content = {
    header: {
        head: "",
        subHead: ""
    },
    faq: [
        {
            question: "What is Consumer Credit Insurance or ‘CCI’?",
            content: "Consumer Credit Insurance (CCI) is an insurance product that is sold alongside credit agreements. It isn’t just loans and credit cards, but also agreements such as credit cards, mortgages / home loans, personal loans and car finance. It’s supposed to help the customer keep up payments on their credit agreements if they became sick or unemployed.",
        },
        {
            question: "How do I know if I was mis-sold CCI?",
            content: "<p>We believe a significant proportion of CCI was mis-sold.</p><p>CCI was very expensive and many of the things you may have expected it to cover simply weren’t covered. It generally didn’t even offer good value for money, but typically you weren’t told this.</p><p>Where CCI was sold, a recent <a href='https://asic.gov.au/media/5201456/rep622-published-11-july-2019-1.pdf' target='_blank'>ASIC report</a> suggests it was commonly mis-sold. Plain and simple!</p>",
        },
        {
            question: "Why does Reclaimer exist?",
            content: "Reclaimer was born to help people like you claim back money that your bank wrongly charged you for, or mis-sold to you as part of a finance agreement.",
        },
        {
            question: "What will Reclaimer do for me?",
            content: "We pursue claims directly with banks and financial institutions and help you to claim back what is rightfully yours.",
        },
        {
            question: "How will Reclaimer do this for me?",
            content: "<p>When it comes to providing us with information, we like to keep our process straightforward. In fact, all we need from you to start the process is some basic information. We don’t require account numbers, just the name of the lenders (the banks/financial institutions you took the original products out with.</p><p>If you have taken out any type of finance agreement e.g. credit card, personal loan, home loan and/or car loan between the late 1990’s and 2019 and you know the identity of the lender, include this information in the form. You can start your claim HERE.</p><p>Once we receive your information, we will approach the lenders to establish if CCI was attached to your credit agreements (with or without your knowledge).</p><p>Once we have established if any of your credit agreements had CCI attached and you would like us to pursue this claim on your behalf, then we will ask for additional information regarding your personal circumstances at the time you were sold CCI.</p><p>This allows us to assess your claim and in appropriate cases develop a strong legal argument on your behalf to fight your claim.</p>",
        },
        {
            question: "I don’t think I’m entitled to claim back my CCI.",
            content: "<p>You’re not alone in thinking that but you should check anyway. Many people weren’t even told CCI was being added to their credit agreements. In other words, in many cases, it was sold without the customer’s knowledge.</p><p>What Reclaimer will do is:</p><ul><li> Look into whether or not you were sold CCI; and</li><li> Establish whether or not you have a basis of claim.</li></ul>",
        },
        {
            question: "Can I make a claim even if my CCI policy has been closed for some time?",
            content: "Yes. <br />In many cases, we can make a claim irrespective of whether your account is still open. However the sooner you act, the better. </div>",
        },
        {
            question: "Will I be charged if my claim fails?",
            content: "No.",
        },
        {
            question: "How much will I be charged if my claim is successful?",
            content: "<p>We charge a success fee for providing the claim service. As long as you don’t cancel or end your agreement with us, the success fee is the only fee we will charge you for the claim service.</p><p>The success fee is 30% (plus GST) of any compensation: this is equivalent to $330 for every $1,000 of compensation.</p>",
        },
        {
            question: "How long will it take?",
            content: "<p>Based on experience in the United Kingdom, we expect typical claims may take around 5 months for a settlement and that some can be resolved in around 8-12 weeks. </p><p>During this time we’ll do all the work for you using our lawyers and dealing directly with the bank on your behalf.</p>",
        },
        {
            question: "What if a creditor/financial firm contacts me regarding my complaint?",
            content: " We are your representatives. <br /> We recommend you do not respond yourself as the banks may use your inexperience in dealing with matters of this type to your detriment without you even realising it. <br />If your lenders contact you, you can tell them that Reclaimer is your representative in this matter. Simply refer them to us, and then let us know by email at support@reclaimer.com.au",
        },
    ]
}