import React, { useContext, useEffect } from 'react';
import { useAdtopiaLoadApi } from '../Hooks/useAdtopiaLoadApi';
import { AppDomainWithoutProtocol, AppDomain, AppApDefaultUrl, AppLpRedirectDefaultUrl, AppAdtopiaUPID, AppReferrerURL } from "../Constants/EnvConstants";
import { UUID } from "./UUID";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../Contexts/VisitorParams";
import RedirectContext from "../Contexts/RedirectContext";
import { useUserAgent } from "../Hooks/useUserAgent";
import { useDataIngestion } from "../Hooks/useDataIngestion";
import { CheckRedirectUrl } from "../Utility/CheckRedirectUrl";

const AdtopiaLoadAP = ({ splitName }) => {
  const history = useHistory();
  const referrer = document.referrer;
  const { getUUID, setParams } = UUID();
  const { adtopiaLoadResponse, adtopiaLPResponse } = useAdtopiaLoadApi();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { redirectUrl } = useContext(RedirectContext);
  const { getUserAgent } = useUserAgent();
  const { saveDataIngestion } = useDataIngestion();
  const urlParams = new URLSearchParams(window.location.search);
  const encryptVal = urlParams.get('value');
  const field = 'uuid';
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  var queryString = "";
  var uuid = "";
  var url = new URL(full_url);
  const { isLpUrlExist } = CheckRedirectUrl();
  var redirectURL = "";
  var ip_address = "";
  if (query_string_arr != null) {
    queryString = query_string_arr[1];
  }
  if (url.searchParams.get("lp_url") != null) {
    redirectUrl.redirectUrl = urlParams.get("lp_url");
  }
   useEffect(async () => {
    let uuidType = 'new';
    if (encryptVal) {
      uuidType = 'new';
    } else {
      uuidType = 'check';
    }
    if (urlParams.get('uuid')) {
      uuid = urlParams.get('uuid');
      ip_address = localStorage.getItem('ip_address');
    } else {
      const getUUIDParam = await getUUID(
        splitName,
        uuidType
      );
      uuid = getUUIDParam.data.response.uuid;
      ip_address = getUUIDParam.data.response.ip_address;
      localStorage.setItem('ip_address', ip_address);
    }
    if (encryptVal) {
      const getdtopiaApi = await adtopiaLoadResponse(
        AppDomain + '?' + queryString,
        AppDomainWithoutProtocol,
        window.navigator.userAgent,
        ip_address,
        uuid,
        AppAdtopiaUPID,
        AppReferrerURL
      );
      if (typeof getdtopiaApi !== "undefined") {
        const getdtopiaApiResponse = getdtopiaApi.data;
        if (getdtopiaApiResponse.lp_url) {
          redirectURL = getdtopiaApiResponse.lp_url;
          localStorage.setItem('redirectURL', redirectURL);
        } else {
          redirectURL = await isLpUrlExist();
        }

        redirectUrl.redirectUrl = redirectURL;
        if (getdtopiaApiResponse.response === 200) {
          if (getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol) {
            if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
              history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param /*+ '&lp_url=' + redirectURL*/);
            } else {
              history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param /*+ '&uuid=' + uuid + '&lp_url=' + redirectURL*/);
            }
          } else { 
            window.location.href = getdtopiaApiResponse.url + '&uuid=' + uuid /*+ '&lp_url=' + redirectURL*/;
          }
        } else if (getdtopiaApiResponse.response === 404) {
          if (getdtopiaApiResponse.url_param != "") {
            if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
              history.push('/' + AppApDefaultUrl + '?' + getdtopiaApiResponse.url_param /*+ '&lp_url=' + redirectURL*/);
            } else {
              history.push('/' + AppApDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
            }
          } else {
            history.push('/' + AppApDefaultUrl + '?uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
          }
        }
      } else {
        redirectURL = AppLpRedirectDefaultUrl;
        history.push('/' + AppApDefaultUrl + '?uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
      }
    } else {
      if (url.searchParams.get("lp_url") == null) {
        redirectURL = await isLpUrlExist();
      } else {
        redirectURL = url.searchParams.get("lp_url");
      }
     
      redirectUrl.redirectUrl = redirectURL;
      const response = await getUserAgent(
        window.navigator.userAgent,
        "AP",
        queryString,
        AppDomain,
        window.location.href,
        referrer
      );
      // const visitorData = response.data.response.visitor_parameters;
      visitorParameters.data = response.data.response.data;
      // visitorParameters.visitor_parameters = visitorData
      visitorParameters.visitor_parameters = { uuid: uuid };
      // visitorParameters.visitor_parameters.uuid = uuid;
      visitorParameters.date = new Date();
      visitorParameters.pageName = splitName;
      (async () => {
        setParams(uuid, splitName, ip_address)
        await saveDataIngestion(
          visitorParameters.visitor_parameters,
          visitorParameters.data,
          'adv_page_load',
          splitName,
        )
      })();
      if (queryString != null) {
        if (queryString.indexOf('&' + field + '=') != -1 ||
          queryString.indexOf('?' + field + '=') != -1 ||
          queryString.indexOf(field + '=') != -1) {
          window.history.pushState({}, '', '?' + queryString + '&adv_page=' + splitName);
        } else {
          window.history.pushState({}, '', '?' + queryString + '&uuid=' + uuid /*+ '&lp_url=' + redirectURL*/ + '&adv_page=' + splitName);
        }
      } else {
        window.history.pushState({}, '', '?uuid=' + uuid /*+ '&lp_url=' + redirectURL*/ + '&adv_page=' + splitName);
      }
    }
    localStorage.setItem('redirectURL', redirectURL);

  }, [encryptVal])
  return (
    <></>
  )
}

export default AdtopiaLoadAP;