import {useState} from 'react';
//import {Api} from '../api/Api';
import {lenders} from "../Constants/Lenders";

export const useBankList = () => {
    const [isBankListLoading, setIsBankListLoading] = useState(false);
    const bankList = async () => {
        //setIsBankListLoading(true);
        //const response = await Api.get("api/v1/banks");
        //setIsBankListLoading(false);
        //return response;
        return lenders;
    }
    return {bankList,isBankListLoading}
}