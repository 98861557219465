import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

const PrivacyPopup = (props) => {

   return (
      <Fragment>
         <Modal {...props} size="lg" aria-labelledby="PrivacyTitle" centered >
            <Modal.Header closeButton>
               <Modal.Title><h3 className="modal-title" id="PrivacyTitle">Privacy Policy</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
          <p>
            This Policy represents how Reclaimer Australia Pty Limited (herein
            “Reclaimer”) manages your personal information in accordance with
            the Privacy Act 1988 (Cth) (“the Privacy Act”). Our Privacy Policy
            seeks to safeguard the privacy of your personal information in
            accordance with the Australian Privacy Principles (“the APP’s”)
            contained within the Privacy Act.
          </p>
          <p>
            In addition to this Privacy Policy, we may also have specific and
            additional privacy provisions that apply to certain activities and
            promotions. In the event of any inconsistency between the provisions
            of this Privacy Policy and those other specific and additional
            provisions, the specific and additional provisions provided to you
            will apply.
          </p>
          <p>
            Please note that if at any time Reclaimer is required by law to
            release information about you, Reclaimer must cooperate fully.
          </p>
          <p>
            This Privacy Policy may be amended from time to time and may be
            revised. This Privacy Policy represents the latest version of the
            Reclaimer Privacy Policy.
          </p>

          <h6>1. Your Personal Information</h6>
          <p>
            Reclaimer recognises that your privacy is very important to you and
            that you have a right to control your personal information. We know
            that providing personal information is an act of trust and we take
            our responsibility for your information seriously.
          </p>
          <p>
            Unless you give us explicit consent to act otherwise, the following
            policy will govern how Reclaimer handles your personal information
            and safeguards your privacy. By using Reclaimer’s products and
            services, you consent to Reclaimer using your personal information
            as set out in this Privacy Policy.
          </p>
          <h6>1.1 What Does “Personal Information” Mean?</h6>
          <p>
            Personal information is any information about you that identifies
            you or by which your identity may be reasonably determined. By
            collecting this personal information we are able to:
          </p>
          <ul>
            <li>Contact you when and where necessary;</li>
            <li>
              Identify any refund entitlements that you may have from your bank;
            </li>
            <li>Ensure that you attain the best possible service; and</li>
            <li>
              Identify you and protect you from any unauthorised access to your
              personal information.
            </li>
          </ul>
          <p>
            If you choose not to provide personal information to us, we may not
            be able to provide you with access to parts of the website or to
            certain content, products and services available on or from our
            website.
          </p>
          <h6>1.2 The Kinds of Personal Information We Collect and Hold</h6>
          <p>Personal information which we may collect and hold includes:</p>
          <ol>
            <li>Your name, date of birth, address including email address;</li>
            <li>Your phone number and mobile phone number;</li>
            <li>
              Other personal information necessary for the services we offer to
              you.
            </li>
          </ol>
          <p>
            Reclaimer may record anonymous information which tells us about
            visitors to our website but not the identity of those visitors. For
            example, we may collect information about the date, time and
            duration of those visits and which pages of our website are being
            commonly accessed. We may also use “cookies” that are small pieces
            of information that allow an organisation to track websites browsed
            by you. You may be able to adjust your browser to disable cookies.
            However, if you do disable cookies, you may not be able to interact
            with our website or take advantage of the improved functionality
            that cookies enable.
          </p>
          <h6>1.3 How Reclaimer Collects and Holds Personal Information</h6>
          <p>
            Reclaimer will collect your personal information directly from you
            where possible. In some instances, we will collect your personal
            information from an associate of yours, in which case we will obtain
            your consent.
          </p>
          <p>
            As a general rule, we do not collect sensitive information.
            Sensitive information is a type of personal information and can
            include, but is not limited to, information about an individual’s
            health, opinions and beliefs. However, if we do collect sensitive
            information, it will usually be for the purposes of providing our
            services and we will seek your consent to collect it.
          </p>
          <p>
            In the course of providing the requested services, we may collect
            and hold personal information relating to an associate of yours
            where it is impractical or unreasonable to seek the same information
            directly from the associate. You must take reasonable steps to
            ensure that you are authorised to disclose that information to
            Reclaimer.
          </p>

          <h6>1.4 How Reclaimer Uses Your Personal Information</h6>
          <p>
            We will tell you why we are collecting information when we collect
            it and how we plan to use it or these things will be obvious when we
            collect the information. Personal information is used only for the
            purpose for which it is submitted or for such other secondary
            purposes that are related to that purpose, unless we disclose other
            uses in this Privacy Policy or at the time of collection.
          </p>
          <p>
            Reclaimer collects and holds your personal information in order to
            fulfil a service for you, whether it is the provision of our
            services, providing you with upcoming news from Reclaimer that may
            interest you, or updating you in relation to further developments.
          </p>
          <p>
            We may use personally identifiable information in a summative form
            to improve our services and make them more responsive to the needs
            of our customers. This statistical compilation and analysis of
            information may also be used by us or provided to others as a
            summary report for marketing, advertising or research purposes.
          </p>
          <p>
            We will not sell, transfer, assign or rent your personal
            information. In order to operate the website and to deliver to you
            the service which you have requested, we may sometimes share your
            personal information to an affiliate or subsidiary which is
            necessary to deliver your requested items or services to you. We
            will not otherwise disclose your personal information to other
            companies without your consent.
          </p>
          <h6>
            1.5 Who Would Reclaimer Disclose Your Personal Information To?
          </h6>
          <p>
            Reclaimer may need to disclose your personal information in certain
            circumstances. For example, your personal information may be
            required to be disclosed in providing our services, such as
            disclosing your information to statutory authorities and banks in
            the completion of our services to you.
          </p>
          <p>
            We may also disclose your personal information where you have
            consented to us doing so in providing you with our services or where
            disclosure is necessary to achieve the purpose for which it was
            submitted.
          </p>
          <p>
            We may need to disclose or transfer your personal information if
            Reclaimer is acquired by or merged with another entity.
          </p>
          <p>
            Reclaimer will, at all times, attempt to ensure that we do not
            disclose your personal information to a third party where consent
            for such disclosure has not been given. In all cases where we may be
            required to disclose information we will attempt to obtain your
            direct consent for such disclosure.
          </p>
          <p>
            Please note that if at any time Reclaimer is required by law to
            release information about you, we must cooperate fully. We may also
            disclose your information in order to investigate, halt or take
            action regarding illegal activities.
          </p>
          <h6>
            1.6 How Can You Access Your Personal Information and/or Seek
            Correction?
          </h6>
          <p>
            You may access and/or request that your personal information be
            edited or corrected by contacting the Privacy Officer at Reclaimer:
          </p>
          <p>Email: info@reclaimer.com.au</p>
          <p>By post: TBC</p>
          <p>
            We strive to ensure that all personal information stored is
            accurate, up-to-date and complete.
          </p>
          <h5>2. Your Personal Information and Overseas Disclosure</h5>
          <p>Reclaimer stores your personal information in Australia.</p>
          <p>
            Disclosures of your personal information will not be made overseas
            without your express consent, or in circumstances where we are
            legally required.
          </p>
          <h5>3. Data Quality and Security</h5>
          <p>
            Reclaimer will endeavour to take all reasonable steps to keep secure
            any personal information which we hold about you and to keep this
            information accurate and up to date.
          </p>
          <p>
            However, as the internet is not a secure environment, any
            information that you send to us via that method is sent at your own
            risk.
          </p>
          <p>
            We strive to ensure the security, integrity and privacy of the
            personal information of our clients. We use a variety of physical
            and electronic security measures including restricting physical
            access to our offices and firewalls and secure databases to keep
            personal information secure from misuse, loss, modification,
            unauthorised access or disclosure. In addition, our employees are
            obliged to respect the confidentiality of personal information held
            by Reclaimer.
          </p>
          <h5>4. Direct Marketing</h5>
          <p>
            If you subscribe to receive information from this Site, then your
            e-mail address and other personal information that you provide will
            be recorded. Your email address will only be used for the purpose
            for which you have provided it. Reclaimer may, from time to time,
            use your personal information, such as your address or contact
            details, to provide you with information about other services that
            we offer.
          </p>
          <p>
            If at any time you do not wish to receive any information about
            these services, please feel free to contact us by mail or email.
          </p>
          <h5>5. Breaches of Privacy</h5>
          <p>
            In the unlikely event of unauthorised disclosure of your personal
            information, Reclaimer is bound by the National Data Breach Scheme
            contained within the Privacy Act. If a breach occurs, Reclaimer
            will:-
          </p>
          <ol>
            <li>
              Conduct a reasonable and expeditious assessment of the suspected
              breach within 30 days;
            </li>
            <li>
              If a breach has been determined to have occurred, prepare a
              statement to the Office of the Australian Information Commissioner
              detailing circumstances and subject of the breach as well as
              recommended steps that you can take; and
            </li>
            <li>
              Notify you, and anyone else subject to the breach, of the
              statement made to the Office of the Australian Information
              Commissioner.
            </li>
          </ol>
          <h5>6. Complaints About Breaches of Privacy</h5>
          <p>
            If you are unsatisfied with the manner in which Reclaimer has dealt
            with your personal information and wish to complain, you can direct
            your complaints to the Privacy Officer at Reclaimer at
            info@reclaimer.com.au.
          </p>
          <p>
            Reclaimer will, within seven days, respond to you and attempt to
            resolve with you your issues as they pertain to your personal
            information.
          </p>
          <p>
            If you are still unsatisfied, you may wish to direct your complaint
            to the Office of the Australian Information Commissioner at{" "}
            <a href="http://www.oaic.gov.au/ " target="_blank">
              http://www.oaic.gov.au/
            </a>{" "}
            or alternatively:
          </p>
          <p>By phone: 1300 363 992</p>
          <p>In writing: GPO Box 5218, Sydney NSW 2001</p>
        </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
         </Modal>
      </Fragment>
   );
};

export default PrivacyPopup;
