import React, { Fragment, useEffect, useState, useReducer } from "react";
import { Modal } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import InputButton from "../../UI/InputButton";
import { useBankList } from '../../../Hooks/useBankList';
import CheckBox from '../../UI/CheckBox';
import dataLayer from '../DataLayer';

const initialState = {
  isChecked: false,
};

const lenderReducer = (state, action) => {
  switch (action.type) {
    case "productChange": {
      return {
        ...state,
        isChecked: action.payload.value,
      };
    }
    default:
      return state;
  }
}    
const Lendors = ({
  className,
  setBankSelectedValue,
  setOtherBankSelectedList,
  setLendedTypeArray,
  slideChange,
  Sass,
  bankProducts,
  setBankProducts,
  bankProductQuestions,
  setBankProductQuestions
}) => {
  const [state, dispatch] = useReducer(lenderReducer, initialState);

  const { bankList, isBankListLoading } = useBankList();
  const [lenderList, setLenderList] = useState({});
  const [otherBankSelectedList1, setOtherBankSelectedList1] = useState([]);
  const [bankSelectedValue] = useState([]);
  const [LendedTypeArray] = useState([]);
  const [bankValidationMsg, setBankValidationMsg] = useState();
  const [bankPopupStatus, setBankPopupStatus] = useState(false);
  const [bankNoticeModalShow, setBankNoticeModalShow] = useState(false);
  const [bankSelectionCount, setBankSelectionCount] = useState(0);
  const [accountTypeModalShow, setaccountTypeModalShow] = useState(false);
  const [lenderNames, setlenderName] = useState([]);
  const [lender, setLender] = useState('');

  const [bankProductList, setBankProductList] = useState([]);
  const [bankProductValue,setBankProductValue] = useState([]);

  const [productStatus, setProductStatus] = useState(false);
  //const [isChecked, setIsChecked] = useState(false);
  
  const [questionsAmExModalShow, setQuestionsAmExModalShow] = useState(false);
  const [questionsHsbcModalShow, setQuestionsHsbcModalShow] = useState(false);
  const [questionsVigMoneyModalShow, setQuestionsVigMoneyModalShow] = useState(false);  

  const [bankQuestionValue,setBankQuestionValue] = useState([]);

  const [bankQuestionList,setBankQuestionList] = useState([]);

  const onSelectOtherBank = (selectedList, selectedItem) => {
    // setlenderName({lenderId : selectedItem.id,lenderName : selectedItem.bank_name})
    // //setaccountTypeModalShow(true);
    // setOtherBankSelectedList(selectedList);
    // setOtherBankSelectedList1(selectedList);

    setOtherBankSelectedList(selectedList);
    setOtherBankSelectedList1(selectedList);

    setBankProductValue([]);    
    let bankId =  selectedItem.id;
    let filterProducts = lenderList.banksProducts.filter(el => {
      return el.id == bankId;
    });

    setlenderName({lenderId : selectedItem.id,lenderName : selectedItem.bank_name})
    
    if(filterProducts && filterProducts[0]){
      setBankProductList(filterProducts[0]);
      if(filterProducts[0].product.length>1) {
        setaccountTypeModalShow(true);
      } else if(filterProducts[0].product.length==1) {       
        let lenderId = filterProducts[0].id;
        bankProducts.push({"id":lenderId,"product":JSON.stringify(filterProducts[0].product)});

        // if(bankId==141) {
        //   setQuestionsVigMoneyModalShow(true);
        // }
      }  
    } else {
      setBankProductList({'id':bankId,"product":[]});
    }
  };
  const onRemoveOtherBank = (selectedList, removedItem) => {
    setOtherBankSelectedList(selectedList);
    setOtherBankSelectedList1(selectedList);

    let bankId =  removedItem.id;
    let otherFilterProducts = bankProducts.filter(el => {
      return el.id != bankId;
    });
    setBankProducts(otherFilterProducts);

    // console.log("bankProductQuestions Length:-"+bankProductQuestions.length);
    if (bankProductQuestions.length>0) {
      // console.log("Remove onSelectBank action ");
      // console.log(bankProductQuestions);
      let otherFilterQuesions = bankProductQuestions.filter(el => {
        return el.bid != bankId;
      });
      
      // console.log(otherFilterQuesions);
      // console.log("otherFilterQuesions");
      setBankProductQuestions(otherFilterQuesions);
      // console.log(bankProductQuestions);
    }

  };

  const removeItemsById = (arr, id) => {
    let i = arr.length;
    if (i) {
      while (--i) {
        let cur = arr[i];
        if (cur.bid == id) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const onSelectBank = (e) => {
    // if (e.target.checked === true) {
    //   bankSelectedValue.push({ id: +e.target.value });
    //   setlenderName({lenderId : e.target.value,lenderName : e.target.dataset.value})
    //   //setaccountTypeModalShow(true);
    // } else {
    //   bankSelectedValue.pop({ id: +e.target.value });
    // }
    // setBankSelectedValue(bankSelectedValue);

    // console.log(bankProductQuestions);
    // console.log("---bankProductQuestions---");

    setBankProductValue([]);    
    let bankId = e.target.value;    
    let filterProducts = lenderList.banksProducts.filter(el => {
      return el.id == bankId;
    });
    
    if (e.target.checked === true) {
      bankSelectedValue.push({ id: +e.target.value });
      setlenderName({lenderId : e.target.value,lenderName : e.target.dataset.value})
      
      if(filterProducts && filterProducts[0]){
        setBankProductList(filterProducts[0]);
        if(filterProducts[0].product.length>1) {
          setaccountTypeModalShow(true);
        } else if(filterProducts[0].product.length==1) {          
          let lenderId = filterProducts[0].id;
          bankProducts.push({"id":lenderId,"product":JSON.stringify(filterProducts[0].product)});

          if(e.target.value==2) {
            setQuestionsAmExModalShow(true);
          }
        }
      } else {
        // console.log("------------");
        // console.log(bankQuestionList);
        setBankProductList({'id':e.target.value,"product":[]});
      }
    } else {
      bankSelectedValue.pop({ id: +e.target.value });

      let otherFilterProducts = bankProducts.filter(el => {
        return el.id != bankId;
      });
      setBankProducts(otherFilterProducts);

      // console.log("bankProductQuestions Length:-"+bankProductQuestions.length);
      if (bankProductQuestions.length>0) {
        // console.log("Remove onSelectBank action ");
        // console.log(bankProductQuestions);
        let otherFilterQuesions = bankProductQuestions.filter(el => {
          return el.bid != bankId;
        });
        
        // console.log(otherFilterQuesions);
        // console.log("otherFilterQuesions");
        setBankProductQuestions(otherFilterQuesions);

        // console.log(bankProductQuestions);
        //removeItemsById(bankQuestionList, )
      }  
    }

    //console.log(bankQuestionList);

    setBankSelectedValue(bankSelectedValue);
  };

  const onSelectLegendBank = (e) => {
    // if (e.target.checked === true) {
    //   bankSelectedValue.push({ id: +e.target.value });
    //   setlenderName({lenderId : e.target.value,lenderName : e.target.dataset.value})
    //   //setaccountTypeModalShow(true);
    // } else {
    //   bankSelectedValue.pop({ id: +e.target.value });
    // }
    // setBankSelectedValue(bankSelectedValue);

    setBankProductValue([]);    
    let bankId = e.target.value;    
    const lid = e.target.getAttribute("data-lid");
    let filterProducts = lenderList.banksProducts.filter(el => {
      return el.lid == lid;
    }); 
    
    if (e.target.checked === true) {
      bankSelectedValue.push({ id: +e.target.value });
      setlenderName({lenderId : e.target.value,lenderName : e.target.dataset.value})
      
      if(filterProducts && filterProducts[0]){
        setBankProductList(filterProducts[0]);

        if(filterProducts[0].product.length>1) {
          setaccountTypeModalShow(true);
        } else if(filterProducts[0].product.length==1) {          
          let lenderId = filterProducts[0].id;
          let lenderLid = filterProducts[0].lid;
          bankProducts.push({"id":lenderId, "lid":lenderLid, "product":JSON.stringify(filterProducts[0].product)});
        }
      } else {
        setBankProductList({'id':e.target.value,"product":[]});
      }

    } else {
      bankSelectedValue.pop({ id: +e.target.value });

      let otherFilterProducts = bankProducts.filter(el => {
        return el.lid != lid;
      });
      setBankProducts(otherFilterProducts);
    }
    setBankSelectedValue(bankSelectedValue);
  };

  const onBankProduct = (e) => {
    if(e.target.checked) {
      bankProductValue.push(e.target.value);
    } else {
      bankProductValue.pop(e.target.value);
    }

    if(e.target.checked){
      if(document.querySelector('#productID')!=null) {
        document.querySelector('#productID').style.display='none';
      }
    }
  }

  const addBankProducts = (e) => {
    if(bankProductValue.length>0) {
      let lenderId = bankProductList.id;
      let lenderLid =  bankProductList.lid ? bankProductList.lid : null;
      if(lenderLid) {
        bankProducts.push({"id":lenderId, "lid":lenderLid, "product":JSON.stringify(bankProductValue)});
      } else {
        bankProducts.push({"id":lenderId,"product":JSON.stringify(bankProductValue)});
      }
      setaccountTypeModalShow(false);
      //setProductStatus(false);
      dispatch({ type: "productChange", payload: { value: false } });
      if (lenderId==64) {
        setQuestionsHsbcModalShow(true);
      }
      if(lenderId==141) {
          setQuestionsVigMoneyModalShow(true);
        }
    } else {
      //setProductStatus(true);
      if(document.querySelector('#productID')!=null) {
        document.querySelector('#productID').style.display='block';
      }
      dispatch({ type: "productChange", payload: { value: true } });
    }
    
    /*
    let lenderId = bankProductList.id;
    console.log("lenderId:"+lenderId);
    console.log("bankProductValue:");
    console.log(bankProductValue);

    let filterQuestions = lenderList.banksQuestion.filter(el => {
      return el.id == lenderId;
    });
    
    console.log(lenderList.banksQuestion);
    console.log(filterQuestions);
    console.log(filterQuestions.length);
    if(filterQuestions.length>0){
      console.log("+++++");
      console.log(filterQuestions[0].id);
      console.log(filterQuestions[0].qid);
      console.log(filterQuestions[0].question);
      console.log(filterQuestions[0].answers);
      
    }
    
    if(bankProductValue.length>0) {

    } else {

    }
    */
  }

  const setLenderType=(lendeType,lenderId) =>{
    LendedTypeArray.push({ [lenderId]: lendeType});
    // console.log(LendedTypeArray);
    setaccountTypeModalShow(false);
    setLendedTypeArray(LendedTypeArray);
  };

  const onBankQuesion = (e) => {
    // console.log("+++111+++");
    // console.log(bankProductQuestions);
    
    let bid = e.target.getAttribute('data-bid');
    let qid = e.target.getAttribute('data-qid');
    let ansid = e.target.value;
    
    if(e.target.checked) {
      bankQuestionValue.push(e.target.value);
    } else {
      bankQuestionValue.pop(e.target.value);
    }
    setBankQuestionValue([]);
    // console.log(bankQuestionValue);
    setQuestionsAmExModalShow(false);
    setBankQuestionList([{bid:bid,qid:qid,ansid:ansid}]);
    //setBankProductQuestions([{bid:bid,qid:qid,ansid:ansid}]);
    bankProductQuestions.push({"bid":bid,"qid":qid,"ansid":ansid});

    // console.log("+++2222+++");
    // console.log(bankProductQuestions);
  }

  const onBankQuesionVigMy = (e) => {
    // console.log("+++Vig Money 111+++");
    // console.log(bankProductQuestions);
    
    let bid = e.target.getAttribute('data-bid');
    let qid = e.target.getAttribute('data-qid');
    let ansid = e.target.value;
    
    if(e.target.checked) {
      bankQuestionValue.push(e.target.value);
    } else {
      bankQuestionValue.pop(e.target.value);
    }
    setBankQuestionValue([]);
    // console.log(bankQuestionValue);
    setQuestionsVigMoneyModalShow(false);
    setBankQuestionList([{bid:bid,qid:qid,ansid:ansid}]);
    //setBankProductQuestions([{bid:bid,qid:qid,ansid:ansid}]);
    bankProductQuestions.push({"bid":bid,"qid":qid,"ansid":ansid});

    // console.log("+++Vig Money 2222+++");
    // console.log(bankProductQuestions);
  }

  const onBankQuesionHsbc = (e) => {
    // console.log("+++ HBSC 111+++");
    // console.log(bankProductQuestions);
    
    let bid = e.target.getAttribute('data-bid');
    let qid = e.target.getAttribute('data-qid');
    let ansid = e.target.value;
    
    if(e.target.checked) {
      bankQuestionValue.push(e.target.value);
    } else {
      bankQuestionValue.pop(e.target.value);
    }
    setBankQuestionValue([]);
    // console.log(bankQuestionValue);
    setQuestionsHsbcModalShow(false);
    setBankQuestionList([{bid:bid,qid:qid,ansid:ansid}]);
    //setBankProductQuestions([{bid:bid,qid:qid,ansid:ansid}]);
    bankProductQuestions.push({"bid":bid,"qid":qid,"ansid":ansid});

    // console.log("+++ HBSC 2222+++");
    // console.log(bankProductQuestions);
  }

  
  

  const addLenderQuestion = (e) => {

    //setBankQuestionValue
    // console.log(bankQuestionValue);
    if(bankQuestionValue.length>0) {

    }

  };

  let optionData1;
  let legendsOptionData;
  useEffect(() => {
    (async () => {
      const bankListResponse = await bankList();
      setLenderList({
        legendBanks: bankListResponse.data.response.legend_banks,
        banks: bankListResponse.data.response.banks,
        otherBanks: bankListResponse.data.response.other_banks,
        banksProducts: bankListResponse.data.response.bank_products,
        banksQuestion: bankListResponse.data.response.bank_questions,
      });
    })();
  }, []);
  if (lenderList.banks) {
    optionData1 = lenderList.banks.map((bankData, index) => {
      let labelClass = '';
      if ([72, 99].includes(bankData.id)) {
        labelClass = `${Sass['labl2']}`
      } else if (bankData.id == 33 || bankData.id == 4 || bankData.id == 37) {
        labelClass = `${Sass['labl1']}`
      } else {
        labelClass = ``
      }
      return (
        <li className="popupLink" key={bankData.id}>
          <CheckBox
            dataId={`chkBankName${bankData.id}`}
            name="chkBankName[]"
            className={`${Sass['custom-checkbox']} bank-name`}
            CheckBoxText={bankData.bank_name}
            onChange={onSelectBank}
            value={bankData.id}
            labelClassName={labelClass}
          />
        </li>
      );
    });
  }

  if (lenderList.legendBanks) {
    legendsOptionData = lenderList.legendBanks.map((legendBankData, index) => {
      let labelClass = '';
      return (
        <li className={`${Sass['wid100']} popupLink`} key={legendBankData.id+"_"+index}>
          <CheckBox
            dataId={`chkBankName${legendBankData.id}_${index}`}
            name="chkBankName[]"
            className={`${Sass['custom-checkbox']} bank-name`}
            CheckBoxText={legendBankData.bank_name}
            onChange={onSelectLegendBank}
            value={legendBankData.id}
            labelClassName={labelClass}
            lid={legendBankData.lid}
          />
        </li>
      );
    });
  }

  // lender validation and slide change
  const lenderValidation = (e) => {
    // console.log(bankSelectedValue);
    // console.log(bankProductQuestions);

    if (otherBankSelectedList1.length > 0 || bankSelectedValue.length > 0) {
      setBankValidationMsg(false);
      let localBankSelectionCount = otherBankSelectedList1.length + bankSelectedValue.length;
      setBankSelectionCount(localBankSelectionCount);

      if (localBankSelectionCount <= 3 && !bankPopupStatus) {
        setBankPopupStatus(true);
        setBankNoticeModalShow(true);
      } else {
        setBankNoticeModalShow(false);
        dataLayer({
          'question': 'Select Banks or Lenders',
          'answer': 'Completed'
        });
        slideChange(e);
      }

      if(localBankSelectionCount == 1)
      {
        setLender('lender');
      }else{
        setLender('lenders');
      }

    } else {
      setBankValidationMsg("Please select Bank");
    }
  };

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",

  };

  const ProductDetails = () => {
    let lenderId = bankProductList.id;
    let lenderLid =  bankProductList.lid ? bankProductList.lid : null;
    
    const listItems = bankProductList.product.map((data, index) => {
      //console.log("index:-"+index+"  lenderId:-"+lenderId);
      let labelClass = '';
      return (
        <li key={lenderId+"_"+index}>
          <CheckBox
            dataId={`chkBankProduct${lenderId}_${index}`}
            name="chkBankProduct[]"
            CheckBoxText={data}
            onChange={onBankProduct}
            value={data}
            lid={lenderLid}
          />
        </li>
        // <a onClick={() => setLenderType( data,lenderId)} key={index.toString()} className={Sass['btn-pops']} >{data}</a>
      );
    });
    //<a className={Sass['btn-pops']} onClick={() => setLenderType('home_loan',lenderNames.lenderId)}>Home Loan</a>
    //const listItems = bankProductList.map((number) =>  <span>{number}</span>);
    return listItems;
  };

  useEffect(() => {
    if (productStatus) {
      dispatch({ type: "productChange", payload: { value: false } });
    }
  }, [productStatus]);
  return (
    <Fragment>
      <div id="slide1" className={className}>
        <h4>Select which Banks or Lenders may have sold you CCI on Personal Loans, Credit Cards, Home Loans and Car Loans</h4>
        <h6>Please only tell us about accounts you have not already received a CCI refund for, and are not part of a Class Action.</h6> 
        <ul className={Sass.options}>
          <fieldset className={`${Sass['fieldset2']}`} >
            {/* <legend className={`${Sass['legend2']} `}><strong><em></em></strong></legend> */}
            {(!isBankListLoading) ? legendsOptionData : ""}
          </fieldset>
          {(!isBankListLoading) ? optionData1 : ""}
        </ul>
        <div className={'clearfix'}></div>
        <div className={Sass['form-group']}>
          <div className={`${Sass['bp0']}`}>
            <h6 className={`${Sass['h6']}`}><span className={`${Sass['yelbox']}`}>Don’t See Your Bank or Lender?
            </span><br className={`${Sass['mobile-break']}`}/>
            <span className={`${Sass['normalbox']}`}>Check Here as Well</span></h6>
            <div className={'row'}>
              <div className={`col-lg-12 col-md-12 col-sm-12 col-12 ${Sass['mulselectouter']}`} >
                <Multiselect
                  style={mystyle}
                  placeholder="Select your bank or lender"
                  options={lenderList.otherBanks} // Options to display in the dropdown
                  // selectedValues={selectedValue} // Preselected value to persist in dropdown
                  onSelect={onSelectOtherBank} // Function will trigger on select event
                  onRemove={onRemoveOtherBank} // Function will trigger on remove event
                  displayValue="bank_name" // Property name to display in the dropdown options
                  showArrow="true"
                />
                <span id="bank_err" className="error_msg">{bankValidationMsg}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={'col-lg-12 col-md-12 col-12 text-center'}>
          <InputButton
            name="slide1"
            className={`${Sass.btn} btn next01 ${Sass.getstart_but}`}
            value="Next"
            btnType="button"
            onClick={lenderValidation}
          />
        </div>
        <div className={`col-lg-12 col-md-12 col-xs-12 ${Sass.frm_lst}`}>
          <h6>PERSONAL INFORMATION GUARANTEE</h6>
          <p>We do not cold call, spam or pass on your data for marketing</p>
        </div>
      </div>
      <Modal show={bankNoticeModalShow} onHide={() => setBankNoticeModalShow(false)} aria-labelledby="BankSelectionNotice" centered contentClassName={Sass['popbord']} backdrop="static">
        <Modal.Body>
          <p className="text-center">We noticed you only selected {bankSelectionCount} {lender}. Most of our clients have had financial products from 4 or more lenders throughout their lifetime.</p>
          <div className="text-center">
            <a className={Sass['btn-pops']} onClick={() => setBankNoticeModalShow(false)}>Yes, I'll add more</a>
            <a className={Sass['btn-pops']} name="slide1" onClick={lenderValidation}>No, that's all</a>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={accountTypeModalShow}   centered contentClassName={`${Sass['popbord']} product-list`} backdrop="static">
        <Modal.Body>
          <p className="text-center">Select which types of credit you’ve had with {lenderNames.lenderName} </p>
          <div className="text-center">
            {/* <a className={Sass['btn-pops']} 
            onClick={() =>{
            setLenderType('credit_card',lenderNames.lenderId);
            //setaccountTypeModalShow(false); 
            }}>Credit Card</a>
            <a className={Sass['btn-pops']} onClick={() => setLenderType('personal_loan',lenderNames.lenderId)}>Personal Loan</a>
            <a className={Sass['btn-pops']} onClick={() => setLenderType('home_loan',lenderNames.lenderId)}>Home Loan</a> */}
            <ul>
              {accountTypeModalShow ? <ProductDetails /> : null}
            </ul>
            {state.isChecked && (
              <span className="error_msg" id="productID">
                Please select a credit type
              </span>
            )}
            <br/><br/>
            <InputButton
              name="prodlistok"
              className={`${Sass.btn} btn ${Sass.backbt}`}
              id="prodlistok"
              value="OK"
              btnType="button"
              onClick={addBankProducts}
              />
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={questionsAmExModalShow}   centered contentClassName={`${Sass['popbord']} product-list`} backdrop="static">
        <Modal.Body>
          <p className="text-center">Have you taken out this before 2012?</p>
          <div className="text-center">
            <ul>
              <li>
                <input type="radio" id="chkBankProduct2_1" name="chkBankQuestion" value="1" onClick={onBankQuesion} data-qid="1" data-bid="2" />
                <label htmlFor="chkBankProduct2_1">Yes</label>
              </li>
              <li>
                <input type="radio" id="chkBankProduct2_2" name="chkBankQuestion" value="2" onClick={onBankQuesion} data-qid="1" data-bid="2" />
                <label htmlFor="chkBankProduct2_2">No</label>
              </li>
            </ul>            
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={questionsHsbcModalShow}   centered contentClassName={`${Sass['popbord']} product-list`} backdrop="static">
        <Modal.Body>
          <p className="text-center">Have you taken out this before 2012?</p>
          <div className="text-center">
            <ul>
              <li>
                <input type="radio" id="chkBankProduct64_1" name="chkBankQuestion" value="1" onClick={onBankQuesionHsbc} data-qid="1" data-bid="64" />
                <label htmlFor="chkBankProduct64_1">Yes</label>
              </li>
              <li>
                <input type="radio" id="chkBankProduct64_2" name="chkBankQuestion" value="2" onClick={onBankQuesionHsbc} data-qid="1" data-bid="64" />
                <label htmlFor="chkBankProduct64_2">No</label>
              </li>
            </ul>            
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={questionsVigMoneyModalShow}   centered contentClassName={`${Sass['popbord']} product-list`} backdrop="static">
        <Modal.Body>
          <p className="text-center">Have you taken out this in the last 15 years?</p>
          <div className="text-center">
            <ul>
              <li>
                <input type="radio" id="chkBankProduct141_1" name="chkBankQuestion" value="3" onClick={onBankQuesionVigMy} data-qid="2" data-bid="141" />
                <label htmlFor="chkBankProduct141_1">Yes</label>
              </li>
              <li>
                <input type="radio" id="chkBankProduct141_2" name="chkBankQuestion" value="4" onClick={onBankQuesionVigMy} data-qid="2" data-bid="141" />
                <label htmlFor="chkBankProduct141_2">No</label>
              </li>
            </ul>            
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default Lendors;
