import React from "react";

const SlideQuestion1 = ({
  Sass,
  slideChange,
  className
}) => {
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  let userInfo = localStorage.getItem("user_info");
  if(!userInfo && formParameters ) {
    userInfo = formParameters.txtFName+' '+formParameters.txtLName;
  }

  const nextSlide = async (e) => {
    slideChange(e);
  };

  return (
    <>
      <section id="question1" className={` ${Sass['content-part']} ${className}`}>
        <div className="container-fluid">
          <div className="row">
            <div className={`col-xl-6 col-lg-6 col-md-12 col-12 text-center ${Sass['my-auto']}`}>
              <div className={Sass['video_div']}>
                <video autoPlay muted playsInline loop tabIndex="0">
                  <source src="assets/UserVerificationV1/video/video-intro.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={`row align-items-center ${Sass['text-div']}`}>
                <img src="assets/UserVerificationV1/img/logo.png" className={Sass['logo']} alt="" />
                {/* <h2>{formParameters.txtFName} {formParameters.txtLName}, why is Reclaimer asking for your Government ID and a photo?</h2> */}
                <h2>{userInfo}, why is Reclaimer asking for your Government ID and a photo?</h2>
                <div className="col-md-12 col-12 align-middle d-block d-lg-none">
                  <a className={`${Sass['btn-home']} btn1`} name="btn1" onClick={nextSlide}>{"FIND OUT >>"}</a> 
                </div>
              </div>
            </div>
            <div className={`col-xl-6 col-lg-6 col-md-12 col-12 text-center ${Sass['white_bg']} ${Sass['my-auto']} d-none d-lg-block`}>
              <span className="align-middle">
                <a className={`${Sass['btn-home']} btn1`} name="btn1" onClick={nextSlide}>{"FIND OUT >>"}</a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SlideQuestion1;
