import React,{ useState } from "react";
import { Modal } from 'react-bootstrap';
import "../../../assets/SK_V1/scss/SK_V1.modal.scss";


const PreviousNamesModal = ({
  showstatus,
  hidepopup,
  modalcloseupfn,
  skipmodalfn

}) => {

  const skippreaddress =()=>{
    skipmodalfn(true);
    hidepopup();
  }

  const closemodal = ()=>{
    hidepopup();
    modalcloseupfn()
  }

  return (
    <>
      <Modal show={showstatus} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <h3>Have you been know by a different name?</h3>
          <h3>Or, would banks have a different address on file?</h3>
          <p>You may have taken your CCI policy out when you had a previous address and/or surname.</p>
      <p>To help your lender identify all the CCI policies you may have had, you will now be asked to provide any previous address and surnames.</p>   
{/*             <p>Have you ever been known by a different name? Or would the banks have a different address on file?</p>*/}
          {/* <p><img src="/assets/CPL_SB_V1/img/line.png" alt=""/></p> */}
          <ul className="options">
              <li className="popupLink">
                <input value="" type="checkbox" name="prename" id="addresses" className="custom-checkbox bank-name next05 slide9_nxtbtn"/>
                <label htmlFor="addresses" onClick={closemodal}>I have previous name(s)/address(es)</label>          
              </li>
              <li className="popupLink">
                <input value="" type="checkbox" name="prename" id="name1" className="custom-checkbox bank-name next06"/>
                <label htmlFor="name1" onClick={skippreaddress}>I don't have any previous name/address</label>          
              </li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PreviousNamesModal;
