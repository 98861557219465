import React from "react";

const RadioButton = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  onLabelClick,
}) => {
  return (
    <>
      <input
        type="radio"
        className={className}
        name={name}
        value={value}
        onClick={onClick}
        id={id}
      />
      <label htmlFor={id} className={labelClassName} onClick={onLabelClick} 
      dangerouslySetInnerHTML={{ __html: labelName }}>
      </label>
    </>
  );
};
export default RadioButton;
