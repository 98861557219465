import React, { Fragment, useState } from "react";
// import React, { useEffect, useState, useRef, useContext, useReducer } from "react";
import PrivacyPopup from "../../Layouts/Common/PrivacyPopup";
//import TermsPopup from "../../Layouts/Common/TermsPopup";
import { Modal, Button } from "react-bootstrap";

const Footer = ({ Sass }) => {

   const [privacyModalShow, setPrivacyModalShow] = useState(false);
   const [termsModalShow, setTermsModalShow] = useState(false);

   return (

      <Fragment>
         <footer className={Sass.footer}>
            <div className={` ${Sass.container} container`}>
               <div className={'row'}>
                  <div className={'col-lg-7 col-md-6 col-12 text-left'}>
                     <h3>Reclaimer is here to help</h3>
                     <p>Reclaimer is here to help you reclaim money taken by your bank.
                     Reclaimer works for you, not the banks.</p>
                     <p>If you need help, you can contact the team at Reclaimer here: <a href="support@reclaimer.com.au" target="_blank">
                     support@reclaimer.com.au</a></p>
                     <p>*<a href="https://www.ifa.com.au/news/27141-asic-puts-total-cost-of-remediation-at-9bn"
                        target="_blank">https://www.ifa .com.au/news/27 141-asic-puts-total -cost-of-remediation-
                   at-9bn</a></p>
                  </div>
                  <div className={'col-lg-5 col-md-6 col-12 text-right'}>
                     <img src="/assets/SK_V1/img/ft-logo.png" alt="" />
                  </div>

                  <div className={'col-lg-12 col-md-12 col-12 text-center'}>

                     <ul>
                        {/* <li><a role="button" tabIndex="0" onClick={() => setPrivacyModalShow(true)}>Privacy Policy</a></li> */}
                        {/* 
                        <li><a role="button" tabIndex="0" onClick={() => setTermsModalShow(true)}>Terms & Conditions</a></li>
                        */}
                        <li><a href="/assets/Privacy_Policy_v1.1-Reclaimer.pdf" role="button" tabIndex="0" target="_blank" >Privacy Policy</a></li>
                        <li><a href="/assets/Terms_and_Conditions_v1.2-Reclaimer.pdf" role="button" tabIndex="0" target="_blank" >Terms & Conditions</a></li>
                     </ul>
                     <p><b>© 2021 Reclaimer Australia Pty Ltd (ABN 83 634 364 604)</b></p>
                  </div>
               </div>
            </div>
         </footer>
         
         <PrivacyPopup show={privacyModalShow} onHide={() => setPrivacyModalShow(false)}  Sass={Sass} />
         {/* 
         <TermsPopup show={termsModalShow} onHide={() => setTermsModalShow(false)}  Sass={Sass} />
         */}

         </Fragment>
   );
};

export default Footer;
