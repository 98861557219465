import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

const UserDocumentPopup = ({
   show,
   onHide,
   sass,
   ispicked,
   imgfile,
   userfile,
   className,
   savedocument,
   isLoading,
   isInValidfFile,
   retakeClick,
   errorMessage
}) => {
   return (      
      <Fragment>
         <Modal  show={show} onHide={onHide}  aria-labelledby="TermsTitle" centered className={sass.idreviewModal} >
            <Modal.Body>
               {ispicked && (
                  <div className={sass.idwrapper}>
                     {!isInValidfFile && 
                        <div>
                           <p><img src={userfile.preview} alt={userfile.title} /></p>
                        
                           <p class="m-0"><span><img src="assets/UserDocument/img/checked.png" alt="" />
                              </span> Document is Clear & Legible
                           </p>
                           <p><span><img src="assets/UserDocument/img/checked.png" alt=""/></span> 
                              Entire Document is Captured
                           </p>
                        </div>
                     }
                     {isInValidfFile &&
                        <p className={sass.error_msg}>{errorMessage.txt}</p>
                     }
                     {/*<p className={sass.error_msg}>The uploaded ID type should be an image in jpeg,png or jpg</p>*/} 
                     <p class="text-center">
                        {!isInValidfFile &&
                        <input type="button" value="Submit" class="btn btn-success" onClick={savedocument}/>
                        }   
                        <input type="button" value="Retake Photo" data-dismiss="modal" className={`btn btn-danger ${sass['btn-danger']} `} onClick={retakeClick} />
                     </p>
                     {isLoading &&
                        <p>Please Wait...</p>
                     }                      
                  </div>
               )}
            </Modal.Body>
         </Modal>  
      </Fragment>
   );
};

export default UserDocumentPopup;