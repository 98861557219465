import React from "react";

const SlideQuestion3 = ({
  Sass,
  slideChange,
  className,
  setIDUpload
}) => {

  const nextSlide = async (e) => {
    slideChange(e);
  };
  return (
    <>
      <section id="question3" className={`${Sass['content-part']} ${Sass['animated']} ${Sass['animatedFadeInUp']} ${Sass['fadeInUp']} ${className}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6  col-lg-6 col-md-12 col-12 text-center ">
              <div className={Sass['video_div']}>
                <video autoPlay muted playsInline loop  tabIndex="0">
                  <source src="assets/UserVerificationV1/video/video-intro2.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={`row align-items-center ${Sass['text-div']}`}>
                <img src="assets/UserVerificationV1/img/logo.png" className={Sass['logo']} alt="" />
                <h3>To achieve this, we have partnered with Australia's very own industry leader in fraud-prevention who is trusted by the world's top banks, fintechs & financial institutions.</h3>
                <img src="assets/UserVerificationV1/img/frankieone.png" className={`${Sass['frankie']} d-none d-lg-block`} alt="" />
                <div className="col-md-12 col-12 align-middle d-block d-lg-none">
                  <img src="assets/UserVerificationV1/img/frankieone.png" className={Sass['frankie']} alt="" />
                  {/** 
                  <a className={`btnwidget ${Sass['btn-upload']} ${Sass['bt110']}`} onClick={() => {
                      setOpen(true);
                      setStart(true);
                    }}
                    ><span>A</span>I can upload ID & a selfie now</a>*/}
                  <a className={`btnwidget ${Sass['btn-upload']} ${Sass['bt110']}`} onClick={setIDUpload}
                    ><span>A</span>I can upload ID & a selfie now</a>  
                  <br />
                  <a className={`${Sass['btn-upload']} btn3`} name="btn3" onClick={nextSlide}><span>B</span>Remind me again tomorrow</a> 
                </div>
              </div>
            </div>
            <div className={`col-xl-6 col-lg-6 col-md-12 col-12 text-center ${Sass['white_bg']} ${Sass['my-auto']} d-none d-lg-block`}>
              {/*<span className="align-middle"><a className={`btnwidget ${Sass['btn-upload']}`} onClick={() => {
                  setOpen(true);
                  setStart(true);
                }}><span>A</span>I can upload ID & a selfie now</a> <br />
              */}
              <span className="align-middle"><a className={`btnwidget ${Sass['btn-upload']}`} onClick={setIDUpload}><span>A</span>I can upload ID & a selfie now</a> <br />
              <a className={`${Sass['btn-upload']} btn3`} name="btn3" onClick={nextSlide}><span>B</span>Remind me again tomorrow</a> </span> 
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SlideQuestion3;
