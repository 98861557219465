import React, { Fragment } from "react";
import SelectBox from "../../UI/SelectBox";
import TextField from "../../UI/TextField";
import { Salutation } from "../../../Constants/Constants";
import { DateOfBirth } from "./DateOfBirth";
import InputButton from "../../UI/InputButton";
import dataLayer from '../DataLayer';

const BasicDetails = ({
  className,
  backClick,
  validation,
  validationMsg,
  trigger,
  slideChange,
  Sass
}) => {

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var txtFNameResult = await trigger("txtFName");

    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    // if (lstSalutationResult) {
    //   txtFNameResult = await trigger("txtFName");
    // }
    // else {
    //   return false;
    // }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    }
    else {
      return false;
    }
    if (txtLNameResult) {
      lstDobDayResult = await trigger("lstDobDay");
    }
    else {
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("lstDobMonth");
    }
    else {
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("lstDobYear");
    }
    else {
      return false;
    }

    if (
      // !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      dataLayer({
        'question': 'Personal details, DOB',
        'answer': 'Completed'
      });
      slideChange(e);
    } else {
      return false;
    }
  };

  return (

    <Fragment>
      <div id="slide2" className={className}>
        <h5>50% COMPLETE</h5>
        <h4>ENTER YOUR NAME AND<br />
              DATE OF BIRTH:</h4>
        {/* <div className={'col-lg-12 col-md-12 col-sm-12 col-12'}>
          <div className={'form-group'}>
            <SelectBox
              className="form-control"
              OptionValue={Salutation}
              name="lstSalutation"
              myRef={validation({ required: "Please Enter Salutation" })}
              validationMsg={
                validationMsg.lstSalutation &&
                validationMsg.lstSalutation.message
              }
            ></SelectBox>
          </div>
        </div> */}
        <div className={'col-lg-12 col-md-12 col-sm-12 col-12'}>
          <div className={'form-group'}>
            <TextField
              type="text"
              className="form-control hero-input"
              placeholder="First Name"
              name="txtFName"
              validation={validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                validationMsg.txtFName && validationMsg.txtFName.message
              }
            ></TextField>
          </div>
        </div>

        <div className={'col-lg-12 col-md-12 col-sm-12 col-12'}>
          <div className={'form-group'}>
            <TextField
              type="text"
              className="form-control hero-input"
              placeholder="Middle Name"
              name="txtMName"
              validation={validation()}
              validationMsg=""
            ></TextField>
          </div>
        </div>

        <div  className={'col-lg-12 col-md-12 col-sm-12 col-12'}>
          <div className={'form-group'}>
            <TextField
              type="text"
              className="form-control hero-input"
              placeholder="Last Name"
              name="txtLName"
              validation={validation({
                required: "Please Enter Lastname",
                minLength: {
                  value: 2,
                  message: "Please Enter Valid Lastname",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Lastname is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                validationMsg.txtLName && validationMsg.txtLName.message
              }
            ></TextField>
          </div>
        </div>

        <div className={'col-lg-12 col-md-12 col-sm-12 col-12'}>
          <div className={Sass['form-group']}>
            <DateOfBirth
              textDob="Date Of Birth"
              validation={validation}
              validationMsg={validationMsg}
              Sass={Sass}
            />
          </div>
        </div>

        <div className={'col-lg-12 col-12 text-center'}>
          <InputButton
            name="backslide2"
            className={`${Sass.btn} btn ${Sass.backbt}`}
            id="back01"
            value="< Back"
            btnType="button"
            onClick={backClick}
          />
          &nbsp;
          <InputButton
            name="slide2"
            className={`${Sass.btn} btn next02 getstart_but`}
            id="next02"
            value="Next"
            btnType="button"
            onClick={basicDetailsValidation}
          />
        </div>
        <div  className={`col-lg-12 col-md-12 col-12 ${Sass.frm_lst}`}>
          <h6>PERSONAL INFORMATION GUARANTEE</h6>
          <p>We do not cold call, spam or pass on your data for marketing</p>
        </div>
      </div>

    </Fragment>
  );
};
export default BasicDetails;
