import { AdtopiaApi } from "../api/AdtopiaApi";

export const useRedirection = () => {
  const getRedirectionUrl = async (asset_id, upid) => {
    const response = await AdtopiaApi.post("asset", {
      asset_id,
      upid,
    });
    return response;
  };
  return { getRedirectionUrl };
};