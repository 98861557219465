import React, {
  Fragment, 
  useState,
  useEffect,
  useReducer,
  useContext,
} from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import FVStyle from '../../../assets/Followup/css/Followup.module.scss';
import AnimateStyle from '../../../assets/Followup/css/Animated.module.css';
import { queryString } from "../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { Api } from "../../../api/Api";
import InputButton from "../../UI/InputButton";
import Loader from "../../Includes/Followup/Loader";
import UserDocumentFlp from "../../Includes/Followup/UserDocumentFlp";
import SignatureFlp from "../../Includes/Followup/SignatureFlp";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import { useBuyerPosting } from "../../../Hooks/useBuyerPosting";
import LoaderPopup from "../../Includes/Followup/LoaderPopup";
import { FrankieOneAPI } from "../../../Hooks/FrankieOneAPI";
import FrankieSmartUI from "./FrankieSmartUi";
import VisitorParamsContext from "../../../Contexts/VisitorParams";

var complete_arr = [];
const initialState = {
  showButton1: "hide",
  showButton2: "hide",
  showTick1: "hide",
  showTick2: "hide",
};

const FormReducer = (state, action) => {

  switch (action.type) {
    case "showButton": {
      if (action.payload.pendingStatus.status_btn == "identity") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      }
      else if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton2: "show",
          showTick2: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "showTick": {
      if (action.payload.pendingStatus.status_btn == "identity") {
        return {
          ...state,
          showTick1: "show",
          showButton1: "hide",
        };
      }
      else if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showTick2: "show",
          showButton2: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "showId": {
      if (action.payload.pendingStatus.status_btn == "identity") {
        return {
          ...state,
          showButton1: "hide",
          showTick1: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
  }
};

const Followup = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
  
  const [showLoad, setshowLoad] = useState("show");
  const [docClass, SetDocClass] = useState("modal fade");
  const [signatureClass, SetSignatureClass] = useState("modal fade");

  const [showUserName, setUserName] = useState("");
  const [allFollowupData, setFollowupData] = useState({});
  const [pendingStatus, setPendingStatus] = useState([]);
  const [pending, setPending] = useState(true);

  const [showPopupLoad, setShowPopupLoad] = useState(false);
  const [initialApiLoad, setInitialApiLoad] = useState(false);
  
  const [userUuid, setUserUuid] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const { saveStatus } = FrankieOneAPI();

  const history = useHistory();

  // Smart ui 

  const FRANKIE_URL = process.env.REACT_APP_FRANKIE_URL;
  const FRANKIE_CONFIG = {
    frankieBackendUrl: FRANKIE_URL,
    documentTypes: ["DRIVERS_LICENCE", "PASSPORT"],
    idScanVerification: {
      injectedCss: '.onfido-sdk-ui-Theme-step { min-height: 90%}'
    },
    ageRange: [18,125],
    organisationName: "Reclaimer's",
    googleAPIKey: false,
    phrases: {
      document_select: {
        title: "Choose your ID",
        hint_message: "Choose which ID you'd like to provide."
      },
    },
    welcomeScreen: {
      type: true,
      htmlContent: [
        "<div><h3><strong>Verify Your Identity</strong></h3><p>As the claims process will involve interaction with the banks and lenders you have told us about, for your security, we need to verify your identity.<br><br>It's important you enter your details as accurately as possible.<br><br>The information you provide will be checked using the Australian Government Document Verification Service (DVS).</p></div>",
      ],
      ctaText: "Start Identity Verification",
    },
    phrases: {
      success_screen: {
        title: "Verification Completed",
        subtitle: "Your identity has been verified.",
      },
    },


    requestAddress: { acceptedCountries: ["AUS", "NZL"] },
    consentText:
      "I agree with the terms described in the Consent section of the Reclaimer's webpage",
  };
  const CREDENTIALS = '' + process.env.REACT_APP_FRANKIE_CUSTOMER_ID + ':' + process.env.REACT_APP_FRANKIE_API_KEY;
  const ENCODED_CREDENTIALS = Buffer.from(CREDENTIALS).toString('base64');// use your base64 encoded credentials!
  
  const FRANKIE_HEADERS = { authorization: `machine ${ENCODED_CREDENTIALS}` };

  const [start, setStart] = useState(false);
  const [open, setOpen] = useState(false);
  
  const FRANKIE_EVENTS = ['INIT', 'SCREEN:WELCOME', 'SCREEN:DOCUMENT_TYPE_SELECT', 'SCREEN:DRIVERS_LICENCE_INPUT', 'SCREEN:NATIONAL_ID', 'SCREEN:MEDICARE_INPUT', 'SCREEN:PASSPORT_INPUT', 'SCREEN:NAME_INPUT', 'SCREEN:DOB_INPUT', 'SCREEN:ADDRESS_INPUT', 'SCREEN:DETAILS_SUMMARY', 'SCREEN:FAILURE', 'SCREEN:SUCCESS', 'SCREEN:PENDING_VERFICATION', 'SCREEN:ERROR', 'DESTROY', 'FF_SAVE_RESULT', 'FF_CHECK_RESULT', 'FF_SAVE_ERROR', 'FF_CHECK_ERROR', 'FF_EXTERNAL_IDV_CHECK_COMPLETED'];

  useEffect(() => {
    
    const initialiseWidget = async () => {
      const data =
        await axios.post(`${FRANKIE_URL}/auth/v1/machine-session`, {}, {
          headers: FRANKIE_HEADERS,
        }
        )
      const {
        headers: { token: ffToken },
      } = data;

      let fo_entity_id = localStorage.getItem('fo_entity_id');
      let form_data = localStorage.getItem('formData');

      window.frankieFinancial.initialiseOnboardingWidget({

        entityId: fo_entity_id, // This will be your applicants reference code and corresponds to the customer_reference value in the FrankieOne Core API 

        config: FRANKIE_CONFIG,
        ffToken,
        width: `${window.innerWidth * 0.9}px`,
        height: `${window.innerHeight / 1.2}px`,
      });

      const eventEffect = function (eventName, event) {
        saveStatus(fo_entity_id, event.detail, eventName,'FLP');

        if ('SCREEN:SUCCESS' == eventName) {
          setTimeout(() => {
            history.push("/thankyou");
          }, "3000");
        }

        //needed

        if ('FF_CHECK_RESULT' == eventName) {
          
          //if ((JSON.parse(form_data).txtEmail).includes('@922.com')) {
          if (userEmail.includes('@922.com')) {
            history.push("/thankyou");
          }
        }

        if ('SCREEN:PENDING_VERFICATION' == eventName) {
          // if ('FF_CHECK_RESULT' == eventName) { 
            setPopupLoader(true);
            setTimeout(() => {
            onModalclose()
            setPendingStatus({ status_btn: 'identity', status_flag: 'showTick' });
            hidePopupLoader();
            setOpen(false);
            setStart(false);  
           // history.push("/thankyou");
          }, "3000");
        }
      }

      FRANKIE_EVENTS.forEach(function (eventName, index) {
        window.addEventListener(eventName, function (e) {
          eventEffect(eventName, e);
        });
      });

      // window.addEventListener('SCREEN:WELCOME', function (e) {
      //   console.log(JSON.stringify(e.detail));
      //   const saveStatusResult = saveStatus(
      //     fo_entity_id,
      //     e.detail,
      //     'SCREEN:WELCOME',
      //   );
      // });

      // window.addEventListener('SCREEN:SUCCESS', function (e) {
        
      //   const saveStatusResult = saveStatus(
      //     fo_entity_id,
      //     e.detail,
      //     'SCREEN:SUCCESS',
      //   );

      //   setTimeout(() => {
      //     history.push("/thankyou");
      //   }, "3000");
      // });

      // window.addEventListener('FF_CHECK_RESULT', function (e) {
        
      //   const saveStatusResult = saveStatus(
      //     fo_entity_id,
      //     e.detail,
      //     'FF_CHECK_RESULT',
      //   );
      //   console.log(saveStatusResult);
      //   if ((JSON.parse(form_data).txtEmail).includes('@922.com')) {
      //     // history.push("/thankyou");
      //   }

      // // setOpen(false);
      // // setStart(false);  

      // });
    };

    if (window.frankieFinancial && start) {
      console.log('inside loop');
      if(!initialApiLoad){
        
        CheckUser();
        console.log('not loaded')
      }

      initialiseWidget();

      //temp code
      setOpen(true);
      setStart(true);
    }
  }, [start]);

  // useEffect(() => {
  //   const getEntity = async () => {
  //     const response = await Api.post("/api/v1/frankie-one/check-entity?query_string=" + queryString, {});
  //     if (response.data.status === "Success") {
  //       localStorage.setItem('fo_entity_id', response.data.response.entityId);
  //     }
  //   };

  //   let fo_entity_id = localStorage.getItem('fo_entity_id');
  //   if (fo_entity_id=='') {
  //     getEntity();  
  //   }
  // },[]);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('atp_sub2');

  const hideLoader = () => {
    setshowLoad("hide");
  };

  const setLoader = (e) => {
    setshowLoad("show");
  };

  const hidePopupLoader = () => {
    setShowPopupLoad(false);
  };

  const setPopupLoader = (e) => {
    setShowPopupLoad(true);
  };
  
  //const timer = setTimeout(() => hideLoader(), 2000); 

  const onModalclose = () => {
    SetDocClass("modal fade");
    SetSignatureClass("modal fade");
  };
  
  // useEffect(() => {
  //   (async () => {
  //     const response = await Api.get("/api/v1/followup/get-pending-details?query_string=" + queryString, {});
  //     const followupDetails = response.data.response.followup_data;
  //     setUserName(response.data.response.user_name);
  //     setUserUuid(response.data.response.user_uuid);
  //     setFollowupData({
  //       flpData: response.data.response,
  //     });

  //     if (response.data.status === "Success") {
  //       localStorage.setItem('fo_entity_id', response.data.response.entity_id);
        
  //       if (response.data.response.pending_details.user_signature === 0) {
  //         setPendingStatus({ status_btn: 'signature', status_flag: 'showButton' });
  //       }
  //       else {
  //         setPendingStatus({ status_btn: 'signature', status_flag: 'showTick' });
  //       }
  
  //       if (response.data.response.pending_details.user_identity === 0) {
  //         setPendingStatus({ status_btn: 'identity', status_flag: 'showButton' });
  //       }
  //       else {
  //         setPendingStatus({ status_btn: 'identity', status_flag: 'showTick' });
  //       }

  //       setshowLoad("hide");
  //       //Load API
  //       const flpResponse = await loadFollowup(
  //       "followup_load",
  //       followupDetails,
  //       "cci_claims/SK_V1"
  //       )


  //       if (response.data.response.pending_details.user_signature === 1 && 
  //         response.data.response.pending_details.user_identity === 1) {
  //           history.push('/thankyou?uuid='+response.data.response.user_uuid);
  //         }
       
        
  //       } else {
  //       history.push('/thankyou');
  //     }
  //   })();
  // },[]);

    useEffect(() => {
      localStorage.setItem('page_source','FLP');
    const timer = setTimeout(() => {
      CheckUser();
   
    }, 1000);
    return () => clearTimeout(timer);
  }, []);


  //  useEffect(() => {
    // (async () => {

    const CheckUser = async () => {
      const response = await Api.get("/api/v1/followup/get-pending-details?query_string=" + queryString, {});
      const followupDetails = response.data.response.followup_data;
      setUserName(response.data.response.user_name);
      setUserUuid(response.data.response.user_uuid);   
      
      setFollowupData({
        flpData: response.data.response,
      });
      
      localStorage.setItem('user_info',response.data.response.user_name);
      let split_name = response.data.response.split_name;

      if (response.data.status === "Success") {
        localStorage.setItem('fo_entity_id', response.data.response.entity_id);
        setInitialApiLoad(true) 

        if (response.data.response.pending_details.user_signature === 0) {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showButton' });
        }
        else {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showTick' });
        }
        
        setUserEmail(response.data.response.user_email);
        localStorage.setItem('user_email', response.data.response.user_email);
        
        // if (response.data.response.pending_details.user_identity === 0) {
        //   setPendingStatus({ status_btn: 'identity', status_flag: 'showButton' });
        // } else if(response.data.response.pending_details.user_identity === 1) {
        //   setPendingStatus({ status_btn: 'identity', status_flag: 'showTick' });
        // } 
        // else {
        //   setPendingStatus({ status_btn: 'identity', status_flag: 'showId' });
        // }

        if (response.data.response.pending_details.user_identity === 0) {
          setPendingStatus({ status_btn: 'identity', status_flag: 'showButton' });
        } else {
          setPendingStatus({ status_btn: 'identity', status_flag: 'showTick' });
        }

        setshowLoad("hide");
        //Load API
        const flpResponse = await loadFollowup(
        "followup_load",
        followupDetails,
        "cci_claims/SK_V1"
        )

        // if(split_name =='SK_V3') {
        //   if (response.data.response.pending_details.user_signature === 1 && 
        //     response.data.response.pending_details.user_identity === 1) {
        //     history.push('/thankyou?uuid='+response.data.response.user_uuid);
        //   }
        // } else {
        //   if (response.data.response.pending_details.user_signature === 1) {
        //     history.push('/thankyou?uuid='+response.data.response.user_uuid);
        //   }
        // }
        
        if (response.data.response.pending_details.user_signature === 1 && 
          response.data.response.pending_details.user_identity === 1) {
          history.push('/thankyou?uuid='+response.data.response.user_uuid);
        }
      } else {
        history.push('/thankyou');
      }
    }
    // })();
  // },[]);

  const toUserVerific = () => {
    console.log("userUuid:-"+userUuid);
    if (userUuid) {
      history.push('/user_verificationv2?uuid='+userUuid+queryString);
    }
  }



  useEffect(() => {
    if (pendingStatus) {
      dispatch({ type: pendingStatus.status_flag, payload: { pendingStatus } });
      if (pendingStatus.status_flag == "showTick") {
        complete_arr.push(pendingStatus.status_btn);
        if (complete_arr.length > 1) {
          let uniqueComplete = [...new Set(complete_arr)];
          if (uniqueComplete.length == 2) {
            // const response = buyerPosting(
            //   "buyer_posting",
            //   allFollowupData.flpData.followup_data
            // )
            // setPending(false);
            // localStorage.clear();

            setPending(false);
          }
        }
      }
    }
  }, [pendingStatus]);

  const procedSubmit = () => {
    const flpResponse = loadFollowup(
      "followup_ty_store",
      allFollowupData.flpData.followup_data
    );
    //history.push('/thankyou');
    history.push('/thankyou?uuid='+userUuid);
  };

  //const pendingClassName = FVStyle['btn-submit'] + ' button1 ' + pending === true ? '': FVStyle['animated-effect'];
  let stylePending = '';
  if (pending === true) {
    stylePending = '';
  } else {
    stylePending = FVStyle['animated-effect'];
  }
  const pendingClassName = FVStyle['btn-submit'] + ' button1 ' + stylePending;
  
  const toUrl = '/unsubscribe?'+queryString;
  return (
    <Fragment>
      <Loader  LdStyle={FVStyle} showLoad={showLoad}></Loader>
      <div className={FVStyle['followup-body']}>
        <section
          className={FVStyle.formsection} 
          id="myDiv2" 
          style={showLoad === "hide" ? { display: "block" } : { display: "none "}}
          >
          <div className="container">
            <div className="row">
              <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                <div className={` ${FVStyle['blue-bg']} col-12`}>
                  <h4>Claim Status: {pending === true ? 'Pending' : 'Complete'}</h4>
                  <p>Almost done! We need a few more details to begin processing your claim.</p>
                </div>                  
                <div className="col-12 data-div animated fadeInUp">
                  <div className=" row">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th colSpan="2" className="align-middle" width='100%'>
                            Claimaint: {showUserName ? showUserName : null} 
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td>Signature:</td>
                          <td  className={state ? `text-center align-middle ${FVStyle['blink_me']} ${FVStyle[state.showButton2]}` : `hide`}>
                            <InputButton
                              name=""
                              className={FVStyle['add-btn']}
                              id="" 
                              value="Sign Here"
                              btnType="button"
                              onClick={() => SetSignatureClass("modal show")}
                            />
                          </td>
                          <td className={state ? `text-center align-middle ${FVStyle[state.showTick2]}` : `hide`}>
                            <img
                              src="/assets/Followup/img/y-but.png"
                              alt=""
                            />
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Customer Identification for Fraud Prevention:</td>
                          <td  className={state ? `text-center align-middle ${FVStyle['blink_me']} ${FVStyle[state.showButton1]}` : `${FVStyle['hide']}`}>
                            <InputButton
                              name=""
                              className={FVStyle['add-btn']}
                              id="" 
                              value="Add Now"
                              btnType="button"
                              // onClick={() => SetDocClass("modal show")}
                              onClick={() => {
                                setOpen(true);
                                setStart(true);
                              }}
                            />
                          </td>
                          <td className={state ? `text-center align-middle ${FVStyle[state.showTick1]}` : `${FVStyle['hide']}`}>
                          <img
                              src="/assets/Followup/img/y-but.png"
                              alt=""
                            />
                          </td>
                        </tr> */}

                        {/* 
                        {state && state.showButton1=='show' && 
                          <tr >  
                            <td>Customer Identification for Fraud Prevention:</td>
                            <td  className={state ? `text-center align-middle ${FVStyle['blink_me']} ${FVStyle[state.showButton1]}` : `${FVStyle['hide']}`}>
                              <InputButton
                                name=""
                                className={FVStyle['add-btn']}
                                id="" 
                                value="Add Now"
                                btnType="button"
                                onClick={toUserVerific}
                              />
                            </td>
                            <td className={state ? `text-center align-middle ${FVStyle[state.showTick1]}` : `${FVStyle['hide']}`}>
                            <img
                                src="/assets/Followup/img/y-but.png"
                                alt=""
                              />
                            </td>
                          </tr>
                        }
                        */}

                        <tr>
                          <td>Customer Identification for Fraud Prevention:</td>
                          <td  className={state ? `text-center align-middle ${FVStyle['blink_me']} ${FVStyle[state.showButton1]}` : `${FVStyle['hide']}`}>
                            <InputButton
                              name=""
                              className={FVStyle['add-btn']}
                              id="" 
                              value="Add Now"
                              btnType="button"
                              onClick={toUserVerific}
                            />
                          </td>
                          <td className={state ? `text-center align-middle ${FVStyle[state.showTick1]}` : `${FVStyle['hide']}`}>
                          <img
                              src="/assets/Followup/img/y-but.png"
                              alt=""
                            />
                          </td>
                        </tr>
                                               
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      <div className={FVStyle['unsubscribe']}>
          <div className="container-fluid">
            <div className="col-lg-12 col-12">
              <p  className="text-center" style={{ "fontSize": "9px" }}>
                (<NavLink className="navbar-item" activeClassName="is-active" to={toUrl}>Click here to unsubscribe</NavLink>) from Reclaimer marketing messages.
                </p>
            </div>
          </div>
      </div>

      <footer id="ftrdiv" style={showLoad === "hide" ? { display: "block" } : { display: "none "}}>
        <div className="container-fluid">
          <div className="col-lg-12 col-12">
            <button 
              type="submit" 
              value="" 
              id="mybut" 
              className={pendingClassName}
              onClick={procedSubmit}
              disabled={pending}
              >
                Proceed &gt;&gt;{""}
            </button>
          </div>
        </div>
      </footer>

      <UserDocumentFlp FVStyle={FVStyle} AnimateStyle={AnimateStyle} 
        carClassName={docClass} 
        onModalclose={onModalclose} 
        allFollowupData={allFollowupData} 
        setPendingStatus={setPendingStatus} 
        setLoader={setLoader} 
        hideLoader={hideLoader}
        setPopupLoader={setPopupLoader} 
        hidePopupLoader={hidePopupLoader}
        />

      <SignatureFlp FVStyle={FVStyle} 
        carClassName={signatureClass} 
        onModalclose={onModalclose} 
        allFollowupData={allFollowupData} 
        setPendingStatus={setPendingStatus} 
        showUserName={showUserName} 
        setLoader={setLoader} 
        hideLoader={hideLoader}
        setPopupLoader={setPopupLoader} 
        hidePopupLoader={hidePopupLoader} 
        />
      
      <LoaderPopup LdStyle={FVStyle} 
        show={showPopupLoad} 
        onHide={() => setPopupLoader(false)}
        
        />
      
     <FrankieSmartUI className="btn_close" show={open} onClose={() => setOpen(false)} />
    </Fragment>
  );
};

export default Followup;
