import React, { Fragment, useContext, useEffect } from "react";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import Sass from "../../../assets/SK_V1/scss/SK_V1.module.scss";
import { SK_V1Content } from "../../../Constants/SK_V1Content";
import FormSection from "../../Includes/Splits/SK_V1/FormSection";
import StaticSection from "../../Includes/Splits/SK_V1/StaticSection";
import Footer from "../../Includes/Layouts/Common/Footer";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import PageLoadApi from "../../../Utility/PageLoadApi";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";

const SK_V1 = () => {
   // const { visitorParameters } = useContext(VisitorParamsContext);
   // const { queryString } = useContext(QueryStringContext);
   // const { saveDataIngestion, isLoading } = useDataIngestion();
   // useEffect(() => {
   //    (async () => {
   //       await saveDataIngestion(
   //          visitorParameters.visitor_parameters,
   //          visitorParameters.data,
   //          'split_page_load',
   //          'cci_claims/SK_V1',
   //          queryString
   //       )
   //    })();
   // }, [visitorParameters]);

   return (
      <Fragment>
      <AdtopiaLoadLP pageType="lp" splitName="cci_claims/SK_V1" />
      {/* <PageLoadApi pageType="LP" splitName="cci_claims/SK_V1"  /> */}
         <section className={Sass.banner}>
            <div className={` ${Sass.container} container`}>
               <div className="row">
                  <div className={`col-lg-12 col-md-12 col-12 ${Sass['logo']} `}>
                     <img src="/assets/SK_V1/img/ft-logo.png" alt="" />
                  </div>
                  <div className={`col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12 ${Sass['lft-sec']}`}>
                     <h1 style={{color:'#f5efe0'}}>Claim your Refund on junk insurance today</h1>
                     <h2 style={{color:'#f5efe0'}}>You could be owed $000's!</h2>
                     <h3>It is reported that Australian banks have set aside over $9 billion* to refund their customers.
                     </h3>
                     <ul>
                        <li>No Account Number Needed</li>
                        <li>No Paperwork Required</li>
                        <li>Free Online Check</li>
                     </ul>
                  </div>
                  <FormSection Sass={Sass} />
               </div>
            </div>
         </section>
         <StaticSection content={SK_V1Content.faq} Sass={Sass} />
         <Footer Sass={Sass} />
      </Fragment>
   );
};

export default SK_V1;