import { Fragment, React, useReducer,useState } from "react";
import TextField from "../../UI/TextField";
import Anchor from "../../UI/Anchor";
import SelectBox from "../../UI/SelectBox";
import InputButton from "../../UI/InputButton";
import { usePostCodeAddress } from "../../../Hooks/usePostCodeAddress";
import dataLayer from '../DataLayer';
const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0
}
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case 'validationSucess': {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address
      }
    }
    case 'getExtraAddressDetails': {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address
      }
    }
    case 'showManualDiv': {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click
      }
    }
    default:
      return state;
  }
}
const PostCode = ({
  className,
  validation,
  validationMsg,
  onBackClick,
  clearErrors,
  setError,
  foDetails,
  slideChange,
  trigger,
  Sass
}) => {
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [animation1, setAnimation1] = useState('');
  const [animation, setAnimation] = useState('');
  const checkValidation = async (e) => {
 
    e.preventDefault();
    const txtSearchKey = await trigger("txtSearchKey");
    if (txtSearchKey) {
      // const getValidation = await getPostcodeValidation(e.target.value)
      // if (getValidation.data === 0) {
      //   setError("txtPostCode", {
      //     type: "manual",
      //     message: "Please Enter Valid Postcode",
      //   });
      // }
      // if (getValidation.data === 1) {
        const addressList = await getPostcodeAddress(e.target.value)
    
          // live case //  
          if(addressList.data.status && addressList.data.status == '0') {
          // test case
          // if(1 === 2) {
          setError("txtSearchKey", {
                type: "manual",
                message: "Please Enter Different Keyword",
              });
            }

        else {
          setAnimation(`${Sass.efct}`);
          setAnimation1('');
          dispatch({
            type: 'validationSucess',
            payload: {
              lookup: false,
              next_click: true,
              postcode: e.target.value,
              get_address: addressList,
              get_address: addressList.data,      // live case //
              check_select: true,
              manual_address: "hide",
            }
          });
        }
      // }
    }
  };
  const getValue = async (e) => {
    setAnimation('');
    setAnimation1(`${Sass.efct}`)
    e.preventDefault();
    if(e.target.value) {
      clearErrors(["txtAddressLine1","txtAddressLine2","txtSuburb","txtState","txtPostCode"]);
      const getData = await getSplitPostcodeAddress(e.target.value)
      dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show" } });   
      clearErrors(["txtAddressLine1","txtAddressLine2","txtSuburb","txtState","txtPostCode","txtAddress"]);
    } else {
      setAnimation(`${Sass.efct}`);
      setAnimation1('');
      clearErrors(["txtAddressLine1","txtAddressLine2","txtSuburb","txtState","txtPostCode"]);
      dispatch({ type: 'getExtraAddressDetails', payload: { get_details: {}, manual_address: "hide" } });   
    }
    // live case
    // dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData, manual_address: "show" } });  // test case
  };

  const lookupValidation = async (e) => {
    await trigger("txtSearchKey");
  }

  const  clearValidationMsg = async(e)=>{
    clearErrors(["txtSearchKey"]);
  }


  const addressValidation = async (e) => {
 
    let errorFlag = 0;
    const txtSearchKey = await trigger("txtSearchKey");
    const manual_txtAddressLine1 = await trigger("txtAddressLine1");
    var manual_txtState;
    var manual_txtPostCode;
    let txtAddressResult = await trigger("txtAddress");
    
    if(state.showManualAddress === 'show')
    {
      clearErrors(["txtSearchKey"]);
    }
 
    if (manual_txtAddressLine1) {
      manual_txtState = await trigger("txtState");
    }
    else {
      return false;
    }
    if (manual_txtState) {
      manual_txtPostCode = await trigger("txtPostCode");
    }
    else {
      return false;
    }
    if (state.showManualAddress === 'hide') {
      if (
        !txtSearchKey ||
        !txtAddressResult ||
        !manual_txtAddressLine1 ||
        !manual_txtState ||
        !manual_txtPostCode
      ) {
        errorFlag = 1;
      }
    }
    else if(state.showManualAddress === 'show')
    {
      clearErrors(["txtSearchKey"]);

      if (
        !manual_txtAddressLine1 ||
        !manual_txtState ||
        !manual_txtPostCode
      ) {
        errorFlag = 1;
      }
    }

    if (state.showManualAddress === 'hide') {
      const txtAddress = await trigger("txtAddress");
      if (!txtAddress) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      dataLayer({
        'question': 'What is your current address?',
        'answer': 'Completed'
      });
      slideChange(e);
    } else {
      return false;
    }
  };

  foDetails.fo_street_number_1 = state.getDetails.fo_street_number_1;
  foDetails.fo_street_number_2 = state.getDetails.fo_street_number_2;
  foDetails.fo_unit_identifier = state.getDetails.fo_unit_identifier;
  foDetails.fo_street_name = state.getDetails.fo_street_name;
  foDetails.fo_street_type = state.getDetails.fo_street_type;
  foDetails.fo_street = state.getDetails.fo_street;
  foDetails.fo_locality_name = state.getDetails.fo_locality_name;
  foDetails.fo_state_territory = state.getDetails.fo_state_territory;
  foDetails.fo_postcode = state.getDetails.postcode;

  // const HiddenFields = () => (
  //   <>
  //     <HiddenField
  //       name="txtUdprn"
  //       inputId="txtUdprn"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
  //     />
  //     <HiddenField
  //       name="txtDeliveryPointSuffix"
  //       inputId="txtDeliveryPointSuffix"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""}
  //     />
  //     <HiddenField
  //       name="txtPz_mailsort"
  //       inputId="txtPz_mailsort"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""}
  //     />
  //     <HiddenField
  //       name="txtStreet"
  //       inputId="txtStreet"
  //       inputValue={state.getDetails.Line3 !== "#" ? state.getDetails.Line3 : ""}
  //     />
  //   </>
  // );
  return (
    <Fragment>
      <div className={className} id="slide3">
        <h5>75% Completed</h5>
        <div className={'frminpart'}>
          <h4>What is your current address?</h4>
          {/* <div className={'col-lg-12 form-group'}>
            <TextField
              type="text"
              placeholder="Enter Your Postcode"
              name="txtPostCode"
              className="form-control hero-input"
              dataId="txtPostCode"
              onBlur={checkValidation}
              onChange={() => {
                dispatch({
                  type: 'validationSucess',
                  payload: {
                    lookup: true,
                    next_click: false,
                    check_select: false,
                    manual_address: "hide",
                    get_address: []
                  }
                })
              }}
              autoComplete="off"
              validation={validation({
                required: "Please Enter Postcode",
                pattern: {
                  value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                  message: "Please Enter a Valid Postcode",
                },
                minLength: {
                  value: 5,
                  message: "Please Enter a Valid Postcode",
                },
              })}
              validationMsg={
                validationMsg.txtPostCode && validationMsg.txtPostCode.message
              }
            />
          </div> */}


          <div className={'col-lg-12 form-group'}>
           
            
            <TextField
              type="text"
              placeholder="Enter Address Keyword"
              name="txtSearchKey"
              className="form-control hero-input"
              dataId="txtSearchKey"
              onBlur={checkValidation}
              onChange={() => {
                dispatch({
                  type: 'validationSucess',
                  payload: {
                    lookup: true,
                    next_click: false,
                    check_select: false,
                    manual_address: "hide",
                    get_address: []
                  }
                })
              }}
              autoComplete="off"
              
              
              validation={validation({
                required: "Please Enter Address Keyword",
                // pattern: {
                //   value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                //   message: "Please Enter a Valid Postcode",
                // },
                // minLength: {
                //   value: 5,
                //   message: "Please Enter a Valid Postcode",
                // },
              })}
              validationMsg={
                validationMsg.txtSearchKey && validationMsg.txtSearchKey.message
              }
            />
            

          </div>

          <div className={'col-lg-12 col-12 text-center slide8_lkup'}
            style={state.lookUp ? { display: "block" } : { display: "none" }} onClick={onBackClick} >
            <div style={{ width: "100%" }} className={'main_1'}>
              <InputButton
                name="backslide3"
                className={`${Sass.btn} btn ${Sass.backbt} slide8_bck`}
                id=""
                value="< Back"
                btnType="button"
              />
              &nbsp;
              <InputButton
                name="next"
                className={`${Sass.btn} btn center-block ${Sass.getstart_but} applynow02 ${Sass.lookupbtn} ${Sass.efct}`}
                value="Search Address"
                btnType="button"
                onClick={lookupValidation}
              />
            </div>
          </div>

          {/* <div class="col-lg-12 col-12 text-center slide8_lkup">
            <div class="main_1">
              <input name="next" class="btn getstart_but applynow02 lookupbtn" value="Lookup Address"
                type="button" />
            </div>
            <div class="form-group text-center" style="display: block;">
              <a class="manual-entry-request" id="manual-entry-btn" href="#">[ Having Issues? Click
              Here to Enter Manually ]</a>
            </div>
          </div> */}

          <div
            className="col-lg-12 form-group slide8_selbx"
            id="currentAddressCollapse"
            style={state.checkSelect ? { display: "block" } : { display: "none" }}
          >
            <label htmlFor="txtAddress">Select Address</label>
            <SelectBox
              className={`form-control ${animation}`}
              id="txtAddress"
              OptionValue={state.getAddress}
              name="txtAddress"
              onChange={getValue}
              myRef={validation({ required: "Please Select Address" })}
              validationMsg={
                validationMsg.txtAddress && validationMsg.txtAddress.message
              }
            />
          </div>

          <div
            className="form-group text-center"
            style={
              state.getDetails.length === 0 ? { display: "block" } : { display: "none" }
            }
          >
            {/* <Anchor
              className="manual-entry-request"
              idName="manual-entry-btn"
              onClick={() => { dispatch({ type: 'showManualDiv', payload: { manual_address: "show", manual_click: 1 } }) }}
              children="[ Having Issues? Click Here to Enter Manually ]"
            ></Anchor> */}
          </div>

          <div className={`col-lg-12 text-center ${Sass[state.showManualAddress]}`} id="customadr">
            {/* <div className="form-group">
              <TextField
                type="text"
                placeholder="Company"
                name="txtHouseName"
                className="form-control"
                dataId="txtHouseName"
                autoComplete="off"
                validation={validation()}
                validationMsg={
                  validationMsg.txtHouseName && validationMsg.txtHouseName.message
                }
                value={state.getDetails.Company}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
              <span id="addr_err" className="error_msg"></span>
            </div> */}
            <div className="form-group required-field">
              <TextField
                type="text"
                placeholder="Address Line 1 *"
                name="txtAddressLine1"
                onBlur={clearValidationMsg}
                className="form-control  hero-input"
                dataId="txtAddressLine1"
                autoComplete="off"
                validation={validation({
                  required: "Please Enter Address Line 1",
                })}
                validationMsg={
                  validationMsg.txtAddressLine1 &&
                  validationMsg.txtAddressLine1.message
                }
                value={state.getDetails.address_line_1}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className="form-group">
              <TextField
                type="text"
                placeholder="Address Line 2"
                name="txtAddressLine2"
                className="form-control  hero-input"
                dataId="txtAddressLine2"
                autoComplete="off"
                validation={validation()}
                validationMsg=""
                value={state.getDetails.address_line_2}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className="form-group">
              <TextField
                type="text"
                placeholder="Suburb"
                name="txtSuburb"
                className="form-control  hero-input"
                dataId="txtSuburb"
                autoComplete="off"
                validation={validation()}
                validationMsg=""
                value={state.getDetails.suburb}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className="form-group">
              <TextField
                type="text"
                placeholder="State *"
                name="txtState"
                className="form-control  hero-input"
                dataId="txtState"
                autoComplete="off"
                validation={validation({ required: "Please Enter State" })}
                validationMsg={
                  validationMsg.txtState && validationMsg.txtState.message
                }
                value={state.getDetails.state}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
            <div className="form-group">
              <TextField
                type="text"
                placeholder="Pincode *"
                name="txtPostCode" 
                className="form-control  hero-input"
                dataId="txtPostCode"
                autoComplete="off"
                validation={validation({ required: "Please Enter Postcode" })}
                validationMsg={
                  validationMsg.txtPostCode && validationMsg.txtPostCode.message
                }
                value={state.getDetails.postcode}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
            {/* {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null} */}
          </div>

          <div
            className="col-lg-12 col-12  text-center slide8_btn"
            style={state.nextClick ? { display: "block" } : { display: "none" }}
          >
            <div className="main_1">

              <InputButton
                name="backslide3"
                className={`${Sass.btn} btn  ${Sass.backbt} slide8_bck`}
                id="back02"
                value="< Back"
                btnType="button"
                onClick={onBackClick}
              />
              &nbsp;
              <InputButton
                name="slide3"
                className={`${Sass.btn} btn next03 center-block ${Sass.getstart_but} applynow02 ${animation1} sctop slide8_nxtbtn`}
                id="next03"
                value="Next"
                btnType="button"
                onClick={addressValidation}
                style={{ float: "none" }}
              />
            </div>
          </div>

          <div  className={`col-lg-12 col-md-12 col-12 ${Sass.frm_lst}`}>
            <h6>PERSONAL INFORMATION GUARANTEE</h6>
            <p>We do not cold call, spam or pass on your data for marketing</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PostCode;