
import {Api} from "../api/Api";

export const useUnsubscribeLoad = () => {

    const loadUnsubscribe = async (message_type,user_unsubs_data,followup_data,query_string) => {
        const UnsubscribeLoad = await Api.post("/api/v1/data-ingestion-pipeline", {
            message_type,
            user_unsubs_data,
            followup_data
        });
      
        return UnsubscribeLoad;
    }
    return { 
        loadUnsubscribe
    }
}