import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
  Fragment,
} from "react";
import { BrowserView, MobileView, IOSView, isBrowser, isMobile, isIPad13 } from "react-device-detect";
import SignStyle from "../../../assets/Signature/css/Signature.module.scss";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../../UI/CheckBox";
import Button from "../../UI/Button";
import { useSignature } from "../../../Hooks/useSignature";
import { signCheckText } from "../../../Constants/Constants";
import PrivacyPopup from "../../Includes/Layouts/Common/PrivacyPopup";
import SignaturePreviewPopup from "../../Includes/Layouts/Common/SignaturePreviewPopup";
//import TermsPopup from "../../Includes/Layouts/Common/TermsPopup";
import { Modal } from "react-bootstrap";
import TextField from "../../UI/TextField";
import { useForm } from "react-hook-form";
import { usePostCodeAddress } from "../../../Hooks/usePostCodeAddress";
import SelectBox from "../../UI/SelectBox";
import HiddenField from "../../UI/HiddenField";
import { Api } from "../../../api/Api";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { queryString } from "../../../Utility/QueryString";
import dataLayer from '../../Includes/DataLayer';

// console.log(queryString);
const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  getAddress1: [],
  getDetails1: [],
  addressPosition1: '',
  getAddress2: [],
  getDetails2: [],
  addressPosition2: '',
  addressKeyword1: '',
  addressKeyword2: '',
  addressKeyword3: '',
  getAddress3: [],
  getDetails3: [],
  addressPosition3: '',
  addressSec1Show: false,
  addressSec2Show: false,
  addressSec3Show: false,
  getAddrID1: '',
  getAddrID2: '',
  getAddrID3: '',
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validationSucess1": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        addressKeyword1: action.payload.address_keyword1,
        getAddress1: action.payload.get_address1,
        addressPosition1: action.payload.address_position1
      };
    }
    case 'getExtraAddressDetails1': {
      return {
        ...state,
        getDetails1: action.payload.get_details1,
        getAddrID1: action.payload.get_address_id_1,

      }
    }
    case "validationSucess2": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        addressKeyword2: action.payload.address_keyword2,
        getAddress2: action.payload.get_address2,
        addressPosition2: action.payload.address_position2
      };
    }
    case 'getExtraAddressDetails2': {
      return {
        ...state,
        getDetails2: action.payload.get_details2,
        getAddrID2: action.payload.get_address_id_2,

      }
    }
    case "validationSucess3": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        addressKeyword3: action.payload.address_keyword3,
        getAddress3: action.payload.get_address3,
        addressPosition3: action.payload.address_position3
      };
    }
    case 'getExtraAddressDetails3': {
      return {
        ...state,
        getDetails3: action.payload.get_details3,
        getAddrID3: action.payload.get_address_id_3,
      }
    }
    case "hideaddressall":
      {
        return {
          ...state,
          addressSec1Show: action.payload.address_section1_show,
          getAddress1: action.payload.get_address1,
          addressKeyword1: action.payload.address_keyword1,
          addressSec2Show: action.payload.address_section2_show,
          getAddress2: action.payload.get_address2,
          addressKeyword2: action.payload.address_keyword2,
          addressSec3Show: action.payload.address_section3_show,
          getAddress3: action.payload.get_address3,
          addressKeyword3: action.payload.address_keyword3,
          getAddrID1: action.payload.get_address_id_1,
          getAddrID2: action.payload.get_address_id_2,
          getAddrID3: action.payload.get_address_id_3,
        }
      }
    case "showaddressone":
      {
        return {
          ...state,
          addressSec1Show: action.payload.address_section1_show,
          getAddress1: action.payload.get_address1,
          addressKeyword1: action.payload.address_keyword1,
          addressSec2Show: action.payload.address_section2_show,
          getAddress2: action.payload.get_address2,
          addressKeyword2: action.payload.address_keyword2,
          addressSec3Show: action.payload.address_section3_show,
          getAddress3: action.payload.get_address3,
          addressKeyword3: action.payload.address_keyword3,
          getAddrID1: action.payload.get_address_id_1,
          getAddrID2: action.payload.get_address_id_2,
          getAddrID3: action.payload.get_address_id_3,
        }
      }
    case "hideaddressone":
      {
        return {
          ...state,
          addressSec1Show: action.payload.address_section1_show,
          getAddress1: action.payload.get_address1,
          addressKeyword1: action.payload.address_keyword1,
          getAddrID1: action.payload.get_address_id_1,
        }
      }
    case "showaddresstwo":
      {
        return {
          ...state,
          addressSec2Show: action.payload.address_section2_show,
          getAddress2: action.payload.get_address2,
          addressKeyword2: action.payload.address_keyword2,
          getAddrID2: action.payload.get_address_id_2,
        }
      }
    case "hideaddresstwo":
      {
        return {
          ...state,
          addressSec2Show: action.payload.address_section2_show,
          getAddress2: action.payload.get_address2,
          addressKeyword2: action.payload.address_keyword2,
          getAddrID2: action.payload.get_address_id_2,

        }
      }
    case "showaddressthree":
      {
        return {
          ...state,
          addressSec3Show: action.payload.address_section3_show,
          getAddress3: action.payload.get_address3,
          addressKeyword3: action.payload.address_keyword3,
          getAddrID3: action.payload.get_address_id_3,
        }
      }
    case "hideaddressthree":
      {
        return {
          ...state,
          addressSec3Show: action.payload.address_section3_show,
          getAddress3: action.payload.get_address3,
          addressKeyword3: action.payload.address_keyword3,
          getAddrID3: action.payload.get_address_id_3,
        }
      }

    default:
      return state;
  }
};

const Signature = ({ Sass }) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(false);
  const [firstAddrloader, setFirstAddrloader] = useState(false);
  const [secondAddrloader, setSecondAddrloader] = useState(false);
  const [thirdAddrloader, setThirdAddrloader] = useState(false);
  const [noAddrKeywordFirst, setNoAddrKeywordFirst] = useState(false);
  const [noAddrKeywordSecond, setNoAddrKeywordSecond] = useState(false);
  const [noAddrKeywordThird, setNoAddrKeywordThird] = useState(false);
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);

  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  // const query_string = localStorage.getItem("queryString");
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  //const jspara = '{"lstSalutation":"Mrs","txtFName":"TESTA","txtLName":"asd","lstDobDay":"18","lstDobMonth":"January","lstDobYear":"1984","txtPostCode":"me13us","address1":"31030238_0S_F","txtHouseName":"","txtHouseNumber":"1 Victoria Terrace","txtAddress3":"Nashenden Lane","txtCounty":"Kent","txtTown":"Rochester","txtCountry":"England","txtEmail":"test12r33@922.com","txtPhone":"01777777779","txtUdprn":"14829116","txtDeliveryPointSuffix":"1A","txtPz_mailsort":"67133","txtStreet":"","bankList":[11,8],"otherBankList":[],"page_name":"cci_claims/SK_V1"}';
  //console.log(jspara);
  //const formParameters = JSON.parse(jspara);
  //console.log(formParameters);
  //console.log(visitorParameters);
  //console.log(visitorParameters.data);

  const [signatureModalShow, setSignatureModalShow] = useState(false);
  const [privacyModalShow, setPrivacyModalShow] = useState(false);
  const [signaturePreviewModalShow, setSignaturePreviewModalShow] = useState(false);
  const [signaturePreviewImage, setSignaturePreviewImage] = useState('');
  
  const [isSignValid, setIsSignValid] = useState(false);

  // const [postcode1Show, setPostcode1Show] = useState(false);
  // const [postcode2Show, setPostcode2Show] = useState(false);
  // const [postcode3Show, setPostcode3Show] = useState(false);
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const initialSubmitClick = async (e) => {
    try {

      const signPad = signPadRef.current;

      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      } else {
        setIsSignValid(false);
        let signdata = signPad.getSignaturePad().toData();
        let signlength = 0;
        signdata.forEach(key => {
          signlength = signlength + key.length;
        });
        if (signlength > 13) {
          if (!isChecked) {
            return dispatch({ type: "privacyAgreed", payload: { value: true } });
          } else {
            if (
              visitorParameters.data !== null ||
              visitorParameters.data !== undefined
            ) {

              let width = signPad.getCanvas().width;
              let height = signPad.getCanvas().height;
              let canvasParameters = { 'width': width, 'height': height };

              setSignaturePreviewImage(signPad.getCanvas().toDataURL("image/png"));
              setSignaturePreviewModalShow(true);

            }
          }
        } else {
          setIsSignValid(true);
        }
      }

    } catch (e) {
      console.warn(e);
    }
  };

  const handleClick = async (e) => {
    console.log('loading '+ isLoading)
    try {
      const signPad = signPadRef.current;
      // if (signPad.isEmpty()) {
      //   return dispatch({ type: "signPadChange", payload: { value: true } });
      // }
      // if (!isChecked) {
      //   return dispatch({ type: "privacyAgreed", payload: { value: true } });
      // }

      const values = getValues();
      const previousAddressDts = values;
      const prevSurname = previousAddressDts.previousSurname;
      //console.log(visitorParameters);
      const previousAddressData = {}
      let prevAdrId1 = state.getAddrID1 ? state.getAddrID1 : '';
      let prevAdrId2 = state.getAddrID2 ? state.getAddrID2 : '';
      let prevAdrId3 = state.getAddrID3 ? state.getAddrID3 : '';


      previousAddressData.prevSurname = prevSurname;
      previousAddressData.PrevAddressKeyword_1 = state.addressKeyword1;
      previousAddressData.PrevAddressID_1 = prevAdrId1;
      previousAddressData.PrevAddressLine1_1 = prevAdrId1 == '' ? '' : state.getDetails1.address_line_1;
      previousAddressData.PrevAddressLine2_1 = prevAdrId1 == '' ? '' : state.getDetails1.address_line_2;
      previousAddressData.PrevSuburb_1 = prevAdrId1 == '' ? '' : state.getDetails1.suburb;
      previousAddressData.PrevState_1 = prevAdrId1 == '' ? '' : state.getDetails1.state;
      previousAddressData.PrevPostcode_1 = prevAdrId1 == '' ? '' : state.getDetails1.postcode;

      previousAddressData.PrevAddressKeyword_2 = state.addressKeyword2;
      previousAddressData.PrevAddressID_2 = prevAdrId2;
      previousAddressData.PrevAddressLine1_2 = prevAdrId2 == '' ? '' : state.getDetails2.address_line_1;
      previousAddressData.PrevAddressLine2_2 = prevAdrId2 == '' ? '' : state.getDetails2.address_line_2;
      previousAddressData.PrevSuburb_2 = prevAdrId2 == '' ? '' : state.getDetails2.suburb;
      previousAddressData.PrevState_2 = prevAdrId2 == '' ? '' : state.getDetails2.state;
      previousAddressData.PrevPostcode_2 = prevAdrId2 == '' ? '' : state.getDetails2.postcode;


      previousAddressData.PrevAddressKeyword_3 = state.addressKeyword3;
      previousAddressData.PrevAddressID_3 = prevAdrId3;
      previousAddressData.PrevAddressLine1_3 = prevAdrId3 == '' ? '' : state.getDetails3.address_line_1;
      previousAddressData.PrevAddressLine2_3 = prevAdrId3 == '' ? '' : state.getDetails3.address_line_2;
      previousAddressData.PrevSuburb_3 = prevAdrId3 == '' ? '' : state.getDetails3.suburb;
      previousAddressData.PrevState_3 = prevAdrId3 == '' ? '' : state.getDetails3.state;
      previousAddressData.PrevPostcode_3 = prevAdrId3 == '' ? '' : state.getDetails3.postcode;

      //  let adrkeywordone = state.addressKeyword1;
      //  let adrkeywordtwo = state.addressKeyword2;
      //  let adrkeywordthree = state.addressKeyword3;

      // //  let bankIds = bankSelectedValue.map((a) => a.id);
      // //  let otherBankIds = otherBankSelectedList.map((a) => a.id);
      //  formParameters.addresskeyword = adrkeywordone;


      // const values = getValues();
      // const formData = values;

      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      } else {
        setIsSignValid(false);
        let signdata = signPad.getSignaturePad().toData();
        let signlength = 0;
        signdata.forEach(key => {
          signlength = signlength + key.length;
        });
        if (signlength > 13) {
          if (!isChecked) {
            return dispatch({ type: "privacyAgreed", payload: { value: true } });
          } else {
            if (
              visitorParameters.data !== null ||
              visitorParameters.data !== undefined
            ) {

              // (async () => {
              //   const response = await Api.get("/api/v1/frankie-one/entity?uuid=" + visitorParameters.visitor_parameters.uuid, {});

              //   const details = response.data.response;
              //   console.log(response.data);

              //   if (response.data.status === "Success") {
              //     // set local torage
              //     localStorage.setItem('fo_entity_id', response.data.response);
              //   }

              // })();

              dataLayer({
                'question': 'signature',
                'answer': 'Completed'
              });

              let width = signPad.getCanvas().width;
              let height = signPad.getCanvas().height;
              let canvasParameters = { 'width': width, 'height': height };

              const response = await saveSignature(
                signPad.getCanvas().toDataURL("image/png"),
                visitorParameters,
                previousAddressData,
                formParameters,
                queryString,
                canvasParameters,
              );

              //localStorage.clear();
              if (response.data.status === "Success") {
                //history.push('/thankyou');
                //history.push('/user_document');
                // history.push(
                //   "/user_verification?uuid=" + visitorParameters.visitor_parameters.uuid
                // );

                history.push(
                  "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
                );

              }
            }
          }
        } else {
          setIsSignValid(true);
        }
      }

      // if (
      //   visitorParameters.data !== null ||
      //   visitorParameters.data !== undefined
      // ) {        
      //   const response = await saveSignature(
      //     signPad.getCanvas().toDataURL("image/png"),
      //     visitorParameters,
      //     previousAddressData,
      //     formParameters,
      //     query_string
      //   );

      //   //localStorage.clear();
      //   if (response.data.status === "Success") {
      //     //history.push('/thankyou');
      //     //history.push('/user_document');
      //     history.push(
      //       "/user_document?uuid=" + visitorParameters.visitor_parameters.uuid
      //     );
      //   }
      // }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
    setIsSignValid(false);
    setSignaturePreviewModalShow(false);
    setSignaturePreviewImage('');
  };




  const checkValidation = async (prvAdrPos, e) => {
    e.preventDefault();

    // const txtSearchKey = await trigger("txtSearchKey");
    const addressKeyword = e.target.value;
    if (addressKeyword) {

      if (prvAdrPos == 'first') {
        setFirstAddrloader(true)
        setNoAddrKeywordFirst(false)
      }
      else if (prvAdrPos == 'second') {
        setSecondAddrloader(true)
        setNoAddrKeywordSecond(false)
      }
      else if (prvAdrPos == 'third') {
        setThirdAddrloader(true)
        setNoAddrKeywordThird(false)
      }

      // const getValidation = await getPostcodeValidation(e.target.value)
      // if (getValidation.data === 0) {
      //   setError("txtPostCode", {
      //     type: "manual",
      //     message: "Please Enter Valid Postcode",
      //   });
      // }
      // if (getValidation.data === 1) {
      const addressList = await getPostcodeAddress(e.target.value);

      // live case //
      if (addressList.data.status && addressList.data.status == '0') {



        if (prvAdrPos == 'first') {
          setFirstAddrloader(false)
          setNoAddrKeywordFirst(true)
        }
        else if (prvAdrPos == 'second') {
          setSecondAddrloader(false)
          setNoAddrKeywordSecond(true)
        }
        else if (prvAdrPos == 'third') {
          setThirdAddrloader(false)
          setNoAddrKeywordThird(true)
        }

        // test case
        // if (1 === 2) {
        // setError("txtSearchKey", {
        //   type: "manual",
        //   message: "Please Enter Different Keyword",
        // });
      }
      else {
        // console.log(addressList)

        if (prvAdrPos == 'first') {

          dispatch({
            type: "validationSucess1",
            payload: {
              lookup: false,
              address_position1: prvAdrPos,
              address_keyword1: e.target.value,
              // get_address1: addressList,               // test case //
              get_address1: addressList.data,      // live case //
            },
          });
          setFirstAddrloader(false)
          setNoAddrKeywordFirst(false)
        } else if (prvAdrPos == 'second') {
          dispatch({
            type: "validationSucess2",
            payload: {
              lookup: false,
              address_position2: prvAdrPos,
              address_keyword2: e.target.value,
              // get_address2: addressList,               // test case //
              get_address2: addressList.data,      // live case //
            },
          });
          setSecondAddrloader(false)
          setNoAddrKeywordSecond(false)
        }
        else if (prvAdrPos == 'third') {
          dispatch({
            type: "validationSucess3",
            payload: {
              lookup: false,
              address_position3: prvAdrPos,
              address_keyword3: e.target.value,
              // get_address3: addressList,               // test case //
              get_address3: addressList.data,      // live case //
            },
          });
          setThirdAddrloader(false)
          setNoAddrKeywordThird(false)
        }



      }
    }
    // }
  };


  const getValue = async (prvAdrPosD, e) => {
    e.preventDefault();
    const prev_addr_id = e.target.value;
    // clearErrors(["txtAddressLine1","txtAddressLine2","txtSuburb","txtState","txtPostCode"]);
    const getData = await getSplitPostcodeAddress(e.target.value)
    // dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show" } });   

    if (prvAdrPosD == 'first') {
      // dispatch({ type: 'getExtraAddressDetails1', payload: { get_details1: getData,get_address_id_1:prev_addr_id } });  // test case
      dispatch({ type: 'getExtraAddressDetails1', payload: { get_details1: getData.data, get_address_id_1: prev_addr_id } }); // live case
    }
    else if (prvAdrPosD == 'second') {
      // dispatch({ type: 'getExtraAddressDetails2', payload: { get_details2: getData,get_address_id_2:prev_addr_id } });  // test case
      dispatch({ type: 'getExtraAddressDetails2', payload: { get_details2: getData.data, get_address_id_2: prev_addr_id } }); // live case 
    }
    else if (prvAdrPosD == 'third') {
      // dispatch({ type: 'getExtraAddressDetails3', payload: { get_details3: getData ,get_address_id_3:prev_addr_id } });  // test case
      dispatch({ type: 'getExtraAddressDetails3', payload: { get_details3: getData.data, get_address_id_3: prev_addr_id } });  // live case
    }
  };



  const previousDetailsValidation = async (e) => {
    let errorFlag = 0;
    var previousSurnameResult;
    previousSurnameResult = await trigger("previousSurname");
    if (!previousSurnameResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      setSignatureModalShow(true)
    } else {
      return false;
    }
  };



  return (
      <Fragment>
      <GetVisitorsParams />
      <div className={SignStyle['body']} >
      <section className={SignStyle['reclaimer_sign']} >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 p-0">
              <div className={SignStyle['signature-part']}>
                <div className="col-12 text-center">
                  <img src="assets/Signature/img/ft-logo.png" className={SignStyle['sign-logo']} alt="" />
                  <h1>Hi, {formParameters.txtFName} {formParameters.txtLName}</h1>
                </div>

                <div className={`${SignStyle["modal-content"]} ${SignStyle["sign-popstyle"]} modal-content`}>
                  <div className={`${SignStyle["modal-header"]} modal-header`} >
                    <h2 className={`${SignStyle["h2_style"]} h2_style`}>Finally, we need your electronic signature below to proceed. Please note that your electronic signature will be included on all forms we submit to your lender</h2>
                  </div>

                  <div className={SignStyle['ensure_li']}>
                    <div className={`${SignStyle["sign-question"]} ${SignStyle["nopadmob"]} offset-lg-3 col-lg-6 col-12 `}>
                      <ul className={SignStyle['signature-ul']}>
                        <li> Sign using your finger, mouse or stylus</li>
                        <li> Keep it fully contained within the box</li>
                        <li> Ensure it is a true likeness of your signature</li>
                      </ul>
                    </div>
                    <div className={SignStyle['signature_canvaseWrap']}>

                      <div className="">
                        <div className={SignStyle['signature-pad']} ref={signatureWrapRef}>
                            <SignatureCanvas
                              canvasProps={{
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                                className: `${SignStyle["sigCanvas"]} anim_bt d-lg-block`,
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                        </div>
                      </div>
                      <div className="c">
                        <span className="text-danger" id="signatures_required" style={{ display: "none" }} >Signatures are required!</span>

                        {/*  */}
                        {state.isCanvasEmpty && (
                          <span id="signatures_required" className="error_msg">
                            Signature is required!
                          </span>
                        )}
                        {isSignValid && (
                          <span id="signatures_required" className="error_msg">
                            Draw valid signature
                          </span>
                        )}

                      </div>
                    </div>
                  </div>

                  <div className={`${SignStyle["bord"]} text-left bord`} id="acceptterms_outer">


                    <div className="form-check">

                      <CheckBox
                        dataId="acceptterms"
                        anchorText="Terms of Business"
                        anchorStyle=""
                        href=""
                        onChange={(e) => setIsChecked(!isChecked)}
                        className={`form-check-input ${SignStyle["form-check-input"]}`}
                        labelClassName="form-check-label"
                        value={isChecked}
                      />
                      <label
                        className={`form-check-label ${SignStyle["form-check-label"]}`}
                        htmlFor="acceptterms"
                      >
                        {/* By signing and pressing “Submit” below, you confirm you have read, understood, and agree to Reclaimer Australia Pty Ltd’s <a href="/assets/Terms_and_Conditions_v1.1-Reclaimer.pdf" target="_blank">Terms and Conditions</a> and <a style={{ cursor: 'pointer', color: '#00aab1 !important' }} href="/assets/Reclaimer_LOA_v1.1.pdf" target="_blank">Letter of Engagement</a>. Reclaimer will apply your electronic signature to those documents and the <a style={{ cursor: 'pointer', color: '#00aab1 !important' }} href="/assets/Reclaimer_LOA_v1.1.pdf" target="_blank">Letter of Authority</a> to confirm your acceptance. Reclaimer will send you a copy of the <a href="/assets/Terms_and_Conditions_v1.1-Reclaimer.pdf" target="_blank">Terms and Conditions</a> and your signed documents via email. */}
                        By signing and pressing “Submit” below, you confirm you have read, understood, and agree to Reclaimer Australia Pty Ltd’s <a href="/assets/Terms_and_Conditions_v1.2-Reclaimer.pdf" target="_blank">Terms and Conditions</a> and <a href="/assets/Reclaimer_LOE_v1.1.pdf" target="_blank">Letter of Engagement</a>. Reclaimer will apply your electronic signature to those documents and the <a style={{ cursor: 'pointer', color: '#00aab1 !important' }} href="/assets/Reclaimer_LOA_v1.1.pdf" target="_blank">Letter of Authority</a> to confirm your acceptance. Reclaimer will send you a copy of the <a href="/assets/Terms_and_Conditions_v1.2-Reclaimer.pdf" target="_blank">Terms and Conditions</a> and your signed documents via email.
                      </label>
                    </div>

                    {state.isAgreed && (
                      <div className="col-12 text-center">
                        <span className="error_msg">
                          Please agree to Reclaimer’s Terms and Conditions
                        </span>
                      </div>
                    )}


                  </div>

                </div>
                <div className={`modal-footer ${SignStyle["modal-footer"]}`} style={{ display: 'block' }} >
                  <Button
                    type="button"
                    className={`btn-clear ${SignStyle["btn-clear"]}`}
                    id="clear"
                    onClick={clearSign}
                    buttonText="Clear"
                  />
                  <Button
                    type="button"
                    className={`btn-submit next08 ${SignStyle["btn-submit"]}`}
                    id="save"
                    onClick={initialSubmitClick}
                    buttonText="Submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PrivacyPopup
        show={privacyModalShow}
        onHide={() => setPrivacyModalShow(false)}
        Sass={SignStyle}
      />

      <SignaturePreviewPopup
        show={signaturePreviewModalShow}
        onHide={() => setSignaturePreviewModalShow(false)}
        Sass={SignStyle}
        clearAction={clearSign}
        submitAction={handleClick}
        signaturePreviewImage={signaturePreviewImage}
        isLoading={isLoading}
      />
  
      {/* <div className={`${SignStyle["sign-question"]} offset-lg-2 col-lg-8 col-12 `} */}
      </div>
    </Fragment>
  );
};

export default Signature;
