import {Api} from '../api/Api';

export const useGetUUID = () => {
    const getUserUUID = async (uuid) => {
         const response = await Api.post("api/v1/get-uuid", {
            uuid
        });
        return response;
    }
    return { getUserUUID}
}