import { React,useEffect,useReducer, useState } from "react";
import TextField from "../Components/UI/TextField";
import Anchor from "../Components/UI/Anchor";
import SelectBox from "../Components/UI/SelectBox";
import HiddenField from "../Components/UI/HiddenField";
import InputButton from "../Components/UI/InputButton";
import PostcodeTextField from "../Components/UI/PostcodeTextField";
import { usePostCodeAddress } from "../Hooks/usePostCodeAddress";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: [],
  clickManualLink: 0,
  searchKeyval : []
}
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case 'validationSucess': {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
        searchKeyval:action.payload.search_key_value,
      }
    }
    case 'getExtraAddressDetails': {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address
      }
    }
    case 'showManualDiv': {
      return {
        ...state,
        showManualAddress: "show",
        lookUp:false,
        nextClick:true,
        checkSelect:false,
        clickManualLink: action.payload.manual_click
      }
    }
    default:
      return state;
  }
}
const Postcode_Pre = ({
  validation,
  validationMsg,
  onBackClick,
  clearErrors,
  setError,
  slideChange,
  adjustClass,
  trigger,
  reset,
  preaddressfetch,
  getValues,
  Sass
}) => {
  // const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [addresshistory,setAddresshistory] = useState([]);
  const [selectaddresslist, setSelectaddresslist] = useState([]);
  const [defaultValuepostcode , setDefaultValuepostcode] = useState('');
  const [addrrcounter, setAddrrcounter] = useState(0);
  const [hidepoststyle, setHidepostcodestyle] = useState('custom-addr-show');
  const [postcode_placeholder, setPostcode_placeholder] = useState('Your Postcode')
  const [getaddressid, setGetaddressid]  = useState('');
  const [manuvalstatus ,setManuvalstatus] = useState(0);
  const [tempmanuvalstatus ,setTempmanuvalstatus] = useState(0);
  const [lin1value, setLine1value]  = useState('');
  const [txttownvalue, setTxttownvalue] = useState('');
  const [searchKeywordValue, setSearchKeywordValue]  = useState([]);
  // const checkValidation = async (e) => {
  //   e.preventDefault();
  //   const txtPostCode1 = await trigger("txtPostCode1");
  //   if(txtPostCode1)
  //   {
  //     const getValidation = await getPostcodeValidation(e.target.value)
  //     if (getValidation.data === 0) {
  //       setError("txtPostCode1", {
  //         type: "manual",
  //         message: "Please Enter Valid Postcode",
  //       });
  //     }
  //     if (getValidation.data === 1) {
  //       const addressList = await getPostcodeAddress(e.target.value)
  //       if (addressList) {
  //         dispatch({
  //           type: 'validationSucess',
  //           payload: {
  //             lookup: false,
  //             next_click: true,
  //             postcode: e.target.value,
  //             get_address: addressList.data,
  //             check_select: true,
  //             manual_address: "hide",
  //           }
  //         });
  //       }
  //     }
  //   }
  // };

  const checkValidation = async (e) => {
 
    e.preventDefault();
    const txtSearchKey = await trigger("txtSearchKeyPrev");
    if (txtSearchKey) {
        const addressList = await getPostcodeAddress(e.target.value)
    
          // live case //  
          if(addressList.data.status && addressList.data.status == '0') {
          // test case
          // if(1 === 2) {
          setError("txtSearchKeyPrev", {
                type: "manual",
                message: "Please Enter Different Keyword",
              });
            }

        else {
          dispatch({
            type: 'validationSucess',
            payload: {
              lookup: false,
              next_click: true,
              postcode: e.target.value,
              get_address: addressList.data,      // live case //
              check_select: true,
              manual_address: "hide",
            }
          });
        }
      // }
    }
  };



 useEffect(()=>{
    if (Object.keys(state.getDetails).length != 0) {
      state.getDetails.address1 = getaddressid;
        setAddresshistory(state.getDetails);
        setGetaddressid('');
        setTxttownvalue(state.getDetails.Town);
      setLine1value(state.getDetails.Line1);
    }
 },[state.getDetails]);


//  useEffect(()=>{
//     if (Object.keys(addresshistory).length != 0) {
//     setSelectaddresslist([...selectaddresslist, addresshistory]);
//     }
//  },[addresshistory]);


 useEffect(()=>{
    if (Object.keys(selectaddresslist).length != 0) {
      preaddressfetch(selectaddresslist);
    }
 },[selectaddresslist])


  useEffect(()=>{
    if(addrrcounter >= 1){
      setPostcode_placeholder('Please enter another address keyword(if any)')
    }else{
      setPostcode_placeholder('Enter Address Keyword')
    }
    if(addrrcounter== 5){
      setHidepostcodestyle('hide');
    }else{
      setHidepostcodestyle('custom-addr-show');
      // hidepoststyle
    }
  },[addrrcounter]);


 const Addtopre = async (e)=>{
    // Addresscomparefn();
    console.log(addresshistory);
    setSelectaddresslist([...selectaddresslist, addresshistory]);
    setAddrrcounter(addrrcounter + 1);
    document.getElementById('txtSearchKeyPrev').value = '';
    // document.getElementById('txtHouseNumber1').value = '';
    // document.getElementById('txtTown1').value = '';
    {dispatch({
        type: 'validationSucess',
        payload: {
            lookup: true,
            next_click: false,
            check_select: false,
            manual_address: "hide",
            get_address: [],
            postcode: '',
            search_key_value: [],
        }
        })}
        // reset("txtPostCode1");
        // setManuvalstatus(0);
        setAddresshistory([]);
        clearErrors(["txtSearchKeyPrev","txtAddressPrev", "txtAddressLine1Prev", "txtAddressLine2Prev", "txtSuburbPrev", "txtStatePrev","txtPostCodePrev"]);

 }

 const removeAddressStore =(e,id)=>{
  e.preventDefault();
   setSelectaddresslist(selectaddresslist =>(selectaddresslist.filter((item, itemIndex) => itemIndex != id)));
   setAddrrcounter(addrrcounter - 1);
}
 //############## check the address manuval or not #################
//  const Addresscomparefn =()=>{
   
//   // if(Object.entries(addressdata).length === 0){
//   //   setManuvalstatus(1);
//   // }else{
//       // code
//       console.log("address Data");
//       const adr_api_Values = [];
//        adr_api_Values.push(addresshistory.Line1,addresshistory.Town,addresshistory.Postcode);
//       const lower_adr_apivalues = adr_api_Values.map(element => {
//         return element.toLowerCase();
//       });
//     //   lower_adr_apivalues.splice(2, 1)
//       lower_adr_apivalues[2] = lower_adr_apivalues[2].replace(/\s+/g, '');
//       console.log(lower_adr_apivalues);
//       const values = getValues();
//       const user_addr_arry = [];
//       let formatpostcode  =  values['txtPostCode1'].replace(/\s+/g, '');
//     //   // formatpostcode =  formatpostcode.substring(0, 4) + " " + formatpostcode.substring(4, formatpostcode.length);

//       user_addr_arry.push(values['txtHouseNumber1'],values['txtTown1'],formatpostcode);
//       const lower_user_addr_arry = user_addr_arry.map(element => { return element.toLowerCase().trim();  });
//       console.log(lower_user_addr_arry);

//     // //  compare check here
//     let is_same_check = lower_adr_apivalues.length == lower_user_addr_arry.length && lower_adr_apivalues.every(function(element, index) {
//       return element === lower_user_addr_arry[index]; 
//      });
//      console.log("manuva check:"+is_same_check);
//     if(is_same_check){
//       // setManuvalstatus(0);
//       addresshistory.is_mauval = 0;
//     }else{
//       addresshistory.is_mauval = 1;
//       // setManuvalstatus(1);
//     }
//     addresshistory.Line1 = values['txtHouseNumber1'];
//     addresshistory.Town  = values['txtTown1'];
//       // code end
//   // }
// }

 // ####### close Address Check      #########################

 const selectAddressArr = selectaddresslist.map((address, index) => {
    return (
        <div className={`addressbox ${Sass.prev_addr_bx}`} id="add_adrs" >
        <h6>Previous addresses:</h6>
        <p>{address.address_line_1}, {address.suburb}, {address.state}, {address.postcode}</p>
        <a href="#" className={`hideadrbox ${Sass.hide_srh_bx_a}`}>
           <p className={`text-end ${Sass.remove_adr_btn}`} onClick={(e) => removeAddressStore(e,index)}><span><img src="/assets/SK_V1/img/remove.svg" alt=""/></span> Remove</p>
        </a>
     </div>
    );
  });

  const getValue = async (e) => {
    e.preventDefault();
    setGetaddressid(e.target.value)
    // clearErrors(["txtHouseNumber1", "txtAddress31", "txtCounty1", "txtTown1", "txtCountry1"]);
    clearErrors(["txtAddressLine1","txtAddressLine2","txtSuburb","txtState","txtPostCode"]);

    const getData = await getSplitPostcodeAddress(e.target.value)
    dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show" } });
  };

  const lookupValidation = async (e) => {
    await trigger("txtSearchKeyPrev");
  }
  const  clearValidationMsg = async(e)=>{
    clearErrors(["txtSearchKeyPrev"]);
  }
  // const addressValidation = async (e) => {
  //   let errorFlag = 0;
  //   const txtPostCode1 = await trigger("txtPostCode1");
  //   const manual_txtHouseNumber1 = await trigger("txtHouseNumber1");
  //   var manual_txtTown1;
  //   var manual_txtCountry1;
  //   if (manual_txtHouseNumber1) {
  //     manual_txtTown1 = await trigger("txtTown1");
  //   }
  //   else {
  //     return false;
  //   }
  //   if (manual_txtTown1) {
  //     manual_txtCountry1 = await trigger("txtCountry1");
  //   }
  //   else {
  //     return false;
  //   }
  //   if (
  //     !txtPostCode1 ||
  //     !manual_txtHouseNumber1 ||
  //     !manual_txtTown1 ||
  //     !manual_txtCountry1
  //   ) {
  //     errorFlag = 1;
  //   }

  //   if (state.clickManualLink === 0) {
  //     const txtAddressPrev = await trigger("txtAddressPrev");
  //     if (!txtAddressPrev) {
  //       errorFlag = 1;
  //     }
  //   }
  //   if (errorFlag === 0) {
  //     slideChange(e);
  //   } else {
  //     return false;
  //   }
  // };
  // const HiddenFields = () => (
  //   <>
  //     <HiddenField
  //       name="txtUdprn1"
  //       inputId="txtUdprn1"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
  //     />
  //     <HiddenField
  //       name="txtDeliveryPointSuffix1"
  //       inputId="txtDeliveryPointSuffix1"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""}
  //     />
  //     <HiddenField
  //       name="txtPz_mailsort1"
  //       inputId="txtPz_mailsort1"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""}
  //     />
  //     <HiddenField
  //       name="txtStreet1"
  //       inputId="txtStreet1"
  //       inputValue={state.getDetails.Line3 !== "#" ? state.getDetails.Line3 : ""}
  //     />
  //   </>
  // );
  return (
    <>
     
       {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 previousslide mob-0">
        <div className="form-group"> */}
      <fieldset className={`btn ${Sass.prev_adr_outer}`}>
     
        {/* {searchKeywordValue} */}
              <legend className={`float-none w-auto px-3`}> Enter your previous address</legend>
              <p className={`${Sass.prev_blank_p}`}><i>(Leave blank if not applicable.)</i></p>

                   {/* show selected Address */}
                   {/* <div className="addressbox" id="add_adrs" >
                                          <h6>Previous addresses:</h6>
                                          <p>3 Victoria Terrace, Nashenden Lane ME1 3US</p>
                                          <a href="#" className="hideadrbox">
                                             <p className="text-end"><span><img src="dist/img/remove.svg" alt=""/></span> Remove</p>
                                          </a>
                                       </div> */}
                         {selectAddressArr}              
                   {/* Close the select Address */}
                  {/* start post code here */}
                  <div className={`row ${hidepoststyle}`} id="firstpost">
                      <div className={`col-lg-7 col-md-7 col-sm-7 col-12 form-group ${adjustClass ? "adj_width" : ""}`}>
                        <TextField
                            type="text"
                            placeholder={postcode_placeholder}
                            name="txtSearchKeyPrev"
                            id="txtSearchKeyPrev"
                            className="form-control  hero-input"
                            dataId="txtSearchKeyPrev"
                            onBlur={checkValidation}
                            onChange={(e)=>{dispatch({
                            type: 'validationSucess',
                            payload: {
                                lookup: true,
                                next_click: false,
                                check_select: false,
                                manual_address: "hide",
                                get_address: [],
                                search_key_value:e.target.value
                            }
                            })}}
                            autoComplete="off"
                            // currentvalue={defaultValuepostcode}
                            validation={validation({
                              required: "Please Enter Address Keyword",
                            })}
                            validationMsg={
                            validationMsg.txtSearchKeyPrev && validationMsg.txtSearchKeyPrev.message
                            }
                        />
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-5 col-12">
            <InputButton
              style={{ float: "none" }}
              name="next"
              className={`btn lookp-btn slide8_lookupbtn1 ${Sass.lookup_val_btn}`}
                                value="Search Address"
                                btnType="button"
                                onClick={lookupValidation}
                            />
                        </div>
                      </div>




                        <div
                        className="col-lg-12 col-12  text-right slide8_lkup"
                        style={state.lookUp ? { display: "block" } : { display: "none" }}
                        onClick={onBackClick}
                        >
                        <div style={{ width: "100%" }} className="main_1 text-right">
                           
                            {/* <InputButton
                            style={{ float: "none" }}
                            name="next"
                            className="btn center-block getstart_but applynow02 sctop efct slide8_lookupbtn"
                            value="Lookup Address"
                            btnType="button"
                            onClick={lookupValidation}
                            /> */}
                        </div>
                        </div>
                        <div
                        className={`form-group slide8_selbx ${adjustClass ? "adj_width" : ""}`}
                        id="currentAddressCollapse"
                        style={state.checkSelect ? { display: "block" } : { display: "none" }}
                        >
          <label className="control-label white-label">Select Address</label>
                        <SelectBox
                            className="form-control efct"
                            OptionValue={state.getAddress}
                            name="txtAddressPrev"
                            onChange={getValue}
                            myRef={validation({ required: "Please select address" })}
                            validationMsg={
                            validationMsg.txtAddressPrev && validationMsg.txtAddressPrev.message
                            }
                        />
                        </div>
                        <div
                        className="form-group text-center"
                        style={
                            state.getDetails.length === 0 ? { display: "block" } : { display: "none" }
                        }
                        >
                        {/* <Anchor
                            className="manual-entry-request"
                            idName="manual-entry-btn"
                            onClick={() => { dispatch({ type: 'showManualDiv', payload: { manual_address: "show", manual_click: 1 } }) }}
                            children="[ Having Issues? Click Here to Enter Manually ]"
                        ></Anchor> */}
                        </div>
                        <div className={`slide8_add ${Sass[state.showManualAddress]}`}>

                <div className="form-group required-field">
                <TextField
                type="text"
                placeholder="Address Line 1 *"
                name="txtAddressLine1Prev"
                onBlur={clearValidationMsg}
                className="form-control  hero-input"
                dataId="txtAddressLine1Prev"
                autoComplete="off"
                validation={validation({required: "Please Enter Address Line 1",
                })}
                validationMsg={
                  validationMsg.txtAddressLine1Prev &&
                  validationMsg.txtAddressLine1Prev.message
                }
                value={state.getDetails.address_line_1}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
            
            <div className="form-group">
              <TextField
                type="text"
                placeholder="Address Line 2"
                name="txtAddressLine2Prev"
                className="form-control hero-input"
                dataId="txtAddressLine2Prev"
                autoComplete="off"
                validation={validation()}
                validationMsg=""
                value={state.getDetails.address_line_2}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className="form-group">
              <TextField
                type="text"
                placeholder="txtSuburbPrev"
                name="txtSuburbPrev"
                className="form-control hero-input"
                dataId="txtSuburbPrev"
                autoComplete="off"
                validation={validation()}
                validationMsg=""
                value={state.getDetails.suburb}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className="form-group">
              <TextField
                type="text"
                placeholder="State *"
                name="txtStatePrev"
                className="form-control hero-input"
                dataId="txtStatePrev"
                autoComplete="off"
                validation={validation({ required: "Please Enter State" })}
                validationMsg={
                  validationMsg.txtStatePrev && validationMsg.txtStatePrev.message
                }
                value={state.getDetails.state}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
            <div className="form-group">
              <TextField
                type="text"
                placeholder="Pincode *"
                name="txtPostCodePrev" 
                className="form-control  hero-input"
                dataId="txtPostCodePrev"
                autoComplete="off"
                validation={validation({ required: "Please Enter Postcode" })}
                validationMsg={
                  validationMsg.txtPostCodePrev && validationMsg.txtPostCodePrev.message
                }
                value={state.getDetails.postcode}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
            

 {/* upto here */}

          


                        {/* <div style={{display:'none'}} className={`col-lg-12 form-group ${adjustClass ? "adj_width" : ""}`}>
                            <TextField
                            type="text"
                            placeholder="Company"
                            readonly="readonly"
                            name="txtHouseName1"
                            className="form-control"
                            dataId="txtHouseName1"
                            autoComplete="off"
                            validation={validation()}
                            validationMsg={
                                validationMsg.txtHouseName1 && validationMsg.txtHouseName1.message
                            }
                            value={state.getDetails.Company}
                            />
                            <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                            ></i>
                            <span id="addr_err" className="error_msg"></span>
                        </div>
                        <div style={{display: lin1value ? "block" : "none"}} className={`col-lg-12 form-group required-field  ${adjustClass ? "adj_width" : ""}`}>
                            <PostcodeTextField
                            type="text"
                            placeholder="Address Line 1 *"
                            readonly="readonly"
                            name="txtHouseNumber1"
                            id="txtHouseNumber1"
                            className="form-control  hero-input"
                            dataId="txtHouseNumber1"
                            autoComplete="off"
                            validation={validation({
                                required: "Please Enter Address Line 1",
                            })}
                            validationMsg={
                                validationMsg.txtHouseNumber1 &&
                                validationMsg.txtHouseNumber1.message
                            }
                            onChange={e => setLine1value(e.target.value)}
                            value={lin1value}
                            />
                            <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                            ></i>
                        </div>

                        <div style={{display:'none'}} className={`col-lg-12 form-group  ${adjustClass ? "adj_width" : ""}`}>
                            <TextField
                            type="text"
                            placeholder="Address Line 2"
                            readonly="readonly"
                            name="txtAddress31"
                            className="form-control  hero-input"
                            dataId="txtAddress31"
                            autoComplete="off"
                            validation={validation()}
                            validationMsg=""
                            value={state.getDetails.Line2}
                            />
                            <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                            ></i>
                        </div>

                        <div style={{display:'none'}} className={`col-lg-12 form-group  ${adjustClass ? "adj_width" : ""}`}>
                            <TextField
                            type="text"
                            placeholder="County"
                            readonly="readonly"
                            name="txtCounty1"
                            className="form-control  hero-input"
                            dataId="txtCounty1"
                            autoComplete="off"
                            validation={validation()}
                            validationMsg=""
                            value={state.getDetails.County}
                            />
                            <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                            ></i>
                        </div>

                        <div style={{display: txttownvalue ? "block" : "none"}} className={`col-lg-12 form-group  ${adjustClass ? "adj_width" : ""}`}>
                            <PostcodeTextField
                            type="text"
                            placeholder="Town *"
                            readonly="readonly"
                            name="txtTown1"
                            className="form-control  hero-input"
                            dataId="txtTown1"
                            onChange={e => setTxttownvalue(e.target.value)}
                            id="txtTown1"
                            value = {txttownvalue}
                            autoComplete="off"
                            validation={validation({ required: "Please Enter Town" })}
                            validationMsg={
                                validationMsg.txtTown1 && validationMsg.txtTown1.message
                            }
                            // value={state.getDetails.Town}
                            />
                            <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                            ></i>
                        </div>
                        <div style={{display:'none'}} className={`col-lg-12 form-group ${adjustClass ? "adj_width" : ""}`}>
                            <TextField
                            type="text"
                            placeholder="Country *"
                            readonly="readonly"
                            name="txtCountry1"
                            className="form-control  hero-input"
                            dataId="txtCountry1"
                            autoComplete="off"
                            validation={validation({ required: "Please Enter Country" })}
                            validationMsg={
                                validationMsg.txtCountry1 && validationMsg.txtCountry1.message
                            }
                            value={state.getDetails.Country}
                            />
                            <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                            ></i>
                        </div> */}
          <div className={`form-group text-center clearfix ${Sass.prev_save_btn}`}>
                          <input name="next" className="btn center-block save-btn applynow02 sctop saveaddr2 savecustom-btn" onClick={Addtopre} value="Save" type="button"/>
                        </div>
                        
                        {/* {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null} */}
                        </div>

                        <div
                        className="col-lg-12 col-12  text-right slide8_lkup"
                        style={state.nextClick ? { display: "block" } : { display: "none" }}
                        >
                            {/* <div style={{ width: "100%" }} className="main_1 text-right">
                                <InputButton
                                name="backslide3"
                                className="btn backbt slide8_bck"
                                id=""
                                value="< Back"
                                btnType="button"
                                onClick={onBackClick}
                                />
                                <InputButton
                                name="slide3"
                                className="btn center-block getstart_but applynow02 sctop efct slide8_lookupbtn"
                                id=""
                                value="Next"
                                btnType="button"
                                onClick={addressValidation}
                                style={{ float: "none" }}
                                />
                            </div> */}
                        </div>

                        {/* <div className="col-lg-12 col-md-12 col-xs-12 frm_lst">
                        <h6>PERSONAL INFORMATION GUARANTEE</h6>
                        <p>We do not cold call, spam or pass on your data for marketing</p>
                        </div> */}







                   {/* close Post code here */}
           </fieldset>
        {/* </div>
       </div> */}



     
    </>
  );
};
export default Postcode_Pre;
