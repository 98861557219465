import React, { Fragment, useContext, useEffect } from "react";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import Sass from "../../../assets/SK_V2/scss/SK_V2.module.scss";
import { SK_V2Content } from "../../../Constants/SK_V2Content";
import StaticSection from "../../Includes/Splits/SK_V2/StaticSection";
import FormSection from "../../Includes/Splits/SK_V2/FormSection";
import Footer from "../../Includes/Layouts/Common/Footer";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import PageLoadApi from "../../../Utility/PageLoadApi";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";

const SK_V2 = () => {
   // const { visitorParameters } = useContext(VisitorParamsContext);
   // const { queryString } = useContext(QueryStringContext);
   // const { saveDataIngestion, isLoading } = useDataIngestion();
   // useEffect(() => {
   //    (async () => {
   //       await saveDataIngestion(
   //          visitorParameters.visitor_parameters,
   //          visitorParameters.data,
   //          'split_page_load',
   //          'cci_claims/SK_V2',
   //          queryString
   //       )
   //    })();
   // }, [visitorParameters]);

   return (
      <Fragment>
      <AdtopiaLoadLP pageType="lp" splitName="cci_claims/SK_V2" />
      {/* <PageLoadApi pageType="LP" splitName="cci_claims/SK_V2"  /> */}
         <section className={Sass['top_header']}>
            <div className="container">
               <div className="col-lg-12 col-12 text-center">
                  <h2>Australian Banks have Set Aside over $9 BILLION to Repay their Customers*</h2>
                  <p>Millions of Consumers Impacted** - Find Out What You're Owed</p>
               </div>
            </div>
         </section>
         <section className={Sass.banner}>
            <div className={` ${Sass.container} container`}>
               <div className="row">
                  <div className={`col-lg-12 col-md-12 col-12 ${Sass['logo']} `}>
                     <img src="/assets/SK_V2/img/ft-logo.png" alt="" />
                  </div>
                  <div className={`col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12 ${Sass['lft-sec']}`}>
                     <h1 style={{color:'#f5efe0'}}>Claim your Refund on junk insurance today</h1>
                     <h2 style={{color:'#f5efe0'}}>You could be owed $000's!</h2>
                     {/* 
                     <h3>It is reported that Australian banks have set aside over $9 billion* to repay their customers.</h3>
                     */}
                     <ul>
                        <li>No Account Number Needed</li>
                        <li>No Paperwork Required</li>
                     </ul>
                  </div>
                  <FormSection Sass={Sass} />
               </div>
            </div>
         </section>
         <StaticSection content={SK_V2Content.faq} Sass={Sass} />
         <Footer Sass={Sass} />
      </Fragment>
   );
};

export default SK_V2;