import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { Api } from "./api/Api";
import { queryString } from "./Utility/QueryString";
import FbPixel from "./Components/Pages/Splits/FbPixel";
import Matomo from "./Components/Includes/Matomo";
import "./App.scss";
import SK_V1 from "./Components/Pages/Splits/SK_V1";
import SK_V2 from "./Components/Pages/Splits/SK_V2";
import SK_V3 from "./Components/Pages/Splits/SK_V3";
import ADV_SR_CCI_V1 from "./Components/Pages/Advertorials/ADV_SR_CCI_V1";
import Signature from "./Components/Pages/Splits/Signature";
import Signature_V2 from "./Components/Pages/Splits/Signature_V2";
import Thankyou from "./Components/Pages/Splits/Thankyou";
import UserDocument from "./Components/Pages/Splits/UserDocument";
import Followup from "./Components/Pages/Splits/Followup";
import Unsubscribe from "./Components/Pages/Splits/Unsubscribe";
import SmartUI from "./Components/Includes/SmartUI";
import YahooGemini from "./Components/Pages/YahooGemini";
import HomePage from "./Components/Pages/HomePage";
import { RedirectProvider } from "./Contexts/RedirectContext";
import UserVerificationV1 from "./Components/Pages/Splits/UserVerificationV1";
import UserVerificationV2 from "./Components/Pages/Splits/UserVerificationV2";

const App = () => {
  const [visitorParameters, setVisitorParameters] = useState({});
  const referrer = document.referrer;
  const [redirectUrl,setRedirectUrl] = useState({redirectUrl:''});
  return (
    <>
      {EnvConstants.AppEnv === 'prod'
        ? <Matomo />
        : ''
      }
      <VisitorParamsProvider value={{ visitorParameters }}> 
        <QueryStringProvider value={{ queryString }}>
        <RedirectProvider value={{ redirectUrl }}>
          <BrowserRouter>
            <Route exact path="/" component={HomePage}/>
            <Route exact path={["/cci_claims/SK_V1"]} component={SK_V1} />
            <Route exact path={["/cci_claims/SK_V2"]} component={SK_V2} />
            <Route exact path={["/cci_claims/SK_V3"]} component={SK_V3} />
            <Route exact path={"/signature"} component={Signature} />
            <Route exact path={"/signature_v2"} component={Signature_V2} />
            <Route exact path={"/user_verification"} component={SmartUI} />
            <Route exact path={["/thankyou"]} component={Thankyou} />
            <Route exact path={["/fbpixel"]} component={FbPixel} />
            <Route exact path={["/user_document"]} component={UserDocument} />
            <Route exact path={["/followup"]} component={Followup} />
            <Route exact path={["/unsubscribe"]} component={Unsubscribe} />
            
            {/* Advertorials */}
            <Route exact path={["/ADV-SR-CCI_V1"]} component={ADV_SR_CCI_V1} />
            <Route exact path="/yahoo-gemini" component={YahooGemini} />
            <Route exact path="/user_verificationv1" component={UserVerificationV1} />
            <Route exact path="/user_verificationv2" component={UserVerificationV2} />
          </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
      
    </>
  );
};
export default App;
