import React, { useEffect, useState, useReducer } from "react";
import RadioButton from '../../UI/RadioButton';

import {useUserDocumentFlp, useSkipUserDocumentFlp} from "../../../Hooks/useFollowupUserDocument";
import UserDocumentPopupFlp from "../../Includes/Followup/UserDocumentPopupFlp";



const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
}
const userfile = {
  preview:undefined,
  title:undefined
}

const FormReducer = (state, action) => {
  switch (action.type) {
    case 'showSlide': {
      if (action.payload.clickedSlide.slide == 'slide1') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show"
        }
      } else if (action.payload.clickedSlide.slide == 'retake') {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide"
        }
      } else {
        return {
          ...state
        }
      }
    }
  }
}

const UserDocumentFlp = ({ FVStyle, AnimateStyle, carClassName, allFollowupData, setPendingStatus, onModalclose,  setLoader, hideLoader, setPopupLoader, hidePopupLoader}) => {

  const { saveUserDocument,isLoading }   = useUserDocumentFlp();
  const { skipUserDocument,isSkipLoading }   = useSkipUserDocumentFlp();
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [selectedDocType, setSelectedDocType] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);  
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const slideChange = (e,slide) => {
    setClickedSlide({ slide: 'slide1' });
  };

  useEffect(() => {
    if (carClassName === "modal show") {
      document.body.classList.add("modal-open");
      let userIDs = document.getElementById("proof");
      window.onclick = function (event) {
        if (event.target == userIDs) {
          onModalclose()
        }
      };
    }

  }, [carClassName]);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: 'showSlide', payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const selectDocId = (e) => {
    slideChange(e,'slide1');
    setSelectedDocType(e.target.value);
  };

  const changeCameraHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if(imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ['image/jpeg', 'image/png'];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'The uploaded ID type should be an image in jpeg,png or jpg'});
      } else if(imgsize>1024*1024*20) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'Please choose a file less than 20mb'});
      } else {           
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      setDocumentModalShow(true);        
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if(imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);   
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if(imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ['image/jpeg', 'image/png'];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'The uploaded ID type should be an image in jpeg,png or jpg'});
      } else if(imgsize>1024*1024*20) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'Please choose a file less than 20mb'});
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }
      setDocumentModalShow(true);        
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if(imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString : binaryString
    });  
  };

  const handleClick = () => {
    try{
      if(isFilePicked) {
        setDocumentModalShow(true);        
        userfile.preview = URL.createObjectURL(selectedFile);
        userfile.title = selectedFile.name;

        if(selectedFile) {
          //_handleFileRead.bind(this);
          let reader = new FileReader();
          reader.onload = _handleReaderLoaded.bind(this);
          //reader.readAsBinaryString(selectedFile);
          reader.readAsDataURL(selectedFile);
        }      
      } else {
        userfile.preview = undefined;
        userfile.title = undefined;
      }
    } catch(e) {
      console.warn(e);
    }
  }

  const savedocument = async ()=>{  
    try{
      setPopupLoader(true);
      let formdata = {
        "imageFile" :base64Image.base64TextString,
        "captureType" : selectedFileType,
        "rdoDocumentType":selectedDocType
      }
      const response  = await saveUserDocument(
        "followup_user_docs_store",
        formdata,
        allFollowupData.flpData.followup_data,
      );
      
      localStorage.clear();
      if (response.data.status === "Success") {
          onModalclose()
          setPendingStatus({ status_btn: 'identity', status_flag: 'showTick' });
          setDocumentModalShow(false);
          hidePopupLoader();
      } else {
          onModalclose()
          setPendingStatus({ status_btn: 'identity', status_flag: 'showButton' });
          setDocumentModalShow(false);
          hidePopupLoader();
      }      
    } catch(e) {
      console.warn(e);
    }
  };

  const retakeClick = () => {
    setDocumentModalShow(false);
    setClickedSlide({ slide: 'retake' });
    setSelectedFile(null);
    setIsFilePicked(false);
  }

  return (
    <>
      <div
        className={`${carClassName} ${FVStyle['popmdl']} `}
        id="proof"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proof"
        aria-hidden="true"
        style={
          carClassName === "modal show"
            ? {display: "block" }
            : {display: "none" }
        }
        >
        <div className="modal-dialog modal-md" role="document">
          <div className={`modal-content ${FVStyle['signmodal']}`}>
              <div className={` ${FVStyle['modal-body']} `}>

                <div className={`${FVStyle['radio-box']} popslide1  ${FVStyle[state.showSlide1]}`}>   
                    {/*<h3>You must provide 1 proof(s) of address to proceed.</h3>                    
                    <p><i>Tip - Please provide a photo of one of the following: Utility bill showing your name and address from the past 90 days, Driver's licence, Passport </i>
                    </p>*/}
                    <p style={{color:"#ff0404"}}><u>Action Required.</u></p>
                    <h3>Please provide one proof of identity document to help us investigate your claim.</h3>
                    <p><i>Tip - Please provide a photo of one of the following: Driver’s licence, Passport or another official document (e.g. ImmiCard) that contains your full name, date of birth, signature and photo.</i></p>                                        
                    <div className="form-group">
                      <RadioButton
                        id="rdoDocumentTypeUKPassport"
                        value="Passport"
                        name="rdoDocumentType"
                        className={FVStyle['radio']}
                        labelName={`<span>A</span>Passport`}
                        labelClassName={`br ${FVStyle['radioa']} button1p`}
                        onClick={selectDocId}
                        >
                      </RadioButton>
                      <RadioButton
                        id="rdoDocumentTypeUKDrivingLicence"
                        value="Drivers Licence"
                        name="rdoDocumentType"
                        className={FVStyle['radio']}
                        labelName={`<span>B</span>Driver’s Licence`}
                        labelClassName={`bl ${FVStyle['radioa']} button1p`}
                        onClick={selectDocId}
                        >
                      </RadioButton>
                      <RadioButton
                        id="rdoDocumentTypeOther"
                        value="Other Official Document"
                        name="rdoDocumentType"
                        className={FVStyle['radio']}
                        labelName={`<span>D</span>Other Official Document`}
                        labelClassName={`bl ${FVStyle['radioa']} button1p`}
                        onClick={selectDocId}
                        >
                      </RadioButton>
                        {/* 
                      <input type="radio" class="radio " name="question_outer_5_radio" id="yes5" value="yes" data-scroll-to="#question_outer_5" />
                      <label for="yes5" class="br radioa button1p"><span style="padding:0px 14px ;">A</span>UK Passport</label>
                      
                      <input type="radio" class=" radio " name="question_outer_5_radio" id="no5" value="no" data-scroll-to="#question_outer_5" />
                      <label for="no5" class="bl radioa button1p" data-dismiss="modal"><span style="padding:0px 14px ;">B</span>UK Driving Licence</label>

                      <input type="radio" class=" radio " name="question_outer_5_radio" id="no5" value="no" data-scroll-to="#question_outer_5" />
                      <label for="no5" class="bl radioa button1p" data-dismiss="modal"><span style="padding:0px 14px ;">D</span>Other Official Document</label>
                          */}
                    </div> 
                </div>

                <div className={`${FVStyle['radio-box']} ${FVStyle['animated']} fadeInUp popslide2 ${FVStyle[state.showSlide2]}`} >
                    <h3> Upload Photo Identification: </h3>                    
                    <div className="form-group">
                        <input type="file" accept="image/*" 
                          capture="camera" 
                          id="idTypeFileCamera" 
                          name="flDocCam" 
                          className="d-none" 
                          onChange={changeCameraHandler} 
                          onClick={e => (e.target.value = null)}
                        />
                        <label htmlFor="idTypeFileCamera" className={`bl ${FVStyle['radioa']} pleft`}>
                          <span style={{ padding:'0 7px'}}>
                            <img src="assets/Followup/img/camera.png" alt="" className="ful-wid" />
                            </span>Take Photo via Device  
                        </label>
                        <div className="clearfix"></div>
                        <div className="info-list">
                          <ul style={{margin:'10px 0 16px 38px'}}>
                            <li> Set the document down on a plain, dark background</li>
                            <li> Ensure to capture all (4) corners of the document</li>
                            <li> Use good lighting and avoid heavy shadows</li>
                          </ul>
                      </div>
                      <div className="clearfix"></div> 
                      <input type="file" accept="image/*" 
                        id="idTypeFile" 
                        name="flDoc" 
                        className="d-none" 
                        onChange={changeFilesHandler} 
                        onClick={e => (e.target.value = null)}
                        />
                      <label htmlFor="idTypeFile" className={`bl ${FVStyle['radioa']} pleft`}>
                        <span style={{ padding:'0 7px'}}>
                          <img className="ful-wid" src="/assets/Followup/img/browse.png"  alt=""/>
                        </span> Select File from Your Device
                      </label>
                      <div className="clearfix"></div>
                    
                    </div>
                </div> 
              </div>
          </div>
        </div>
      </div>

      <UserDocumentPopupFlp show={documentModalShow} onHide={() => setDocumentModalShow(false)}  
        sass={FVStyle}       
        ispicked={isFilePicked.toString()} 
        imgfile={selectedFile}
        userfile={userfile} 
        className={` ${FVStyle['idreviewModal']} `}
        savedocument={savedocument}
        isLoading={isLoading}
        isInValidfFile={isInValidfFile}
        retakeClick={retakeClick}
        errorMessage={errorMessage}
        />

    </>
  );
};
export default UserDocumentFlp;
