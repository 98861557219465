import {Api} from '../api/Api';

export const FrankieOneAPI = () => {
    
    const saveStatus = async (fo_entity_id, data, message_type,source) => {
        
        const response = await Api.post("/api/v1/frankie-one/status", {
            fo_entity_id,
            data,
            message_type,
            source
        });
        
        return response;
    }

    return { saveStatus } 
}

