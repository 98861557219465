
import {Api} from "../api/Api";

export const useFollowupSignature = () => {
    const saveFollowupSignature = async (message_type,signature_data,followup_data,canvasParameters) => {
        const signatureSubmit = await Api.post("/api/v1/followup/signature-store", {
            message_type,
            signature_data,
            followup_data,
            canvas_parameters:canvasParameters,
        });
        return signatureSubmit;
    }
    return { 
       
        saveFollowupSignature
    }
}