import React, {useState } from "react";
import PrivacyPopup from "./Common/PrivacyPopup";
//import TermsPopup from "./Common/TermsPopup";

const ThankyouFooter = ({TyStyle}) => {

  const [privacyModalShow, setPrivacyModalShow] = useState(false);
  {/* 
  const [termsModalShow, setTermsModalShow] = useState(false);
  */}
  return (
    <>
      <footer className={TyStyle['thank-footer']}>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 text-left">
              <h3>Reclaimer is here to help</h3>
              <p>Reclaimer is here to help you reclaim money taken by your bank. Reclaimer works for you, not the banks.</p>
              <p>If you need help, you can contact the team at Reclaimer here: <a href="support@reclaimer.com.au" target="_blank">support@reclaimer.com.au</a></p>
              <p>*<a href="https://www.ifa.com.au/news/27141-asic-puts-total-cost-of-remediation-at-9bn" target="_blank">https://www.ifa.com.au/news/27141-asic-puts-total-cost-of-remediation-at-9bn</a></p>
            </div>
            <div class="col-lg-4 text-right d-none d-sm-none d-md-none d-lg-block">
              <a><img src="/assets/Thankyou/img/logo.png" alt="" className={TyStyle.logo} /></a>
            </div>
            <div class="col-lg-12 col-md-12 col-12 text-center">
              <ul class="p-0 m-0">
                {/* <li><a role="button" tabIndex="0" onClick={() => setPrivacyModalShow(true)}>Privacy Policy</a></li> */}
                {/*
                <li><a role="button" tabIndex="0" onClick={() => setTermsModalShow(true)}>Terms & Conditions</a></li>
                */}
                 <li><a href="/assets/Privacy_Policy_v1.1-Reclaimer.pdf" role="button" tabIndex="0" target="_blank" >Privacy Policy</a></li>
                <li><a href="/assets/Terms_and_Conditions_v1.2-Reclaimer.pdf" role="button" tabIndex="0" target="_blank" >Terms & Conditions</a></li>
              </ul>
              <p class="m-0">
                <b>© 2021 Reclaimer Australia Pty Ltd (ABN 83 634 364 604)</b>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <PrivacyPopup show={privacyModalShow} onHide={() => setPrivacyModalShow(false)}  Sass={TyStyle} />
      {/*
      <TermsPopup show={termsModalShow} onHide={() => setTermsModalShow(false)}  Sass={TyStyle} />
      */}
    </>
  );
};

export default ThankyouFooter;
