import React from "react";
import { Modal } from "react-bootstrap";

const LoaderPopup = ({LdStyle, show, onHide}) => {
  
  return (
    <>
      
      <Modal  show={show} onHide={onHide}  aria-labelledby="TermsTitle" centered 
        className={` ${LdStyle['load_modal']} `}
      >
        <Modal.Body>
          <div className={`${LdStyle['tst']}`}>
            <img 
              src="/assets/Followup/img/review.gif" alt=""
              />
          </div>
        </Modal.Body>
      </Modal> 
      
      {/* 
      <div 
        className={`modal ${LdStyle['load_modal']} load ${LdStyle[showPopupLoad]}`}
        id="Loderpopup" 
        tabIndex="-1" 
        role="dialog" 
        aria-labelledby="TermsTitle" 
        style={{display: "none"}} 
        aria-hidden="true"
      >
        <div className={`${LdStyle['inner_load']} modal-lg modal-dialog modal-dialog-centered`} 
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className={`${LdStyle['tst']}`}>
                <img 
                  src="/assets/Followup/img/review.gif" alt=""
                  />
              </div>
            </div>
          </div>
        </div>
        
      </div>
      */}
      
    </>
  );
};

export default LoaderPopup;
