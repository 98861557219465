import React, { useEffect } from "react";

const ThankyouHeader = ({TyStyle}) => {
  
  return (
    <>
      <header>
         <div class="container">
            <div class="row">
               <div class="col-lg-12 text-center">
                  <a><img src="assets/Thankyou/img/logo.png" alt="" className={TyStyle.logo} /></a>
               </div>
            </div>
         </div>
      </header>
    </>
  );
};

export default ThankyouHeader;
