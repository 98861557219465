//import React, { Fragment} from 'react';
import React, { Fragment, useState, useEffect, useReducer, useContext } from 'react';
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import UDStyle from '../../../assets/UserDocument/css/UserDocument.module.scss';
import AnimateStyle from '../../../assets/UserDocument/css/Animated.module.css';
import RadioButton from '../../UI/RadioButton';
import Button from "../../UI/Button";
import {useUserDocument, useSkipUserDocument} from "../../../Hooks/useUserDocument";
import UserDocumentPopup from '../../Includes/Layouts/Common/UserDocumentPopup';

import axios from 'axios';
require('dotenv').config();
const base_api_url = process.env.REACT_APP_API_URL;
const api_auth_token = process.env.REACT_APP_API_AUTH_TOKEN;

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
}

const userfile = {
  preview:undefined,
  title:undefined
}
const FormReducer = (state, action) => {
  switch (action.type) {
    case 'showSlide': {
      if (action.payload.clickedSlide.slide == 'slide1') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show"
        }
      } else if (action.payload.clickedSlide.slide == 'retake') {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide"
        }
      } else {
        return {
          ...state
        }
      }
    }
  }
}

const UserDocument = () => {
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query_string = localStorage.getItem('queryString');
  const formParameters = JSON.parse(localStorage.getItem('formData'));

  const { saveUserDocument,isLoading }   = useUserDocument();
  const { skipUserDocument,isSkipLoading }   = useSkipUserDocument();
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [selectedDocType, setSelectedDocType] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const slideChange = (e,slide) => {
    setClickedSlide({ slide: 'slide1' });
  };

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: 'showSlide', payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  
  const selectDocId = (e) => {
    slideChange(e,'slide1');
    setSelectedDocType(e.target.value);
  };

  const changeCameraHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if(imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ['image/jpeg', 'image/png'];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'The uploaded ID type should be an image in jpeg,png or jpg'});
      } else if(imgsize>1024*1024*20) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'Please choose a file less than 20mb'});
      } else {           
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      setDocumentModalShow(true);        
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if(imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);   
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if(imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      console.log(imgsize);
      console.log(1024*1024*10);
      let allowedExtensions = ['image/jpeg', 'image/png'];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'The uploaded ID type should be an image in jpeg,png or jpg'});
      } else if(imgsize>1024*1024*20) {
        setIsInValidfFile(true);
        setErrorMessage({txt:'Please choose a file less than 20mb'});
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }
      setDocumentModalShow(true);        
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if(imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };
  

  const handleClick = () => {
    try{
      if(isFilePicked) {
        setDocumentModalShow(true);        
        userfile.preview = URL.createObjectURL(selectedFile);
        userfile.title = selectedFile.name;

        if(selectedFile) {
          //_handleFileRead.bind(this);
          let reader = new FileReader();
          reader.onload = _handleReaderLoaded.bind(this);
          //reader.readAsBinaryString(selectedFile);
          reader.readAsDataURL(selectedFile);
        }      
      } else {
        userfile.preview = undefined;
        userfile.title = undefined;
      }
    } catch(e) {
      console.warn(e);
    }
  };

  // const _handleFileRead = (readEvt) => {
  //   console.log("ddddd");
  // }

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    //binaryString = binaryString.replace(";base64", `;name=${selectedFile.name};base64`);
    // setBase64Image({
    //   //base64TextString : btoa(binaryString)
    //   base64TextString : btoa(encodeURIComponent(binaryString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    //     return String.fromCharCode('0x' + p1);
    //   }))
    // });
    setBase64Image({
      base64TextString : binaryString
    });  
  };

  const retakeClick = () => {
    setDocumentModalShow(false);
    setClickedSlide({ slide: 'retake' });
    setSelectedFile(null);
    setIsFilePicked(false);
  }
  /*
  const savedocument = async ()=>{  
    try{
      //console.log(visitorParameters);
      //console.log(visitorParameters.visitor_parameters);
      //console.log(visitorParameters.data);
      //console.log(base64Image.base64TextString);
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {

        let formdata = {
          "imageFile" :base64Image.base64TextString,
          "captureType" : selectedFileType,
          "rdoDocumentType":selectedDocType
        }
        const response      =   await saveUserDocument(
          formdata,
          visitorParameters,
          formParameters,
          query_string
        );

        //console.log(response);

        localStorage.clear();
        // if(response.data.status === "Success"){
        //   history.push(
        //     "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
        //   );
        // }
        history.push(
          "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }  
    } catch(e) {
      console.warn(e);
    }
  };
  */
  const savedocument = async ()=>{  
    try{
      //console.log(visitorParameters);
      //console.log(visitorParameters.visitor_parameters);
      //console.log(visitorParameters.data);
      //console.log(base64Image.base64TextString);
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {

        let formdata = {
          "imageFile" :base64Image.base64TextString,
          "captureType" : selectedFileType,
          "rdoDocumentType":selectedDocType
        }
        const response      =  saveUserDocument(
          formdata,
          visitorParameters,
          formParameters,
          query_string
        );
        
        // localStorage.clear();
        history.push(
          "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }  
    } catch(e) {
      console.warn(e);
    }
  };

  const skipID = async ()=> {
    console.log("hai");
    try{
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {

        let formdata = {
          "skipedDocument":true
        }
        const response      =  skipUserDocument(
          formdata,
          visitorParameters,
          query_string
        );
        
        // localStorage.clear();
        history.push(
          "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }  
    } catch(e) {
      console.warn(e);
    }
  };

  return (
    <Fragment>
      <section className={UDStyle.questionspart}>
        <div class="container">
          <div class="row">
            <div className={`offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-12 col-sm-12 ${UDStyle['l_bg']}  `}>
              <form name="docs_form" id="user_form" method="POST"  encType="multipart/form-data" >
                <div className={`${UDStyle['radio-box']} question4 ${AnimateStyle['animated']} ${UDStyle['fadeInUp']} slide1 ${UDStyle[state.showSlide1]}`}>
                  <p style={{color:"#ff0404"}}><u>Action Required.</u></p>
                  <h4><b>Please provide one proof of identity document to help us investigate your claim.</b></h4>
                  <p><i>Tip - Please provide a photo of one of the following: Driver’s licence, Passport or another official document (e.g. ImmiCard) that contains your full name, date of birth, signature and photo. </i></p>
                  <div class="form-group">
                    <RadioButton
                      id="rdoDocumentTypeUKPassport"
                      value="Passport"
                      name="rdoDocumentType"
                      className={UDStyle['radio']}
                      labelName={`<span>A</span>Passport`}
                      labelClassName={`br ${UDStyle['radioa']} button1`}
                      onClick={selectDocId}
                      >
                    </RadioButton>
                    <RadioButton
                      id="rdoDocumentTypeUKDrivingLicence"
                      value="Drivers Licence"
                      name="rdoDocumentType"
                      className={UDStyle['radio']}
                      labelName={`<span>B</span>Driver’s Licence`}
                      labelClassName={`br ${UDStyle['radioa']} button1`}
                      onClick={selectDocId}
                      >
                    </RadioButton>
                    {/* <RadioButton
                      id="rdoDocumentTypeResidence"
                      value="Residence Permit"
                      name="rdoDocumentType"
                      className={UDStyle['radio']}
                      labelName={`<span>C</span>Residence Permit`}
                      labelClassName={`br ${UDStyle['radioa']} button1`}
                      onClick={selectDocId}
                      >
                    </RadioButton> */}
                    <RadioButton
                      id="rdoDocumentTypeOther"
                      value="Other Official Document"
                      name="rdoDocumentType"
                      className={UDStyle['radio']}
                      labelName={`<span>C</span>Other Official Document`}
                      labelClassName={`br ${UDStyle['radioa']} button1`}
                      onClick={selectDocId}
                      >
                    </RadioButton>
                    {/* 
                    <input type="radio" class="radio " name="question_outer_5_radio" id="yes5" value="yes" data-scroll-to="#question_outer_5">
								    <label htmlFor="yes5" class="br radioa button1"><span>A</span>UK Passport</label>
                    */}
                    <div class="clearfix"></div>
                  </div>
                  {/* <div class="form-group">
                    <Button
                      type="button"
                      className={`btn-clear ${UDStyle["id-skip"]}`}
                      id="rdoDocumentTypeSkipg"
                      onClick={skipID}
                      buttonText="SKIP - Don't Have to Head? Provide Later."
                    />
                  </div> */}
                </div>
                <div className={`${UDStyle['space']} ${AnimateStyle['animated']} ${UDStyle['fadeInUp']} slide2 ${UDStyle[state.showSlide2]}`}>
                  <h4>Upload Photo Identification:</h4>
                  <div class="">
                    <label type="button" htmlFor="idTypeFileCamera" className={`${UDStyle['btn-submit']} button1 browse-btn`}>
                      <img src="assets/UserDocument/img/camera.png" alt="" class="img-icon" />Take Photo via Device
                    </label>
                    <input type="file" accept="image/*" capture="camera" id="idTypeFileCamera" name="flDocCam" 
                    class="d-none" 
                    onChange={changeCameraHandler} 
                    onClick={e => (e.target.value = null)}
                    />
                  </div>
                  
                  <div class="clearfix"></div>
                  <ul className={UDStyle['info-list']}>
                    <li>
                      <img src="assets/UserDocument/img/li-bulb.png" alt=""/>Set the document down on a plain, dark background</li>
                    <li>
                      <img src="assets/UserDocument/img/li-bulb.png" alt=""/>Ensure to capture all (4) corners of the document</li>
                    <li>
                      <img src="assets/UserDocument/img/li-bulb.png" alt=""/>Use good lighting and avoid heavy shadows</li>
                  </ul>
                  <div class="clearfix"></div>
                  {/* <div class="upload-btn-wrapper">
                    <button className={`${UDStyle['btn-submit']} button1 browse-btn`}>
                      <img src="assets/UserDocument/img/browse.png" alt="" class="img-icon" />Select File from Your Device
                    </button>
                    <input type="file" id="idTypeFile" name="idTypeFile" class="d-none" />
                  </div> */}
                  <div class="upload-btn-wrapper">
                      <label htmlFor="idTypeFile" type="button" className={`${UDStyle['btn-submit']} button1 browse-btn`}>
                        <img src="assets/UserDocument/img/browse.png" alt="" class="img-icon" />Select File from Your Device
                      </label>
                      <input type="file" accept="image/*" id="idTypeFile" name="flDoc" 
                      class="d-none" 
                      onChange={changeFilesHandler} 
                      onClick={e => (e.target.value = null)}
                      />
                  </div>
                </div>
                {/* 
                <p className={UDStyle[state.showSlide2]}>
                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#idreview"
                  onClick={handleClick} >
                    ID Review
                  </button>
                </p>
                */}
              </form>
            </div>
          </div>
        </div>
      </section>
      
      <UserDocumentPopup show={documentModalShow} onHide={() => setDocumentModalShow(false)}  
        sass={UDStyle}       
        ispicked={isFilePicked.toString()} 
        imgfile={selectedFile}
        userfile={userfile} 
        className={UDStyle['idreviewModal']}
        savedocument={savedocument}
        isLoading={isLoading}
        isInValidfFile={isInValidfFile}
        retakeClick={retakeClick}
        errorMessage={errorMessage}
        />
    </Fragment>
  )
}

export default UserDocument;