
import {useState} from 'react'; 
import {Api} from "../api/Api";

export const useSignature = () => {
    const [isLoading,setIsLoading] = useState(false);
    const saveSignature = async (signature_data,visitor_parameters,previousAddressData,formParameters,query_string,canvasParameters,message_type='signature_store') => {
        setIsLoading(true);
        const signatureSubmit = await Api.post("api/v1/signature", {
            message_type,
            signature_data,
            visitor_parameters:visitor_parameters.visitor_parameters,
            previous_data:previousAddressData,
            form_data:formParameters,
            data:visitor_parameters.data,
            query_string:query_string,
            canvas_parameters:canvasParameters,
        });
        // setIsLoading(false);
        return signatureSubmit;
    }
    return { 
        isLoading,
        saveSignature
    }
}